import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { Option } from 'app/types';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/slice';
import { selectCities, selectIsLoading, selectPagination } from '../redux/selector';
import { selectOrganization } from 'app/redux/selectors';
import { selectDirection } from 'styles/theme/slice';
import debounce from 'lodash/debounce';
import { cityAllOption } from '../helper';
import { City } from '../types';

interface SelectInputProps {
  readonly value?: Option;
  readonly onChange?: (newValue?: Option | null) => void;
  readonly isLoading?: boolean;
  readonly disabled?: boolean;
  readonly error?: string;
  readonly backgroundColor?: string;
  readonly borderWidth?: number;
  readonly showAll?: boolean; // Prop to control "Show All" option
}

export default function SelectInput({
  value: propValue,
  onChange,
  isLoading,
  error,
  disabled,
  backgroundColor,
  borderWidth,
  showAll = false, // Default to false if not provided
}: SelectInputProps) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [value, setValue] = useState<Option | null>(propValue || null); // Manage local value
  const loadingMore = useSelector(selectIsLoading);
  const pagination = useSelector(selectPagination);
  const organization = useSelector(selectOrganization);
  const cities = useSelector(selectCities);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const direction = useSelector(selectDirection);

  const fetchCities = useCallback(
    debounce((searchQuery: string) => {
      dispatch(
        actions.getCities({
          page: 1,
          perPage: pagination.perPage,
          search: searchQuery,
          isActive: pagination.filters?.isActive,
          organization: organization?.value,
        })
      );
    }, 300),
    [dispatch, pagination.perPage, organization?.value]
  );

    // Update internal state when prop value changes
    useEffect(() => {
      if(propValue?.label) {
        setValue(propValue || null);
      }else if(propValue?.value && !propValue?.label) {
        if(open) {
          fetchCities('')
        }else{
          dispatch(
            actions.getCities({
              page: 1,
              perPage: pagination.perPage,
              search: propValue?.value,
              isActive: pagination.filters?.isActive,
              organization: organization?.value,
              callback: (city: City) => {
                setValue({label: city.name , value: city._id})
              }
            })
          )
        }
        
      }else if(showAll && !propValue && !open) {
        setValue(cityAllOption)
      }
    }, [propValue, showAll, open]);
  

  useEffect(() => {
    if (search.length > 0) {
      fetchCities(search);
    } else {
      dispatch(
        actions.getCities({
          page: 1,
          perPage: pagination.perPage,
          search: '',
          isActive: pagination.filters?.isActive,
          organization: organization?.value,
        })
      );
    }
  }, [search, dispatch, organization?.value, fetchCities]);

  const handleScroll = (event: React.SyntheticEvent) => {
    if (loadingMore) return;
    const listboxNode = event.currentTarget;
    const bottom = Math.ceil(listboxNode.scrollTop + listboxNode.clientHeight) >= listboxNode.scrollHeight;
    if (bottom) {
      const hasMore = pagination.page < pagination.totalPage;
      if (!hasMore) return;
      dispatch(
        actions.getCities({
          page: pagination.page + 1,
          perPage: pagination.perPage,
          search: search,
          isActive: pagination.filters?.isActive,
          organization: organization?.value,
        })
      );
    }
  };

  // Create the "Show All" option
  const renderValue = () => {
    if (value?.value === '') {
      return value;
    }
    if (value?.label === '') {
      const cityItem = cities.filter((x) => x._id === value.value)[0];
      if (cityItem) {
        return { label: cityItem.name, value: value?.value };
      }
      return value;
    }
    return value;
  };

  // Update local value and trigger onChange prop when value changes
  const handleChange = (event: React.SyntheticEvent, newValue: Option | null) => {
    const updatedValue = newValue?.value === 'all' ? cityAllOption : newValue;
    setValue(updatedValue); // Update local value
    onChange?.(updatedValue); // Trigger the prop onChange if it exists
  };

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={renderValue()}
      getOptionLabel={(option) => option?.label ?? ''}
      getOptionKey={(option) => option?.value}
      onChange={handleChange} // Use the local change handler
      options={[
        ...(showAll ? [cityAllOption] : []), // Conditionally include "Show All"
        ...(cities?.map((c) => ({
          label: c.name,
          value: c._id as string,
        })) || []),
      ]}
      disabled={disabled}
      loadingText={
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '14px',
            textAlign: 'left',
          }}
        >
          {t('COMMON.TEXT.LOADING')}
        </Typography>
      }
      noOptionsText={
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '14px',
            textAlign: 'left',
          }}
        >
          {t('COMMON.TEXT.NO_OPTIONS')}
        </Typography>
      }
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '14px',
              color: '#5E6781',
              py: '4px',
            }}
          >
            {option?.label}
          </Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('COMMON.PLACEHOLDERS.SELECT')}
          disabled={disabled}
          sx={{
            width: '100%',
            backgroundColor: backgroundColor ?? '#FFFFFF',
            borderRadius: '4px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: error ? '#d32f2f' : '#86BDE3',
                borderRadius: '4px',
                color: '#263238',
                borderWidth: borderWidth ?? 1,
              },
              '&:hover fieldset': {
                borderColor: error ? '#d32f2f' : '#86BDE3',
                borderRadius: '4px',
                color: '#263238',
                borderWidth: borderWidth ?? 1,
              },
              '&.Mui-focused fieldset': {
                borderColor: error ? '#d32f2f' : '#86BDE3',
                borderRadius: '4px',
                color: '#263238',
                borderWidth: borderWidth ?? 1,
              },
            },
            '& .MuiFormHelperText-root': {
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '18px',
              letterSpacing: '0em',
              margin: 0,
              color: '#d32f2f',
            },
            input: {
              '&::placeholder': {
                color: '#727F89',
                opacity: '100%',
                fontWeight: '400',
                fontSize: '14px',
              },
              fontWeight: '400',
              fontSize: '14px',
              color: '#263238',
              backgroundColor: backgroundColor ?? '#FFFFFF',
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {(isLoading || loadingMore) ? (
                  <CircularProgress color="inherit" size={16} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          helperText={error}
          onChange={(e) => setSearch(e.target.value)}
        />
      )}
      ListboxProps={{ onScroll: handleScroll }}
    />
  );
}
