import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { formatUUID } from "styles/theme/utils";
import { Link } from "react-router-dom";
import { selectDirection } from "styles/theme/slice";
import {
  selectIsLoading,
  selectQuestions,
} from "../GamesStations/redux/selector";
import { LoadingIndicator } from "app/components/LoadingIndicator";

export default function GameStationQuestions({
  disabled,
}: {
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const questions = useSelector(selectQuestions);
  const isLoading = useSelector(selectIsLoading);
  const direction = useSelector(selectDirection);

  const sortedQuestions = questions;
  if (isLoading) {
    return (
      <Box height={'100px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <LoadingIndicator />
      </Box>
    );
  }
  return (
    <Box>
      <Box display={"flex"} gap="10px" flexDirection={"row"}>
        <Box
          sx={{
            width: "25%",
          }}
        >
          <Typography
            style={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "100%",
              color: "#5E6781",
              marginBottom: "16px",
              paddingTop: "16px",
              marginLeft: "16px",
            }}
          >
            {t("STATIONS.FORM.LABELS.QUESTION_ID")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "70%",
          }}
        >
          <Typography
            style={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "100%",
              color: "#5E6781",
              marginBottom: "16px",
              paddingTop: "16px",
              marginLeft: "10px",
            }}
          >
            {t("STATIONS.FORM.QUESTION_BODY")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {sortedQuestions.length < 1 ? (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "100%",
              color: "#5E6781",
              
              textAlign: "center",
              my: "40px",
            }}
          >
            {t("STATIONS.FORM.NO_QUESTIONS_ADDED_YET")}
          </Typography>
        ) : (
          sortedQuestions.map((question, i) => (
            <Box display={"flex"} flexDirection={"row"}>
              <Box width={"25%"}>
                <Link
                  target="_blank"
                  to={`/app/questions/${question?._id}/view`}
                  style={{
                    
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                    color: "#2F80ED",
                    cursor: "pointer",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  {formatUUID(question?._id) || "N/A"}
                </Link>
              </Box>
              <Box width={"70%"}>
                <Typography sx={{ marginLeft: "20px", marginRight: "20px" }}>
                  {" "}
                  {question.body}
                </Typography>
              </Box>
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
}
