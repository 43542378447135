import { IconButton, MenuItem } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useStyles } from "./styles";
import React from "react";
import QuestionTypesDropdown from "app/components/QuestionTypesDropdown";
import { useTranslation } from "react-i18next";
import EditorsSelect from "app/components/EditorSelect";
import { useNavigate, useSearchParams } from "react-router-dom";
import { QUESTION_TYPES } from "utils/constants";
import { allEditorOption } from "app/components/EditorSelect/helper";

export default function Header({ disabled ,resetFilter }: { disabled?: boolean, resetFilter(): void }) {
  const styles = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchParams  = useSearchParams();
  const questionType = searchParams?.[0]?.get("type")  as QUESTION_TYPES | undefined;;
  const editorParam = searchParams?.[0]?.get("user");

  const setTypeSearchQuery = (type: string) => {
    const params = new URLSearchParams(window.location.search);
    if(type=="All"){
      params.delete("type");
    }else{
      params.set("type", type);
    }
   
    navigate(`?${params.toString()}`);
  };

  const handleResetFilters = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("type");
    params.delete("user");
    navigate(`?${params.toString()}`);
    resetFilter()
  }

  const setUserSearchQuery = (user: string) => {
    const params = new URLSearchParams(window.location.search);
    if (user.toLowerCase() == "all") {
      params.delete("user");
    }else{
      params.set("user", user);
    }
    navigate(`?${params.toString()}`);
  };
  return (
    <>
      <Grid2 xs={4}>
        <QuestionTypesDropdown
          disabled={disabled}
          onChange={(value)=>setTypeSearchQuery(value)}
          value={questionType}
        />
      </Grid2>
      <Grid2 xs={4}>
        <EditorsSelect
          label={t("STATIONS.FORM.LABELS.CREATOR_NAME")}
          isFieldReq={false}
          backgroundColor="#F3F6F8"
          borderWidth={0}
          disabled={disabled}
          onChange={(value) => setUserSearchQuery(value?.value || '')}
          value={editorParam ? {value: editorParam, label: ''} : undefined}
        />
      </Grid2>
      <Grid2 xs={1} display={'flex'}  justifyContent={'center'} alignItems={'center'}>
      <IconButton
          onClick={handleResetFilters}
        >
          <RefreshIcon />
        </IconButton>
      </Grid2>
    </>
  );
}
