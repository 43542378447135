import React, { useEffect, useState } from "react";
import { DropDown } from "../DropDown";
import { MenuItem, Typography } from "@mui/material";
import { QUESTION_TYPES } from "utils/constants";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";

export default function CustomizeQuestionDropdownDropdown({
  onChange,
  isCustomQuestion,
  disabled,
  isMandatory,
}: {
  readonly onChange?: (v: any) => void;
  readonly disabled?: boolean;
  readonly isMandatory?: boolean;
  readonly isCustomQuestion?: boolean

}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState<any>(null)

  const questionTypes = [{
    value:"No",
    name: t(`QUESTIONS.CUSTOM_QUESTION_OPTION.NO`),
  },{
    value:"Yes",
    name: t(`QUESTIONS.CUSTOM_QUESTION_OPTION.YES`),
  }];

  useEffect(()=>{
    const v = isCustomQuestion ? questionTypes.find(f => f.value == 'Yes') : questionTypes.find(f => f.value == 'No')
    setValue(v)
  },[isCustomQuestion])

  return (
    <DropDown
      isDisable={disabled}
      menuItems={[...questionTypes]?.map((item,index) => (
        <MenuItem value={item.value} key={index}>
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "14px",
              letterSpacing: "0em",
              color: "#5E6781",
              paddingY: "8px",
            }}
          >
            {item.name}
          </Typography>
        </MenuItem>
      ))}
      renderValue={s => {
        return s?.name
      }}
      onChange={(e) => onChange?.(e.target.value)}
      label={t("QUESTIONS.LABELS.CUSTOM_QUESTION_FILTER")}
      placeholder="Select"
      name="status"
      value={value}
      fullWidth={true}
      className={styles.dropDownWithoutBorder}
      isMandatory={isMandatory}
      defaultValue={t('QUESTIONS.CUSTOM_QUESTION_OPTION.NO')}
    />
  );
}
