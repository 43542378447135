import { Box, IconButton } from "@mui/material";
import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/containers/Tickets/redux/slice";
import { selectDirection } from "styles/theme/slice";
import InputField from "app/components/InputField";
export default function Counter({
  maxCount = 10,
  disabled,
  value,
  onChange
}: {
  readonly maxCount?: number;
  readonly disabled?: boolean;
  readonly value: number | string;
  readonly onChange: (value: number) => void;
}) {
  const dispatch = useDispatch();
  const direction = useSelector(selectDirection);

  const onIncrease = () => {
    if (disabled) return;
    if(!value) {
      onChange(1);
      return;
    };
    const num = Number(value || 0)
    if (num < maxCount) {
      onChange(num + 1);
    }
  };

  const onDecrease = () => {
    if (disabled) return;
    const num = Number(value || 0)
    if (num > 0) {
      onChange(num - 1);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        maxWidth: "80px",
        height: "36px",
        padding: "6px 12px 6px 12px",
        borderRadius: "4px",
        backgroundColor: "#F3F6F8",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {" "}
      <>
        {/* <Typography
          sx={{
          }}
        >
          {noOfDevices?.value}
        </Typography> */}
        <InputField 
          sx={{
            '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none',
          },
        },
          }}
          mainStyle={{
            marginBottom: "0px",
          }}
          isDisable={disabled}
          inputStyle={{
            
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0em",
            textAlign: "center",
            opacity: disabled ? 0.5 : 1,
            padding: "0px",
            height: "30px",
            backgroundColor: "#F3F6F8",
          }}
          inputProps={{
            min: 0,
            max: maxCount,
            type: "number",
          }}
          onChange={(e) => {
            if (disabled) return;
            if(Number(e.target.value??0) > maxCount) return;
            onChange((e.target.value??1));
          }}
          borderColor="#F3F6F8"
          value={value}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "2px",
          }}
        >
          <IconButton 
            sx={{ 
              width: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '10px',
            }} 
            size='small' 
            onClick={onIncrease}
          >
            <KeyboardArrowUpIcon
              sx={{
                fontSize: "18px",
                opacity: disabled ? 0.5 : 1,
              }}
            />
          </IconButton>
          <IconButton 
            sx={{
              width: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '10px',
            }} 
            size='small' 
            onClick={onDecrease}
          >
            <KeyboardArrowDownIcon
              sx={{
                fontSize: "18px",
                opacity: disabled ? 0.5 : 1,
              }}
            />
          </IconButton>
        </Box>
      </>
    </Box>
  );
}
