import { Box, RadioGroup, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import RadioItem from "./Radio";
import CheckboxItem from "./CheckboxItem";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectGame } from "app/containers/Games/redux/selector";
import { actions } from "app/containers/Games/redux/slice";
import { CourseTypes } from "app/containers/Games/types";
import { selectDirection } from "styles/theme/slice";

export default function CourseForm({
  disabled,
}:{
  disabled?: boolean
}) {
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  const game = useSelector(selectGame)
  const dispatch = useDispatch()

  const onChange = (name: string, value: string) => {
    dispatch(actions.setFormValues({ key: "startIsIdentical", value: false }));
    dispatch(actions.setFormValues({ key: "endIsIdentical", value: false }));
    dispatch(actions.setFormValues({ key: name, value }));
  }
  
  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue={game.course.value}
      name="radio-buttons-group"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          marginBottom: "32px",
        }}
      >
        <Typography
          sx={{
            
            fontSize: "32px",
            fontWeight: 600,
            linHeight: "32px",
            letterSpacing: "0em",
            color: "#207DB9",
          }}
        >
          {t("GAMES.FORM.COURSE")}
        </Typography>

        <Grid2
          container
          spacing={3}
          sx={{
            backgroundColor: "#F3F6F8",
            padding: "16px 24px",
            borderRadius: "8px",
          }}
        >
          <Grid2 md={4}>
            <RadioItem
              tooltip={t("GAMES.FORM.LABELS.IDENTICAL_FOR_ALL_TOOLTIP")}
              value={CourseTypes.IDENTICAL}
              name="course"
              onChange={(e)=>onChange(e?.target.name, e?.target.value)}
              disabled={disabled}
              label={t("GAMES.FORM.LABELS.IDENTICAL_FOR_ALL")}
              checked={game.course.value == CourseTypes.IDENTICAL}
              containerStyle={{
                padding: "8px 24px 8px 8px",
                border: "1px solid #207DB9",
                borderRadius: "4px",
                background: "#FFFFFF",
              }}
            />
          </Grid2>
          <Grid2 md={4}>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                width: "100%",
                height: "100%",
                border: "1px solid #F2F2F2",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <RadioItem 
                tooltip={t("GAMES.FORM.LABELS.RANDOM_STATIONS_TOOLTIP")}
                disabled={disabled} 
                label={t("GAMES.FORM.LABELS.RANDOM_STATIONS")} 
                value={CourseTypes.RANDOM_STATION}
                checked={game.course.value == CourseTypes.RANDOM_STATION}
                name="course"
                onChange={(e)=>onChange(e?.target.name, e?.target.value)}
              />
              <CheckboxItem 
                disabled={disabled || game.course.value !== CourseTypes.RANDOM_STATION} 
                label={t("GAMES.FORM.LABELS.RANDOM_START")} 
                name="startIsIdentical"
                onChange={(e)=>dispatch(actions.setFormValues({ key: e?.target.name, value: e?.target.checked }))}
                checked={game.startIsIdentical.value && game.course.value === CourseTypes.RANDOM_STATION}
              />
              <CheckboxItem 
                disabled={disabled || game.course.value !== CourseTypes.RANDOM_STATION} 
                label={t("GAMES.FORM.LABELS.RANDOM_END")} 
                name="endIsIdentical"
                onChange={(e)=>dispatch(actions.setFormValues({ key: e?.target.name, value: e?.target.checked }))}
                checked={game.endIsIdentical.value && game.course.value === CourseTypes.RANDOM_STATION}
              />
            </Box>
          </Grid2>
          <Grid2 md={4}>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                width: "100%",
                height: "100%",
                border: "1px solid #F2F2F2",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <RadioItem 
                tooltip={t("GAMES.FORM.LABELS.RANDOM_STATION_GROUPS_TOOLTIP")}
                disabled={disabled} 
                label={t("GAMES.FORM.LABELS.RANDOM_STATION_GROUPS")} 
                value={CourseTypes.RANDOM_STATION_GROUPS}
                checked={game.course.value == CourseTypes.RANDOM_STATION_GROUPS}
                name="course"
                onChange={(e)=>onChange(e?.target.name, e?.target.value)}
              />
              <CheckboxItem 
                disabled={disabled || game.course.value !== CourseTypes.RANDOM_STATION_GROUPS} 
                label={t("GAMES.FORM.LABELS.RANDOM_START")} 
                name="startIsIdentical"
                onChange={(e)=>dispatch(actions.setFormValues({ key: e?.target.name, value: e?.target.checked }))}
                checked={game.startIsIdentical.value && game.course.value === CourseTypes.RANDOM_STATION_GROUPS}
              />
              <CheckboxItem 
                disabled={disabled || game.course.value !== CourseTypes.RANDOM_STATION_GROUPS} 
                label={t("GAMES.FORM.LABELS.RANDOM_END")} 
                name="endIsIdentical"
                onChange={(e)=>dispatch(actions.setFormValues({ key: e?.target.name, value: e?.target.checked }))}
                checked={game.endIsIdentical.value && game.course.value === CourseTypes.RANDOM_STATION_GROUPS}
              />
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </RadioGroup>
  );
}
