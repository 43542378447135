import React from "react";
import { Link } from "react-router-dom";

import { ThumbUp } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useStyles } from "./styles";

type Props = {};

const ThankYou = (props: Props) => {
  const styles = useStyles();

  return (
    <Box className={styles.form}>
      <Box mb={2} className={styles.heading}>
        Password Set
      </Box>
      <ThumbUp className={styles.icon} />
      <div className={styles.subHeading}>
        Your Password has been set. Please{" "}
        <Link className={styles.loginLink} to="/auth/login">
          Click Here
        </Link>{" "}
        to log in
      </div>
    </Box>
  );
};

export default ThankYou;
