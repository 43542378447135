import { FormControlLabel, Typography, Radio, Theme, SxProps, Tooltip, Box } from '@mui/material'
import React from 'react'
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';

export default function RadioItem({
    containerStyle,
    label,
    disabled,
    value,
    name,
    onChange,
    tooltip,
    checked,
}:{
    containerStyle?: SxProps<Theme>
    label?: string
    disabled?: boolean
    value?: string
    name?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    tooltip?: string
    checked?: boolean
}) {
  const direction = useSelector(selectDirection);
    return (
        <FormControlLabel
            sx={{
                margin: 0,
                
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0em",
                width: "100%",
                ...containerStyle
            }}
            disabled={disabled}
            label={
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "6px",
                    }}
                >
                    <Typography
                        sx={{
                            
                            fontSize: "14px",
                            fontWeight: 600,
                            lineHeight: "14px",
                            letterSpacing: "0em",
                        }}
                    >
                        {label}
                    </Typography>
                    {
                        tooltip && (
                            <Tooltip title={tooltip}>
                                <InfoIcon
                                    sx={{
                                        fontSize: "18px",
                                        marginLeft: "6px",
                                        color: "#3C3C43",
                                    }}
                                />
                            </Tooltip>
                        )
                    }
                </Box>
            }
            control={
                <Radio
                    size="small"
                    value={value}
                    name={name}
                    onChange={onChange}
                    checked={checked}
                    sx={{
                        color: "#207DB9",
                        "&.Mui-checked": {
                            color: "#207DB9",
                        },
                    }}
                />
            }
        />
    )
}
