import React from "react";
import CustomButton from "../CustomButton";
import { SxProps, Theme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDirection } from "styles/theme/slice";

export default function OutlineButton({
  onClick,
  label = "Cancel",
  sx,
  startIcon,
  endIcon,
  disabled,
  isLoading,
}: {
  readonly onClick?: () => void;
  readonly label: string;
  readonly sx?: SxProps<Theme>;
  readonly startIcon?: React.ReactNode;
  readonly endIcon?: React.ReactNode;
  readonly disabled?: boolean;
  readonly isLoading?: boolean;
}) {
  const direction = useSelector(selectDirection);

  return (
    <CustomButton
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        color: "#17171B",
        borderColor: "#17171B",
        "&:hover": {
          opacity: 0.8,
        },
        textTransform: "none",
        
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "14px",
        width: "200px",
        height: "44px",
        borderRadius: "8px",
        ...sx,
      }}
      disabled={disabled}
      variant="outlined"
      onClick={onClick}
      label={label}
      isLoading={isLoading}
    />
  );
}
