import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { API_URL, translate } from 'utils/constants';
import { Res, request } from 'utils/request';
import { selectCustomizeTicket, selectPagination } from './selector';
import { actions } from './slice';
import { Ticket, TicketCustomizeForm, TicketListResponse } from '../types';
import { removeBlankProperties } from 'utils/helpers';
import toastService from 'utils/toast';
import { Query } from 'app/containers/types';
import { actions as authAction } from 'app/redux/slice';
import { selectOrganization } from 'app/redux/selectors';

export function* getTicketRequest({payload}: { payload: string }) { 
  yield delay(500);
  try {
      const id = payload;
      const {data, err, status}: Res<{ data: Ticket}> = yield call(request, `${API_URL}/v1/admin/ticket/get/${id}`);
      
      if(err || !data){
          throw err;
      }
      yield put(actions.getTicketSuccess(data?.data));
  } catch (error:any) {
      yield put(actions.getTicketError());
      let errorMessage = error?.message;
      try {
        errorMessage = JSON.parse(errorMessage);
      } catch (e) {
      }
      toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
  }
}

export function* getTicketsRequest({payload}: { payload: Query }) { 
  yield delay(500);
  try {
      const query = new URLSearchParams(removeBlankProperties(payload)).toString();
      const {data, err, status}: Res<TicketListResponse> = yield call(request, `${API_URL}/v1/admin/ticket/list?${query}`);
      
      if(err || !data){
          throw err;
      }
      yield put(actions.getTicketsSuccess({
          tickets: data?.data,
          pagination: data?._metadata.pagination
      }));
  } catch (error:any) {
      yield put(actions.getTicketError());
      let errorMessage = error?.message;
      try {
        errorMessage = JSON.parse(errorMessage);
      } catch (e) {
      }
      toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
  }
}
export function* getCustomizeTicketRequest({ payload }: { payload: string }) {
  yield delay(500);
  try {
    const id = payload;
    const response: { data: any } = yield call(
      request,
      `${API_URL}/v1/service/ticket-customizations/get/${id}`
    );
    yield put(actions.getCustomizeTicketSuccess(response.data.data));
  } catch (error: any) {
    yield put(actions.getTicketError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}
export function* createTicketRequest({payload}: { payload: {ticket: Ticket; callback?: ()=>void;}}) {
  yield delay(500);
  try {
      const {err, status} = yield call(request, `${API_URL}/v1/admin/ticket/create`, {
          method: 'POST',
          body: JSON.stringify(payload.ticket)
      });
      
      if(err){
          throw err;
      }
      yield put(actions.createTicketSuccess());
      toastService.success(translate("TICKETS.SUCCESSFULLY_CREATED"));
      payload?.callback?.();
  } catch (error:any) {
      yield put(actions.createTicketError());
      let errorMessage = error?.message;
      try {
        errorMessage = JSON.parse(errorMessage);
      } catch (e) {
      }
      toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
  }
}

export function* updateTicketRequest({payload}: { payload: {ticket: Ticket; callback?: ()=>void;}}) {
  yield delay(500);
  try {
      const {data, err, status}: Res<{ data: Ticket }> = yield call(request, `${API_URL}/v1/admin/ticket/update/${payload.ticket?._id}`, {
          method: 'PUT',
          body: JSON.stringify(payload.ticket)
      });
      
      if(err || !data){
          throw err;
      }
      yield put(actions.updateTicketSuccess(data?.data));
      toastService.success(translate("TICKETS.SUCCESSFULLY_UPDATED"));
      payload?.callback?.();
  } catch (error:any) {
      yield put(actions.updateTicketError());
      let errorMessage = error?.message;
      try {
        errorMessage = JSON.parse(errorMessage);
      } catch (e) {
      }
      toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
  }
}

export function* deleteTicketRequest({payload}: { payload: string }) {
  yield delay(500);
  try {
      const {status, err} = yield call(request, `${API_URL}/v1/admin/ticket/delete/${payload}`, {
          method: 'DELETE',
      });
      
      if(err){
          throw err;
      }
      yield put(actions.deleteTicketSuccess());
      const pagination = yield select(selectPagination);
      const organization = yield select(selectOrganization);
      toastService.success(translate("TICKETS.SUCCESSFULLY_DELETED"));
      yield put(actions.getTickets({
          perPage: pagination.perPage,
          page: pagination.page,
          search: pagination.search,
          isActive: pagination.filters?.isActive,
          orderBy: pagination.orderBy,
          orderDirection: pagination.orderDirection,
          organization: organization?.value
      }));
  } catch (error:any) {
      yield put(actions.deleteTicketError());
      let errorMessage = error?.message;
      try {
        errorMessage = JSON.parse(errorMessage);
      } catch (e) {
      }
      toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
  }
}
export function* updateCustomizeTicketRequest({
  payload,
}: {
  payload: { id: string; callback?: () => void };
}) {
  yield delay(500);
  const ticket: TicketCustomizeForm = yield select(selectCustomizeTicket);

  try {
    const requestPayload = {
      gameName: ticket.gameName.value,
      asset: ticket.asset,
      questions:ticket.questions
    };

    const response: { data: Ticket } = yield call(
      request,
      `${API_URL}/v1/service/ticket-customizations/update/${ticket._id.value}`,
      {
        method: "PUT",
        body: JSON.stringify(requestPayload),
      }
    );

    toastService.success(translate("TICKETS.SUCCESSFULLY_UPDATED"));
    payload?.callback?.();
  } catch (error: any) {
    yield put(actions.updateTicketError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* shareResultsRequest({ payload }: { payload: { emails: string; id: string; callback: () => void; } }) {
  yield delay(500);
  try {
    const { status, err } = yield call(request, `${API_URL}/v1/admin/ticket/share`, {
      method: 'POST',
      body: JSON.stringify({ 
        emails: payload?.emails,
        id: payload?.id
      })
    });
    
    if (err) {
      throw err;
    }
    toastService.success(translate("TICKETS.SUCCESSFULLY_SHARED"));
    payload?.callback?.();
    yield put(actions.shareResultComplete());
  } catch (error: any) {
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {
    }
    toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
    yield put(actions.shareResultComplete());
  }
}
export function* resetFrom({
  payload,
}: {
  payload: { callback?: () => void };
}) {
  yield delay(500);
  try {
    payload?.callback?.();
  } catch (error: any) {}
}
export function* ticketsSaga() {
  yield takeLatest(actions.getTicket, getTicketRequest);
  yield takeLatest(actions.getTickets, getTicketsRequest);
  yield takeLatest(actions.createTicket, createTicketRequest);
  yield takeLatest(actions.updateTicket, updateTicketRequest);
  yield takeLatest(actions.updateCustomizeTicket, updateCustomizeTicketRequest);
  yield takeLatest(actions.shareResult, shareResultsRequest);
  yield takeLatest(actions.deleteTicket, deleteTicketRequest);
  yield takeLatest(actions.getCustomizeTicket, getCustomizeTicketRequest);
  yield takeLatest(actions.resetForm, resetFrom);
}