import React from 'react'
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectStation } from 'app/containers/Stations/redux/selector';
import { actions } from 'app/containers/Stations/redux/slice';
import TabPanel from '../TabPanel';

import Address from '../Address';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { placesReducer, placesSlice } from '../Address/redux/slice';
import { placesSaga } from '../Address/redux/saga';


interface AddressProps {
    disabled: boolean;
}

export default function Location({
    disabled,
}: AddressProps) {
    useInjectReducer({ key: placesSlice, reducer: placesReducer });
    useInjectSaga({ key: placesSlice, saga: placesSaga });
    const { t } = useTranslation();
    const station = useSelector(selectStation);
    const dispatch = useDispatch();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(actions.setFormValues({key: e.target.name, value: e.target.value}))
    }

    return (
        <TabPanel value={station?.type?.value} index={2}>
            <Grid2 md={12}>
                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "8px",
                            pr: "16px",
                            mb: "10px",
                            height: "44px",
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                        }}
                    >
                        <FormControlLabel 
                            sx={{ 
                            margin: 0,
                            
                            fontWeight: 500,
                            lineHeight: "24px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            }} 
                            disabled={disabled}
                            label={
                                <Typography 
                                    sx={{
                                        
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "14px",
                                        letterSpacing: "0em",
                                        color: "#5E6781"
                                    }}
                                >
                                    {t("STATIONS.FORM.LABELS.SHOW_STATION_ADDRESS")}
                                </Typography>}
                                control={
                                    <Checkbox 
                                        name='isAddressVisible'
                                        checked={station.isAddressVisible.value}
                                        size="small" 
                                        disabled={disabled}
                                        sx={{
                                            color: "#207DB9",
                                            '&.Mui-checked': {
                                                color: "#207DB9",
                                            },
                                        }}
                                        onChange={(e)=>dispatch(actions.setFormValues({key: 'isAddressVisible', value: e.target.checked}))}
                                    />
                                }
                        />
                    </Box>

                    {/* other */}
                    <Box
                        sx={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: "8px",
                            px: "16px",
                            mb: "10px",
                            height: "44px",
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                        }}
                    >
                        <Typography sx={{
                            
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "14px",
                            letterSpacing: "0em",
                            color: "#5E6781"
                        }}
                        >
                            {t("STATIONS.FORM.LABELS.STATION_RADIUS")}
                        </Typography> 
                        <input 
                            type="number" 
                            name='radius'
                            placeholder="__"
                            style={{
                                width: "50px",
                                height: "32px",
                                border: `1px solid  ${station?.radius?.error ? "#d32f2f" : "#26A8E0"}`,
                                borderRadius: "4px",
                                outline: "none",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "14px",
                                letterSpacing: "0em",
                                padding : "0px 10px",
                                textAlign: "center",
                                color: "#5E6781",
                                
                            }}
                            value={station.radius.value}
                            onChange={onChange}
                            disabled={disabled}
                        />
                    </Box>
                </Box>
                {/* address */}
                <Address disabled={disabled} />
            </Grid2>
        </TabPanel>
    )
}
