import { Box, Typography } from '@mui/material'
import InputField from 'app/components/InputField'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';

export default function MessageCard({
    type="primary",
    value,
    onChange,
    error,
    name,
    maxLength
}:{
    type?: "primary" | "secondary";
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error: string;
    name: string;
    maxLength?: number
}) {
    const { t } = useTranslation()
  const direction = useSelector(selectDirection);
    return (
        <Box
            sx={{
                width: "100%",
                backgroundColor: "#FFFFFF",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
                padding: "14px 16px",
                gap: "10px",
                border: "1px solid #D7D7E0"
            }}
        >
            <Typography
                sx={{
                    
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    color: type === "primary" ? "#E24399" : "#207DB9",
                    background: type === "primary" ? "#FBE4F0" : "#EDF4F8",
                    width: "fit-content",
                    padding: "6px 10px",
                    borderRadius: "4px",
                }}
            >
                {t("GAMES.FORM.MESSAGE")}
            </Typography>
            <InputField
                borderColor='transparent'
                sx={{
                    width: "100%",
                    border: "none",
                    borderRadius: "4px",
                    
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0em",
                }}
                maxLength={maxLength}
                value={value}
                onChange={onChange}
                error={error}
                name={name}
                rows={4}
                lightPlaceholder
                placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
            />
        </Box>
    )
}
