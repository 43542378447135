import { Box } from "@mui/material";
import React, { useEffect } from "react";
import InputField from "app/components/InputField";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CitySelect from "app/components/CitySelect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectStation } from "app/containers/Stations/redux/selector";
import { MODES } from "utils/constants";
import { actions } from "app/containers/Stations/redux/slice";

type Props = { mode: MODES };

const Form = ({ mode }: Props) => {
  const { t } = useTranslation();
  const station = useSelector(selectStation)
  const dispatch = useDispatch();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(actions.setFormValues({ key: name, value }));
  }
  
  return (
    <Box
      sx={{
        background: "#F3F6F8",
        padding: "24px",
        borderRadius: "8px",
      }}
    >
      <Grid2 container spacing={3}>
        <Grid2 md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <InputField
              label={t("STATIONS.FORM.LABELS.STATION_NAME")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="name"
              isFieldReq
              fullWidth={true}
              isDisable={mode === MODES.VIEW}
              lightPlaceholder
              onChange={onChange}
              value={station.name.value}
              error={station.name.error ? t(station.name.error) : undefined}
            />
            <CitySelect 
              label={t("STATIONS.FORM.LABELS.STATION_CITY")}
              disabled={mode === MODES.VIEW}
              value={station?.city?.value}
              onChange={(value) => dispatch(actions.setFormValues({ key: "", value }))}
              error={station?.city?.error ? t(station?.city?.error) : undefined}
              isFieldReq={false}
            />
          </Box>
        </Grid2>
        <Grid2 
          md={4}
          sx={{
            borderLeft: "1px solid #E0E0E0",
            borderRight: "1px solid #E0E0E0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <InputField
              label={t("STATIONS.FORM.LABELS.GO_TO_MESSAGE")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="goToMessage1"
              fullWidth={true}
              isDisable={mode === MODES.VIEW}
              lightPlaceholder
              onChange={onChange}
              value={station.goToMessage1?.value}
              error={station.goToMessage1?.error ? t(station.goToMessage1?.error) : undefined}
            />
            <InputField
              label={t("STATIONS.FORM.LABELS.GO_TO_MESSAGE")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="goToMessage2"
              fullWidth={true}
              isDisable={mode === MODES.VIEW}
              lightPlaceholder
              onChange={onChange}
              value={station.goToMessage2?.value}
              error={station.goToMessage2?.error ? t(station.goToMessage2?.error) : undefined}
            />
            <InputField
              label={t("STATIONS.FORM.LABELS.FEEDBACK_FOR_SUCCESSFUL_ARRIVAL")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="feedBackOnSuccessfulArrival"
              fullWidth={true}
              isDisable={mode === MODES.VIEW}
              lightPlaceholder
              onChange={onChange}
              maxLength={50}
              value={station.feedBackOnSuccessfulArrival?.value}
              error={station.feedBackOnSuccessfulArrival?.error ? t(station.feedBackOnSuccessfulArrival?.error) : undefined}
            />
          </Box>
        </Grid2>
        <Grid2 md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InputField
              label={t("STATIONS.FORM.LABELS.STATION_INSTRUCTION")}
              placeholder={t("COMMON.PLACEHOLDERS.ENTER")}
              name="stationInstruction"
              fullWidth={true}
              isDisable={mode === MODES.VIEW}
              lightPlaceholder
              multiline
              rows={9}
              onChange={onChange}
              value={station.stationInstruction?.value}
              error={station.stationInstruction?.error ? t(station.stationInstruction?.error) : undefined}
            />
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Form;
