/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { API_URL, translate } from "utils/constants";
import { Res, request } from "utils/request";
import { actions } from "./slice";
import toastService from "utils/toast";
import { removeBlankProperties } from "utils/helpers";
import { Query, QueryWithCallback } from "app/containers/types";
import { CityResponse } from "../types";
import { actions as authAction } from 'app/redux/slice';

/**
 * Root saga manages watcher lifecycle
 */

export function* getCitiesRequest({payload}: { payload: QueryWithCallback }) { 
    try {
        const query = new URLSearchParams(removeBlankProperties(payload)).toString();
        const {data, err, status}: Res<CityResponse> = yield call(request, `${API_URL}/v1/admin/address/cities/list?${query}`);
        
        if(err || !data){
          throw err;
        }
        yield put(actions.setCities({
            cities: data.data,
            pagination: data._metadata.pagination
        }));
        if(payload.callback && data[0]) {
          payload.callback(data[0])
        }
    } catch (error:any) {
        yield put(actions.getCitiesError());
        let errorMessage = error?.message;
        try {
          errorMessage = JSON.parse(errorMessage);
        } catch (e) {
        }
        toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
    }
}

export function* citiesSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.getCities, getCitiesRequest);
}
