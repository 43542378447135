import { FormControlLabel, Radio, Tab, Tabs, Typography } from '@mui/material'
import { selectStation } from 'app/containers/Stations/redux/selector';
import { actions } from 'app/containers/Stations/redux/slice';
import { StationTypes } from 'app/containers/Stations/types';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { getStationType } from 'utils/constants';

export default function OptionsSelect({
    disabled
}:{
    disabled: boolean
}) {
    const station = useSelector(selectStation);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    return (
        <Tabs
            value={station?.type?.value}
            onChange={(e, value) => dispatch(actions.setFormValues({ key: "type", value: getStationType(value) }))} // dispatch is not defined
            variant="fullWidth"
            style={{ borderBottom: "none" }}
            TabIndicatorProps={{ style: { display: "none" } }}
        >
           
            <Tab
                disabled={disabled}
                tabIndex={0}
                label={
                    <FormControlLabel
                        value={0}
                        control={
                            <Radio
                                size="small"
                                disabled={disabled}
                                checked={station?.type?.value === StationTypes.QR}
                                sx={{
                                    color: "#207DB9",
                                    "&.Mui-checked": {
                                    color: "#207DB9",
                                    },
                                }}
                            />
                        }
                        label={
                            <Typography style={{ color: "#000000", fontWeight: 500 }}>
                                {t("STATIONS.FORM.QR")}
                            </Typography>
                        }
                        sx={{ marginTop: "15px" }}
                    />
                }
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "14px 0px",
                    width: "170px",
                    height: "52px",
                    background: "#FFFFFF",
                    border: `1px solid ${station?.type?.value === StationTypes.QR ? "#207DB9" : "#F2F2F2"} `,
                    boxShadow:
                    "10px 28px 12px rgba(172, 174, 175, 0.01), 6px 16px 10px rgba(172, 174, 175, 0.05), 3px 7px 8px rgba(172, 174, 175, 0.09), 1px 2px 4px rgba(172, 174, 175, 0.1), 0px 0px 0px rgba(172, 174, 175, 0.1)",
                    borderRadius: "4px",
                    margin: "0px 5px",
                    backgroundColor: "#FFFFFF",
                }}
            />
            <Tab
                tabIndex={1}
                disabled={disabled}
                label={
                    <FormControlLabel
                        value={1}
                        control={
                            <Radio
                                size="small"
                                disabled={disabled}
                                checked={station?.type?.value === StationTypes.CODE}
                                sx={{
                                    color: "#207DB9",
                                    "&.Mui-checked": {
                                    color: "#207DB9",
                                    },
                                }}
                            />
                        }
                        label={
                        <Typography
                            style={{
                                color: "#000000",
                                fontWeight: 500,
                                textTransform: "capitalize",
                            }}
                        >
                            {t("STATIONS.FORM.CODE")}
                        </Typography>
                    }
                    sx={{ marginTop: "15px" }}
                    />
                }
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "14px 0px",
                    height: "52px",
                    background: "#FFFFFF",
                    border: `1px solid ${station?.type?.value === StationTypes.CODE ? "#207DB9" : "#F2F2F2"} `,
                    boxShadow:
                    "10px 28px 12px rgba(172, 174, 175, 0.01), 6px 16px 10px rgba(172, 174, 175, 0.05), 3px 7px 8px rgba(172, 174, 175, 0.09), 1px 2px 4px rgba(172, 174, 175, 0.1), 0px 0px 0px rgba(172, 174, 175, 0.1)",
                    borderRadius: "4px",
                    margin: "0px 5px",
                    backgroundColor: "#FFFFFF",
                }}
            />
             <Tab
                disabled={disabled}
                tabIndex={2}
                label={
                    <FormControlLabel
                    value={StationTypes.POINT}
                    control={
                        <Radio
                            value={2}
                            disabled={disabled}
                            size="small"
                            checked={station?.type?.value === StationTypes.POINT}
                            sx={{
                                color: "#207DB9",
                                "&.Mui-checked": {
                                color: "#207DB9",
                                },
                            }}
                        />
                    }
                    label={
                        <Typography
                            style={{
                                color: "#000000",
                                fontWeight: 500,
                                textTransform: "capitalize",
                            }}
                        >
                            {t("STATIONS.FORM.ADDRESS")}
                        </Typography>
                    }
                    sx={{ marginTop: "15px" }}
                    />
                }
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "14px 0px",
                    width: "170px",
                    height: "52px",
                    background: "#FFFFFF",
                    border: `1px solid ${station?.type?.value === StationTypes.POINT ? "#207DB9" : "#F2F2F2"} `,
                    boxShadow:
                    "10px 28px 12px rgba(172, 174, 175, 0.01), 6px 16px 10px rgba(172, 174, 175, 0.05), 3px 7px 8px rgba(172, 174, 175, 0.09), 1px 2px 4px rgba(172, 174, 175, 0.1), 0px 0px 0px rgba(172, 174, 175, 0.1)",
                    borderRadius: "4px",
                    margin: "0px 5px",
                    backgroundColor: "#FFFFFF",
                }}
            />
        </Tabs>
    )
}
