import React, { useEffect } from 'react';
import {
  BoldItalicUnderlineToggles,
  MDXEditor,
  UndoRedo,
  linkDialogPlugin,
  linkPlugin,
  CreateLink,
  toolbarPlugin,
  MDXEditorMethods,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { Box } from '@mui/material';
import { useStyles } from './style';
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';
const EditorComponent = ({ editable, value, onChange }) => {
  const style = useStyles();
  const mdxEditorRef = React.useRef<MDXEditorMethods>(null)
  useEffect(()=>{
    mdxEditorRef.current?.setMarkdown(value)
  },[value])
  return (
    <Box className={style.root}     >
      <MDXEditor
  
        readOnly={!editable}
        ref={mdxEditorRef}
        markdown={''}
        onChange={onChange}
        plugins={[
          linkPlugin(), // Link plugin enabled
          linkDialogPlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <Box className={style.toolbarWrap} >
                <UndoRedo />
                <BoldItalicUnderlineToggles />
                <CreateLink />
              </Box>
            ),
          }),
        ]}
      />
    </Box>
  );
};

export default EditorComponent;
