import InputField from 'app/components/InputField';
import { selectStation } from 'app/containers/Stations/redux/selector';
import { actions } from 'app/containers/Stations/redux/slice';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TabPanel from '../TabPanel';

interface CodeProps {
    disabled: boolean;
}

export default function Code({
    disabled,
}:CodeProps) {
    const { t } = useTranslation();
    const station = useSelector(selectStation);
    const dispatch = useDispatch();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch(actions.setFormValues({ key: 'code', value }));
        dispatch(actions.setFormValues({ key: 'qrText', value }));
    }
    return (
        <TabPanel value={station?.type?.value} index={1}>
            <InputField
                label={t("STATIONS.FORM.LABELS.STATION_CODE")}
                placeholder={t("STATIONS.FORM.PLACEHOLDERS.ENTER_STATION_CODE")}
                name="code"
                fullWidth={true}
                value={station.code.value}
                error={station.code.error ? t(station.code.error) : ""}
                isDisable={disabled}
                lightPlaceholder
                sx={{
                    "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                        border: "none",
                    },
                    },
                    marginBottom: "20px",
                }}
                onChange={onChange}
            />
        </TabPanel>
    )
}
