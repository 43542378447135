import React, { useState } from "react";
const SVG =({ isSelected }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill={isSelected ? "#E24399" : "white" } />
      <path
        d="M11.1744 4.03321C9.27684 4.26617 7.63107 5.05148 6.34226 6.34781C3.92245 8.77513 3.3175 12.4349 4.82424 15.5123C6.40238 18.7362 9.93064 20.5172 13.4514 19.8672C15.2324 19.5403 16.8669 18.6047 18.043 17.2407C20.1885 14.7457 20.6094 11.2663 19.1139 8.34302C17.9528 6.07351 15.751 4.4841 13.2184 4.08957C12.775 4.01818 11.5689 3.98812 11.1744 4.03321ZM12.4857 4.6344C14.5185 4.77343 16.3822 5.72782 17.6898 7.29845C18.6555 8.45575 19.2642 9.98128 19.3619 11.488C19.5197 13.874 18.539 16.1548 16.7016 17.6878C15.27 18.8827 13.3838 19.4877 11.49 19.3599C8.55165 19.1645 5.96651 17.1768 5.01587 14.3775C4.23056 12.0742 4.63261 9.53414 6.09427 7.56523C6.36856 7.19699 7.20648 6.35908 7.56719 6.09606C8.72449 5.24687 10.1035 4.72834 11.4487 4.6344C11.6779 4.61937 11.8883 4.60434 11.9071 4.60434C11.9296 4.60058 12.1889 4.61561 12.4857 4.6344Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M11.2136 6.77149C9.8872 7.06833 9.0042 8.07909 8.99293 9.32281C8.98541 9.67601 9.03426 9.86388 9.18831 10.0931C9.42128 10.4425 9.8083 10.6304 10.2479 10.6003C10.8529 10.559 11.2812 10.1344 11.3564 9.50692C11.3939 9.2176 11.4353 9.17251 11.7171 9.10112C11.9388 9.04851 12.3296 9.07857 12.4911 9.16124L12.6076 9.22136L12.4874 9.38668C12.4197 9.48062 12.1079 9.80376 11.796 10.1081C11.3 10.5928 11.2098 10.7018 11.0858 10.946C10.8641 11.4007 10.8378 11.5435 10.8378 12.4302V13.2193L10.9431 13.4447C11.067 13.7003 11.2887 13.9219 11.548 14.0422C11.6795 14.1023 11.7922 14.1211 12.0214 14.1211C12.292 14.1173 12.3483 14.1061 12.5588 13.9896C12.818 13.8543 12.9683 13.6927 13.0998 13.426C13.1712 13.2794 13.1863 13.1817 13.1975 12.6557L13.2126 12.0545L13.5808 11.705C14.2233 11.1001 14.7343 10.4237 14.8959 9.96158C15.0124 9.63468 15.0387 9.05603 14.956 8.72913C14.7268 7.80855 14.0392 7.1322 13.0284 6.82785C12.5738 6.68882 11.6983 6.66252 11.2136 6.77149ZM12.5738 7.35389C13.7311 7.57558 14.4751 8.40598 14.3962 9.37541C14.3511 9.89394 14.0317 10.3862 13.205 11.2053L12.6226 11.7839L12.6039 12.4453C12.5888 13.0202 12.5738 13.1216 12.5099 13.2306C12.2957 13.5875 11.7922 13.6063 11.5367 13.2644C11.4653 13.1667 11.4578 13.0991 11.4466 12.5129C11.4315 11.8065 11.4653 11.5698 11.6194 11.2654C11.7434 11.0174 11.8373 10.9047 12.2093 10.5703C12.818 10.0217 13.2426 9.47686 13.2426 9.23639C13.2426 8.93579 12.9758 8.66525 12.5625 8.5375C12.3408 8.46986 12.2319 8.45859 11.905 8.47362C11.5518 8.48865 11.4879 8.50368 11.2624 8.62016C10.928 8.78925 10.8078 8.95833 10.7627 9.32281C10.7026 9.79249 10.5297 9.98788 10.1728 9.98788C9.8346 9.98788 9.59788 9.73613 9.59788 9.37165C9.60163 8.5901 10.1051 7.85364 10.8604 7.52298C11.3188 7.32383 12.044 7.25244 12.5738 7.35389Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M11.6008 14.6253C11.3153 14.723 11.0898 14.9259 10.947 15.2115C10.8456 15.4181 10.8231 15.5121 10.808 15.8052C10.778 16.4139 10.9508 16.8347 11.3416 17.0977C11.5595 17.2443 11.7286 17.2969 12.0029 17.2969C12.2772 17.2969 12.4463 17.2443 12.6642 17.0977C13.055 16.8347 13.2278 16.4139 13.1978 15.8052C13.1827 15.5083 13.1602 15.4181 13.0587 15.2115C12.792 14.6704 12.1795 14.4224 11.6008 14.6253ZM12.2434 15.2303C12.5026 15.358 12.5477 15.4557 12.559 15.9066C12.5703 16.2673 12.5665 16.3124 12.4839 16.4289C12.2471 16.7783 11.7587 16.7783 11.5219 16.4289C11.4393 16.3124 11.4355 16.2673 11.4468 15.9066C11.4581 15.4557 11.5032 15.3618 11.7587 15.2303C11.9465 15.1363 12.0517 15.1363 12.2434 15.2303Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
    </svg>
  );
};
export default SVG