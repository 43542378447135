import makeStyles from "@mui/styles/makeStyles";


export const useStyles = makeStyles(() => ({
    loginButtonWrapper: {
        width: "100%",
    },
    heading: {
      fontSize: 16,
      fontWeight: 500,
      textAlign: 'center',
      color: '#000',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: "500px"
    },
    button: {
      width: '100%',
      height: 40,
      fontSize: 22,
      marginTop: 11,
    },
    loginText: {
      
      fontSize: "48px",
      fontWeight: 400,
      lineHeight: "58px",
      letterSpacing: "0em",
      textAlign: "center",
      color: "#207DB9",
      marginBottom: "16px"
    },
    icon: {
      width: 48,
      height: 48,
      color: '#B2B2B2',
      transform: 'rotateY(180deg)',
      margin: "16px 0px 14px 0px",
    },
    input: {
      marginTop: 24,
    }
  }))