import { Box, Typography } from '@mui/material'
import React from 'react'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { useTranslation } from 'react-i18next';
import { selectDirection } from 'styles/theme/slice';
import { useSelector } from 'react-redux';

export type Type = "phone" | "address" | "email" | "website"

const renderIcon = (type:Type) => {
    switch (type) {
        case "phone":
            return <LocalPhoneOutlinedIcon 
                sx={{ 
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#E24399",
                    color: "#FFFFFF",
                    borderRadius: "4px",
                    padding: "2px",
                }} 
            />
        case "address":
            return <RoomOutlinedIcon 
                sx={{ 
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#E24399",
                    color: "#FFFFFF",
                    borderRadius: "4px",
                    padding: "2px",
                }} 
            />
        case "email":
            return <EmailOutlinedIcon 
                sx={{ 
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#E24399",
                    color: "#FFFFFF",
                    borderRadius: "4px",
                    padding: "2px",
                }} 
            />
        case "website":
            return <LanguageOutlinedIcon 
                sx={{ 
                    width: "16px",
                    height: "16px",
                    backgroundColor: "#E24399",
                    color: "#FFFFFF",
                    borderRadius: "4px",
                    padding: "2px",
                }} 
            />
        default:
            break;
    }
}

export default function ContactItem({
    type,
    text
}:{
    type: Type
    text: string
}) {
    const direction = useSelector(selectDirection);
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "center",
            }}
        >
            {renderIcon(type)}
            <Typography
                sx={{
                    
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "12px",
                    letterSpacing: "0em",
                    color: "#FFFFFF"
                }}
            >
                {text}
            </Typography>
        </Box>
    )
}
