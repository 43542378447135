import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import DirectionKeys from "./DirectionKeys";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Question } from "app/containers/Stations/types";
import { formatUUID } from "styles/theme/utils";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import QuestionBodyWithAnswer from "app/components/QuestionBodyWithAnswer";

interface StationQuestionTileProps {
  readonly isEven?: boolean;
  readonly disabled?: boolean;
  readonly question?: Question;
  readonly index: number;
  readonly handleRemoveClick?: () => void;
}

export default function StationQuestionTile({
  isEven,
  disabled,
  question,
  index,
  handleRemoveClick,
}: StationQuestionTileProps) {
  const { i18n } = useTranslation();
  const isHebrew = i18n.language === "he";

  return (
    <Box
      sx={{
        width: "100%",
        height: "52px",
        padding: "14px 10px",
        borderRadius: "16px",
        border: "1px solid #86BDE3",
        angle: "-180 deg",
        "&:nth-of-type(odd)": {
          backgroundColor: "#F3F6F8",
        },
        "&:nth-of-type(even)": {
          backgroundColor: "#ffffff",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          width: "40%",
        }}
      >
        <DirectionKeys
          index={index}
          disabled={disabled}
          backgroundColor={isEven ? "#F3F6F8" : "#FFFFFF"}
        />
        <Box>
          <Link
            target="_blank"
            to={`/app/questions/${question?._id}/view`}
            style={{
              
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "16px",
              letterSpacing: "0em",
              color: "#2F80ED",
              cursor: "pointer",
            }}
          >
            {formatUUID(question?._id) || "N/A"}
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          width: "50%",
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "100%",
            color: "#3C3C43",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <QuestionBodyWithAnswer question={question as any} />

        </Typography>
      </Box>
      <IconButton
        disabled={disabled}
        sx={{
          width: "20px",
          height: "20px",
          padding: "0px",
          backgroundColor: "#3C3C43",
          "&:disabled": {
            backgroundColor: "#D7D7E0",
          },
        }}
        onClick={handleRemoveClick}
      >
        {isHebrew ? (
          <ArrowBackIosIcon
            sx={{
              fontSize: "10px",
              color: "#ffffff",
            }}
          />
        ) : (
          <ArrowForwardIosIcon
            sx={{
              fontSize: "10px",
              color: "#ffffff",
            }}
          />
        )}
      </IconButton>
    </Box>
  );
}
