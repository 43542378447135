import React, { useState } from "react";
const SVG =({ isSelected }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill={isSelected ? "#E24399" : "white" } />
      <path
        d="M11.5228 5.39422C10.4645 5.58522 9.58775 6.39619 9.30282 7.44513C9.20888 7.78956 9.19949 8.43457 9.28716 8.77587C9.46251 9.45846 9.86956 10.0565 10.4144 10.4354C10.6336 10.5857 11.05 10.7735 11.3255 10.8456C11.6794 10.9395 12.3181 10.9395 12.6719 10.8456C12.9475 10.7735 13.3639 10.5857 13.5831 10.4354C14.1279 10.0565 14.535 9.45846 14.7103 8.77587C14.798 8.43457 14.7886 7.78956 14.6947 7.44513C14.4379 6.49326 13.7491 5.78248 12.7815 5.4725C12.5592 5.40048 12.4465 5.38483 12.0927 5.37543C11.861 5.3723 11.6042 5.37856 11.5228 5.39422ZM12.434 6.34922C12.7502 6.42437 13.0477 6.59658 13.3013 6.85021C14.3346 7.88975 13.8023 9.64946 12.3588 9.95005C11.9455 10.0377 11.5823 9.9845 11.1659 9.78097C10.2547 9.33008 9.89774 8.22792 10.3611 7.29796C10.5803 6.8596 11.0751 6.46821 11.5479 6.35235C11.7921 6.29286 12.1866 6.29286 12.434 6.34922Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M6.62929 8.00444C6.22224 8.10463 5.89347 8.30503 5.58661 8.6338C4.76312 9.51365 4.88523 10.9509 5.84024 11.6742C6.06255 11.8432 6.39445 12.0029 6.64494 12.0655C6.86412 12.1188 7.36198 12.1188 7.58429 12.0655C7.82852 12.0061 8.22618 11.8088 8.4391 11.6397C8.75847 11.3861 9.0528 10.9133 9.153 10.4968C9.20936 10.2557 9.2031 9.77354 9.14048 9.52618C8.95261 8.79349 8.33577 8.17978 7.61247 8.00757C7.37137 7.95121 6.84847 7.95121 6.62929 8.00444ZM7.44026 8.93752C7.7565 9.02206 8.09154 9.34457 8.20113 9.66708C8.27314 9.88939 8.26375 10.2182 8.17921 10.4499C8.07901 10.7098 7.79408 10.9947 7.53732 11.0918C7.12714 11.2452 6.66999 11.1606 6.35375 10.8726C5.73065 10.3058 5.91225 9.2976 6.69504 8.97823C6.90483 8.89055 7.20855 8.8749 7.44026 8.93752Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M16.3721 8.00757C15.6551 8.18917 15.0476 8.79975 14.8598 9.52618C14.7971 9.77354 14.7909 10.2557 14.8472 10.4968C14.9474 10.9133 15.2418 11.3861 15.5611 11.6397C15.7741 11.8088 16.1717 12.0061 16.4159 12.0655C16.6382 12.1188 17.1361 12.1188 17.3553 12.0655C17.6058 12.0029 17.9377 11.8432 18.16 11.6742C18.9397 11.0855 19.1839 9.99272 18.7393 9.08782C18.6516 8.90621 18.5608 8.78723 18.3416 8.57118C18.0567 8.28624 17.8782 8.17352 17.4962 8.03888C17.2801 7.9606 16.6257 7.94494 16.3721 8.00757ZM17.2801 8.9657C18.0755 9.26316 18.2758 10.2964 17.6465 10.8726C17.3302 11.1606 16.8731 11.2452 16.4629 11.0918C16.2062 10.9947 15.9212 10.7098 15.821 10.4499C15.7365 10.2182 15.7271 9.88939 15.7991 9.66708C15.9024 9.3571 16.2437 9.02206 16.5381 8.94065C16.7447 8.88116 17.086 8.89368 17.2801 8.9657Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M10.0713 11.246C9.47013 11.3932 8.90339 11.8065 8.61219 12.3075C8.5527 12.4139 8.5026 12.4672 8.47442 12.4578C8.31473 12.3951 7.80749 12.3732 6.92137 12.3826C6.0196 12.3951 5.92253 12.4045 5.70648 12.4672C5.29943 12.5893 5.0051 12.7646 4.68885 13.0809C4.37574 13.3909 4.20666 13.6695 4.07202 14.0985C4 14.3302 4 14.3459 4 15.8989C4 17.621 4 17.6336 4.191 17.9529C4.37261 18.2567 4.6732 18.4884 5.01449 18.5886C5.16792 18.6324 5.87243 18.6387 12.0001 18.6387C18.1278 18.6387 18.8323 18.6324 18.9857 18.5886C19.327 18.4884 19.6276 18.2567 19.8092 17.9529C20.0002 17.6336 20.0002 17.621 20.0002 15.8989C20.0002 14.3459 20.0002 14.3302 19.9282 14.0985C19.7936 13.6695 19.6245 13.3909 19.3114 13.0809C18.9951 12.7646 18.7008 12.5893 18.2937 12.4672C18.0777 12.4045 17.9806 12.3951 17.0788 12.3826C16.1927 12.3732 15.6855 12.3951 15.5258 12.4578C15.4976 12.4672 15.4475 12.4139 15.388 12.3075C15.172 11.9349 14.8025 11.6061 14.3704 11.4057C13.9038 11.1897 13.8694 11.1865 11.9782 11.1897C10.4846 11.1897 10.2686 11.1959 10.0713 11.246ZM13.7567 12.176C14.0228 12.2542 14.1919 12.3544 14.3767 12.536C14.5583 12.7208 14.6585 12.8899 14.7367 13.156C14.7806 13.3063 14.7868 13.5944 14.7868 15.5138V17.6993H12.0001H9.21338V15.5138C9.21338 13.1466 9.21024 13.1905 9.38559 12.846C9.47326 12.6738 9.7488 12.392 9.91789 12.3043C10.2592 12.129 10.2592 12.129 11.9876 12.129C13.3684 12.1259 13.6095 12.1321 13.7567 12.176ZM8.23645 13.3564C8.26464 13.3752 8.27403 13.848 8.27403 15.542V17.6993H6.75855H5.2462L5.13974 17.6273C4.92369 17.4833 4.92369 17.4958 4.92369 15.9897C4.92369 15.2007 4.93622 14.5682 4.955 14.4711C5.06146 13.9106 5.528 13.4441 6.08222 13.3501C6.28888 13.3157 8.18009 13.322 8.23645 13.3564ZM17.8867 13.3501C18.4659 13.441 18.9356 13.895 19.0452 14.4711C19.064 14.5682 19.0765 15.2007 19.0765 15.9897C19.0765 17.4958 19.0765 17.4833 18.8605 17.6273L18.754 17.6993H17.2385H15.7262L15.7324 15.5294L15.7418 13.3627L15.8514 13.3439C16.0393 13.3094 17.6738 13.3157 17.8867 13.3501Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
    </svg>
  );
};
export default SVG