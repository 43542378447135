import React, { useEffect, useState, useCallback } from 'react';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { Option } from 'app/types';
import { actions } from '../redux/slice';
import { selectEditors, selectIsLoading, selectPagination } from '../redux/selector';
import { selectOrganization } from 'app/redux/selectors';
import { selectDirection } from 'styles/theme/slice';
import { allEditorOption } from '../helper';
import { Editor } from 'app/containers/Editors/types';

interface SelectInputProps {
  readonly value?: Option;
  readonly onChange?: (newValue?: Option | null) => void;
  readonly isLoading?: boolean;
  readonly disabled?: boolean;
  readonly error?: string;
  readonly backgroundColor?: string;
  readonly borderWidth?: number;
  readonly showAll?: boolean; // Prop to control "Show All" option
}

export default function SelectInput({
  value: propValue, // Renaming the value prop for clarity
  onChange,
  isLoading,
  error,
  disabled,
  backgroundColor,
  borderWidth,
  showAll = true
}: SelectInputProps) {
  const [value, setValue] = useState<Option | null>(propValue || null);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const loadingMore = useSelector(selectIsLoading);
  const pagination = useSelector(selectPagination);
  const organization = useSelector(selectOrganization);
  const editors = useSelector(selectEditors);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const direction = useSelector(selectDirection);

  // Update internal state when prop value changes
  useEffect(() => {
    if(propValue?.label) {
      setValue(propValue || null);
    }else if(propValue?.value && !propValue?.label) {
      if(open) {
          fetchEditors('')
      }else{
        dispatch(
          actions.getEditors({
            page: 1,
            perPage: pagination.perPage,
            search: propValue?.value,
            isActive: pagination.filters?.isActive,
            organization: organization?.value,
            callback: (e: Editor) => {
              setValue({label: e.fullName || e.email , value: e._id as string})
            }
          })
        )
      }
      
    }else if(showAll && !propValue && !open) {
      setValue(allEditorOption)
    } 
  }, [propValue, showAll, open]);

  const fetchEditors = useCallback(
    debounce((searchQuery: string) => {
      dispatch(
        actions.getEditors({
          page: 1,
          perPage: pagination.perPage,
          search: searchQuery == allEditorOption.label ? '' : searchQuery,
          isActive: pagination.filters?.isActive,
          organization: organization?.value,
        })
      );
    }, 300),
    [dispatch, pagination.perPage, pagination.filters?.isActive, organization?.value]
  );

  useEffect(() => {
    fetchEditors(search);
  
  }, [search, dispatch, organization?.value, fetchEditors]);

  const handleScroll = (event: React.SyntheticEvent) => {
    if (loadingMore) return;
    const listboxNode = event.currentTarget;
    const bottom = Math.ceil(listboxNode.scrollTop + listboxNode.clientHeight) >= listboxNode.scrollHeight;

    if (bottom) {
      const hasMore = pagination.page < pagination.totalPage;
      if (!hasMore) return;
      dispatch(
        actions.getEditors({
          page: pagination.page + 1,
          perPage: pagination.perPage,
          search: search,
          isActive: pagination.filters?.isActive,
          organization: organization?.value,
        })
      );
    }
  };

  const handleChange = (e: any, newValue: Option | null) => {
    setValue(newValue); // Update internal state
    onChange?.(newValue); // Trigger parent handler if provided
  };

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      getOptionLabel={(option) => option?.label ?? ''}
      getOptionKey={(option) => option?.value}
      onChange={handleChange}
      options={[
        ...(showAll ? [allEditorOption] : []), // Conditionally include "Show All"
        ...(editors?.map((c) => ({
          label: c.fullName,
          value: c._id as string,
        })) || []),
      ]}
      disabled={disabled}
      loadingText={
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '14px',
            textAlign: 'left',
          }}
        >
          {t('COMMON.TEXT.LOADING')}
        </Typography>
      }
      noOptionsText={
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '14px',
            textAlign: 'left',
          }}
        >
          {t('COMMON.TEXT.NO_OPTIONS')}
        </Typography>
      }
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '14px',
              color: '#5E6781',
              py: '4px',
            }}
          >
            {option?.label}
          </Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('COMMON.PLACEHOLDERS.SELECT')}
          disabled={disabled}
          sx={{
            width: '100%',
            backgroundColor: backgroundColor ?? '#FFFFFF',
            borderRadius: '4px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: error ? '#d32f2f' : '#86BDE3',
                borderRadius: '4px',
                color: '#263238',
                borderWidth: borderWidth ?? 1,
              },
              '&:hover fieldset': {
                borderColor: error ? '#d32f2f' : '#86BDE3',
                borderRadius: '4px',
                color: '#263238',
                borderWidth: borderWidth ?? 1,
              },
              '&.Mui-focused fieldset': {
                borderColor: error ? '#d32f2f' : '#86BDE3',
                borderRadius: '4px',
                color: '#263238',
                borderWidth: borderWidth ?? 1,
              },
            },
            '& .MuiFormHelperText-root': {
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '18px',
              letterSpacing: '0em',
              margin: 0,
              color: '#d32f2f',
            },
            input: {
              '&::placeholder': {
                color: '#727F89',
                opacity: '100%',
                fontWeight: '400',
                fontSize: '14px',
              },
              fontWeight: '400',
              fontSize: '14px',
              color: '#263238',
              backgroundColor: backgroundColor ?? '#FFFFFF',
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {(isLoading || loadingMore) ? (
                  <CircularProgress color="inherit" size={16} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          helperText={error}
          onChange={(e) => setSearch(e.target.value)}
        />
      )}
      ListboxProps={{ onScroll: handleScroll }}
    />
  );
}
