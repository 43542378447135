import { Box, IconButton, SxProps, Theme } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ActionButtons from "./components/ActionButtons";
import { useTranslation } from "react-i18next";
import { selectDirection } from "styles/theme/slice";
import { useSelector } from "react-redux";

export default function EditableColumn({
  value,
  isOdd = false,
  containerStyle,
  innerContainerStyle,
  inputStyle,
  disabled,
  maxLength,
  onSave,
}: {
  value: string;
  isOdd?: boolean;
  containerStyle?: SxProps<Theme>;
  innerContainerStyle?: SxProps<Theme>;
  inputStyle?: React.CSSProperties;
  disabled?: boolean;
  maxLength?: number;
  onSave: (val: string) => void;
}) {
  const direction = useSelector(selectDirection);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const isFocused = isEditing || isHovered;
  const border = !isFocused
    ? "1px solid transparent"
    : isEditing
    ? "1px solid #207DB9"
    : "1px solid #E0E0E0";
  const [textValue, setTextValue] = useState("");
  useEffect(() => {
    setTextValue(value);

    return () => {};
  }, [value]);

  const onChange = (value: string) => {
    if (!maxLength) {
      setTextValue(value);
    } else {
      if(value.length>maxLength){
        setTextValue(value.slice(0,maxLength));
      }else{
        setTextValue(value);
      }
      
    }
  };

  return (
    <Box
      onMouseEnter={disabled ? undefined : () => setIsHovered(true)}
      onMouseLeave={disabled ? undefined : () => setIsHovered(false)}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        ...containerStyle,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          height: "100%",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          alignSelf: "center",
          maxWidth: "150px",
          width: "100%",
          border,
          ...innerContainerStyle,
        }}
      >
        <input
          autoFocus
          value={textValue}
          contentEditable={isEditing}
          max={maxLength}
          onChange={(e) => onChange(e.target.value)}
          disabled={!isEditing}
          style={{
            border: "none",
            outline: "none",
            
            fontSize: "14px",
            backgroundColor: "transparent",
            fontWeight: 500,
            lineHeight: "14px",
            letterSpacing: "0em",
            color: "#5E6781",
            width: "100%",
            padding: "0px",
            margin: "0px",
            ...inputStyle,
          }}
        />
        {isHovered && !isEditing && (
          <IconButton
            sx={{
              padding: "0px",
              margin: "0px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => setIsEditing(true)}
          >
            <EditOutlinedIcon
              sx={{
                color: "#8890A6",
                fontSize: "16px",
              }}
            />
          </IconButton>
        )}
        {isEditing && (
          <ActionButtons
            isOdd={isOdd}
            onCancel={() => {
              setIsHovered(false);
              setIsEditing(false);
              setTextValue(value);
            }}
            onSave={() => {
              onSave(textValue);
              setIsHovered(false);
              setIsEditing(false);
            }}
          />
        )}
      </Box>
    </Box>
  );
}
