import Skeleton from "@mui/material/Skeleton";
import React from "react";


type Props = {
  colspan?: number;
  children?: any;
  className?: string;
};

const TableLoader = ({ colspan = 1, children, className = "" }: Props) => {
  return (
    <Skeleton
      variant="rectangular"
      width={'100%'}
      height={40}
      sx={{
        bgcolor: "#F5F5F5",
      }}
    />
  );
};

export default TableLoader;
