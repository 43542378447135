import { Box, Typography } from '@mui/material'
import React, { useState }  from 'react'
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import dayjs from 'dayjs';
import Calendar from '../Calendar';
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';

export default function DatePicker({
    value,
    onChange,
    disabled=false
}:{
    readonly value: Date;
    readonly onChange: (v: Date) => void;
    readonly disabled?: boolean;
}) {
    const [open, setOpen] = useState(false);
  const direction = useSelector(selectDirection);
    return (
        <>
            <Box
                onClick={disabled ? ()=>{} : () => setOpen(true)}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: "36px",
                    padding: "6px 12px 6px 12px",
                    borderRadius: "4px",
                    backgroundColor: "#F3F6F8",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    cursor: "pointer",
                }}
            >
                <Typography
                    sx={{
                        
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        opacity: disabled ? 0.5 : 1,
                    }}
                >
                    {value ? dayjs(value).format("DD/MM/YYYY") : "__/__/____"}
                </Typography>
                <CalendarMonthSharpIcon 
                    sx={{
                        fontSize: "20px",
                        cursor: "pointer",
                        color: "#3C3C43",
                        opacity: disabled ? 0.5 : 1,
                    }}
                />
            </Box>
            <Calendar
                open={open}
                onClose={() => setOpen(false)}
                value={value}
                onChange={onChange}
            />
        </>
    )
}
