import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Question } from "app/containers/Stations/types";
import { formatUUID } from "styles/theme/utils";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import QuestionBodyWithAnswer from "app/components/QuestionBodyWithAnswer";

interface QuestionTileProps {
  readonly isEven?: boolean;
  readonly disabled?: boolean;
  readonly question?: Question;
  readonly handleBackClick?: () => void;
}

export default function QuestionTile({
  isEven,
  disabled,
  question,
  handleBackClick,
}: QuestionTileProps) {
  const { i18n } = useTranslation();
  const isHebrew = i18n.language === "he";
  return (
    <Box
      sx={{
        marginTop: "8px",
        width: "100%",
        height: "52px",
        padding: "14px 10px",
        borderRadius: "16px",
        border: "1px solid #86BDE3",
        gap: "10px",
        angle: "-180 deg",
        "&:nth-of-type(odd)": {
          backgroundColor: "#F3F6F8",
        },
        "&:nth-of-type(even)": {
          backgroundColor: "#ffffff",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          width: "30%",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            width: "30px",
          }}
        >
          <IconButton
            disabled={disabled}
            sx={{
              width: "20px",
              height: "20px",
              padding: "0px",
              backgroundColor: "#E24399",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:disabled": {
                backgroundColor: "#D7D7E0",
              },
            }}
            onClick={handleBackClick}
          >
            {isHebrew ? (
              <KeyboardArrowRightIcon
                sx={{
                  fontSize: "16px",
                  color: "#ffffff",
                }}
              />
            ) : (
              <KeyboardArrowLeftIcon
                sx={{
                  fontSize: "16px",
                  color: "#ffffff",
                }}
              />
            )}
          </IconButton>
        </Box>
        <Link
          to={`/app/questions/${question?._id}/view`}
          target="_blank"
          style={{
            
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16px",
            letterSpacing: "0em",
            color: "#2F80ED",
            cursor: "pointer",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          {" "}
          {formatUUID(question?._id)}
        </Link>
      </Box>
      <Box
        sx={{
          width: "40%",
        }}
      >
        <Typography
          noWrap
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "100%",
            color: "#3C3C43",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "300px",
            height: "40px",
          }}
        >
          <QuestionBodyWithAnswer question={question as any} />
        </Typography>
      </Box>
      <Box
        sx={{
          width: "30%",
        }}
      >
        <Link
          target="_blank"
          to={`/app/editors/${question?.user?._id}/view`}
          style={{
            
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16px",
            letterSpacing: "0em",
            color: "#2F80ED",
            cursor: "pointer",
          }}
        >
          {question?.user?.fullName || "N/A"}
        </Link>
      </Box>
    </Box>
  );
}
