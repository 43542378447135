import { Box, Typography } from "@mui/material";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import InputField from "app/components/InputField";
import React from "react";
import { useStyles } from "./styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectConfirmPassword,
  selectLoading,
  selectPassword,
} from "../../redux/selectors";
import { actions } from "../../redux/slice";
import { validate } from "../../validator";
import { useTranslation } from "react-i18next";

export default function ResetPasswordForm({
  onSuccess,
}: {
  onSuccess: (v: boolean) => void;
}) {
  const styles = useStyles();
  const password = useSelector(selectPassword);
  const confirmPassword = useSelector(selectConfirmPassword);
  const isLoading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const email = searchParams?.[0]?.get("email");
  const hash = searchParams?.[0]?.get("hash");
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(
      actions.setFormValues({
        key: name,
        value: value,
      })
    );
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const errors = validate({
      password: password,
      confirmPassword: confirmPassword,
    });
    if (errors?.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.submit({
        callback: () => onSuccess(true),
        creds: {
          password: password.value,
          hash: hash as string,
          email: email as string,
        },
      })
    );
  };
  return (
    <form className={styles.form} noValidate>
      <Box width="100%" mt={1}>
        <Typography className={styles.loginText}>
          {t("AUTH.RESET_PASSWORD.TITLE")}
        </Typography>
        <Typography
          sx={{
            
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "27px",
            letterSpacing: "0em",
            textAlign: "center",
            marginBottom: "32px",
            color: "#8890A6",
          }}
        >
          {t("AUTH.RESET_PASSWORD.CAPTION")}
        </Typography>
      </Box>
      <InputField
        label={t("AUTH.FORM.PASSWORD")}
        onChange={onChange}
        name="password"
        type="password"
        value={password.value}
        error={password.error}
        isDisable={isLoading}
        tooltip={t("AUTH.FORM.PASSWORD_TOOLTIP")}
        fullWidth
        mainStyle={{ paddingBottom: "24px", width: "100%" }}
        labelStyle={{ fontWeight: 600 }}
      />
      <InputField
        label={t("AUTH.FORM.CONFIRM_PASSWORD")}
        onChange={onChange}
        name="confirmPassword"
        type="password"
        value={confirmPassword.value}
        error={confirmPassword.error}
        isDisable={isLoading}
        fullWidth
        tooltip={t("AUTH.FORM.PASSWORD_TOOLTIP")}
        mainStyle={{ paddingBottom: "24px", width: "100%" }}
        labelStyle={{ fontWeight: 600 }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit(e);
          }
        }}
      />
      <Box
        className={styles.loginButtonWrapper}
        sx={{
          my: 3,
        }}
      >
        <PrimaryButton
          label={t("AUTH.BUTTONS.SET_PASSWORD")}
          sx={{
            width: "100%",
            height: "48px",
            textTransform: "none",
          }}
          onClick={onSubmit}
          isLoading={isLoading}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          flexDirection: "row",
          marginBottom: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            cursor: "pointer",
          }}
          onClick={() => navigate("/auth/login")}
        >
          {t("AUTH.FORM.BACK_TO_LOGIN")}
        </Typography>
      </Box>
    </form>
  );
}
