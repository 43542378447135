import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectDirection } from 'styles/theme/slice'

export default function CheckboxItem({
    label,
    disabled,
    name,
    onChange,
    checked
}:{
    label?: string
    disabled?: boolean
    name?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    checked?: boolean
}) {
  const direction = useSelector(selectDirection);
    return (
        <FormControlLabel
            sx={{
                margin: 0,
                
                fontWeight: 500,
                lineHeight: "24px",
                letterSpacing: "0em",
            }}
            disabled={disabled}
            label={
                <Typography
                    sx={{
                        
                        fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "14px",
                    letterSpacing: "0em",
                    }}
                >
                    {label}
                </Typography>
            }
            control={
                <Checkbox
                    size="small"
                    checked={checked}
                    name={name}
                    onChange={onChange}
                    sx={{
                        color: "#207DB9",
                        "&.Mui-checked": {
                            color: "#207DB9",
                        },
                    }}
                />
            }
        />
    )
}
