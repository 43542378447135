import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SingleSelect from "./components/SingleSelect";
import MultiSelect from "./components/MultiSelect";
import Correlation from "./components/Correlation";
import MixedLetters from "./components/MixedLetters";
import FreeText from "./components/FreeText";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { ASSET_TYPE, MODES, QUESTION_TYPES } from "utils/constants";
import QuestionTypesDropdown from "app/components/QuestionTypesDropdown";
import { useDispatch, useSelector } from "react-redux";
import { selectForm } from "app/containers/Questions/redux/selector";
import { actions } from "app/containers/Questions/redux/slice";
import ConfirmDialog from "app/components/ConfirmDialog";
import { Typography } from "@mui/material";
import { selectDirection } from "styles/theme/slice";

type Props = { mode: MODES };
const QuestionTypes = ({ mode }: Props) => {
  const [confirmDelete, setConfirmDelete] = React.useState({
    status: false,
    id: -1,
  });
  const dispatch = useDispatch();

  const form = useSelector(selectForm);

  const onChange = (name, value) => {
    dispatch(actions.setFormValues({ key: name, value }));
  };
  const deleteItem = () => {
    dispatch(
      actions.setUpdateAnswer({
        index: confirmDelete.id,
        key: "asset",
        type:"singleSelect",
        value: {
          _id: "",
          type: ASSET_TYPE.IMAGE,
          mimeType: "",
          fileName: "",
          filePath: "",
          url: "",
        },
      })
    );
    setConfirmDelete({
      status: false,
      id: -1,
    });
  };

  const { t } = useTranslation();
  const direction = useSelector(selectDirection);


  const renderValue = (v: string) => {
    return (
      <Typography
        sx={{
          
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "14px",
          letterSpacing: "0em",
          color: "#3C3C43",
        }}
      >
        {t(`QUESTIONS.TYPES.${v?.toUpperCase()??"ALL"}`)}
      </Typography>
    );
  };

  return (
    <Grid2 container spacing={1}>
      <Grid2 md={3}>
        <QuestionTypesDropdown
          onChange={(v) => onChange("type", v)}
          value={form.type.value}
          disabled={mode === MODES.VIEW }
          isMandatory={true}
          renderValue={renderValue}
          hideAll={true}
        />
      </Grid2>
      {form.type.value === QUESTION_TYPES.SINGLE_SELECT && (
        <Grid2 xs={12}>
          <SingleSelect
            mode={mode}
            disabled={mode === MODES.VIEW }
            onDelete={(id) => {
              setConfirmDelete({
                status: true,
                id: id,
              });
            }}
          />
        </Grid2>
      )}
      {form.type.value === QUESTION_TYPES.MULTI_SELECT && (
        <Grid2 xs={12}>
          <MultiSelect mode={mode} disabled={mode === MODES.VIEW } />
        </Grid2>
      )}
      {form.type.value === QUESTION_TYPES.CORRELATION && (
        <Grid2 xs={12}>
          <Correlation mode={mode} disabled={mode === MODES.VIEW } />
        </Grid2>
      )}
      {form.type.value === QUESTION_TYPES.MIXED_LETTER && (
        <Grid2 xs={12}>
          <MixedLetters mode={mode} disabled={mode === MODES.VIEW } />
        </Grid2>
      )}
      {form.type.value === QUESTION_TYPES.FREE_TEXT && (
        <Grid2 xs={12}>
          <FreeText mode={mode} disabled={mode === MODES.VIEW } />
        </Grid2>
      )}
      {form.type.value === QUESTION_TYPES.TEXT_CREATIVE_ASSIGNMENT && (
        <Grid2 xs={12}>
          <div style={{ height: "300px" }} />
        </Grid2>
      )}
      {form.type.value === QUESTION_TYPES.VIDEO_CREATIVE_ASSIGNMENT && (
        <Grid2 xs={12}>
          <div style={{ height: "300px" }} />
        </Grid2>
      )}
      {form.type.value === QUESTION_TYPES.IMAGE_CREATIVE_ASSIGNMENT && (
        <Grid2 xs={12}>
          <div style={{ height: "300px" }} />
        </Grid2>
      )}
      {form.type.value === QUESTION_TYPES.NONE && (
        <Grid2 xs={12}>
          <div style={{ height: "50px" }} />
        </Grid2>
      )}
      <ConfirmDialog
        open={confirmDelete.status}
        handleClose={() =>
          setConfirmDelete({
            status: false,
            id: -1,
          })
        }
        onConfirm={deleteItem}
        title={t("COMMON.DELETE.TITLE", { name: t("COMMON.LABELS.IMAGE") })}
        message={t("COMMON.DELETE.MESSAGE", { name: t("COMMON.LABELS.IMAGE") })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.DELETE")}
      />
    </Grid2>
  );
};

export default QuestionTypes;
