/**
 * Gets the repositories of the user from Github
 */

import { call, delay, put, takeLatest } from "redux-saga/effects";
import { API_URL, translate } from "utils/constants";
import { Res, request } from "utils/request";
import { actions } from "./slice";
import toastService from "utils/toast";
import { removeBlankProperties } from "utils/helpers";
import { Query } from "app/containers/types";
import { GameResponse } from "app/containers/Games/types";
import { actions as authAction } from 'app/redux/slice';

/**
 * Root saga manages watcher lifecycle
 */

export function* getGamesRequest({payload}: { payload: Query }) {
  yield delay(300) 
    try {
        const query = new URLSearchParams(removeBlankProperties(payload)).toString();
        const {data, err, status}: Res<GameResponse> = yield call(request, `${API_URL}/v1/admin/game/list?${query}`);
        
        if(err || !data){
          throw err;
        }
        yield put(actions.setGames({
            games: data.data,
            pagination: data._metadata.pagination
        }));
    } catch (error:any) {
        yield put(actions.getGamesError());
        let errorMessage = error?.message;
        try {
          errorMessage = JSON.parse(errorMessage);
        } catch (e) {
        }
        toastService.error(errorMessage?.message || errorMessage || translate("COMMON.ERRORS.MESSAGE"));
    }
}

export function* gamesSelectSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.getGames, getGamesRequest);
}
