import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import { FormError } from "app/containers/types";
import { ContainerState } from "../types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  password: {
    value: "",
  },
  confirmPassword: {
    value: "",
  },
  loading: false,
  error: null,
};

const formSlice = createSlice({
  name: "createPasswordState",
  initialState,
  reducers: {
    setFormValues: (state, action: PayloadAction<{ key: string; value: string }>) => {
      const { key, value } = action.payload;
      set(state, `${key}.value`, value);
      set(state, `${key}.error`, "");
    },
    setFormErrors(
      state,
      action: PayloadAction<Array<FormError>>
    ) {
      action.payload.forEach((error) => {
        set(state, `${error.name}.error`, error.error);
      });
    },
    create(state, action: PayloadAction<{ callback: () => void, creds: { password: string; hash: string; email: string }  }>) {
      state.loading = true;
      state.error = null;
    },
    createSuccess(state) {
      state.password = {
        value: "",
      };
      state.confirmPassword = {
        value: "",
      };
      state.loading = false;
      state.error = null;
    },
    createError(state) {
      state.loading = false;
    }
  },
});

export const { actions, reducer: createPasswordReducer, name: createPasswordSlice } = formSlice;
