import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Stack({
    label,
    value
}:{
    label: string,
    value: string
}) {
    const { t } = useTranslation()
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
            }}
        >
            <Typography
                sx={{
                    
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    color: "#FFFFFF",
                }}
            >
                {t(`RESULTS.LABELS.${label}`)}
            </Typography>
            <Typography
                sx={{
                    
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "100%",
                    letterSpacing: "0em",
                    color: "#FFFFFF",
                }}
            >
                {value}
            </Typography>
        </Box>
    )
}
