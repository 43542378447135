import { Add } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { actions } from "app/containers/Tickets/redux/slice";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomizeTicketQuestion } from "app/containers/Tickets/redux/selector";
import toastService from "utils/toast";
import { selectDirection } from "styles/theme/slice";
import { useTranslation } from "react-i18next";
export default function Header() {
  const dispatch = useDispatch();
  const question = useSelector(selectCustomizeTicketQuestion);
  const direction = useSelector(selectDirection);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "24px",
          letterSpacing: "0em",
          color: "#3C3C43",
        }}
      >
        {t("TICKETS.CUSTOMIZED_TICKETS.CORRECT_ANSWER")}
      </Typography>
      <Typography
        sx={{
          
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "24px",
          letterSpacing: "0em",
          color: "#E24399",
          cursor: "pointer",
        }}
        onClick={() => {
          if (question.answers.length < 7) {
            dispatch(
              actions.addCustomizeQuestionAnswer({
                _id: "",
                title: "",
                isCorrect: false,
                order: 0,
                isDelete: false,
              })
            );
          }else{
            toastService.error( "Max 7 answer only ")
          }
        }}
      >
        <Add
          sx={{
            fontSize: "20px",
          }}
        />
        {t("TICKETS.CUSTOMIZED_TICKETS.ADD_ANSWER")}
      </Typography>
    </Box>
  );
}
