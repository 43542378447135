import React from 'react';
import { Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { KeyboardArrowDown } from '@mui/icons-material';
import listImage from 'assets/list.png';
import tileImage from 'assets/tile.png';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';

type Props = {
  onChange?: any;
  isEdit?: boolean | undefined;
  defaultValue?: any;
  multipleDefaultValue?: string[];
  label: string;
  placeholder?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | undefined;
  isDisable?: boolean;
  mt?: string;
  mb?: string;
  minWidth?: string;
  backgroundColor?: string;
  value?: any;
  name?: any;
  editable?: any;
  inputProps?: any;
  onBlur?: any;
  error?: any;
  mainStyle?: any;
  type?: string;
  sx?: any;
  labelFontWeight?: number | string;
  className?: any;
  menuItems: any;
  multiple?: boolean;
  labelColor?: string;
  labelStyle?: React.CSSProperties;
  isMandatory?:boolean
  renderValue?: any;
};

const useStyles = makeStyles(() => ({
  inputField: {
    background: "#FFF",
    boxShadow:
      "10px 31px 9px rgba(232, 232, 232, 0.01), 7px 20px 8px rgba(textEnabled232, 232, 232, 0.04), 4px 11px 7px rgba(232, 232, 232, 0.15), 2px 5px 5px rgba(232, 232, 232, 0.26), 0px 1px 3px rgba(232, 232, 232, 0.29), 0px 0px 0px rgba(232, 232, 232, 0.3)",
    borderRadius: "4px",
    minWidth: "100%",
    input: {
      '&::placeholder': {
        color: '#727F89',
        opacity: '100%',
        fontWeight: '400',
        fontSize: '14px',
      },
    },
    fontWeight: '400',
    fontSize: '14px',
    
  },
  icon: {
    fill: 'E24399',
  },
}));

const ITEM_PADDING_TOP = 8;
const ITEM_HEIGHT = 48;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const DropDown = ({
  label,
  placeholder,
  fullWidth=true,
  size,
  isDisable,
  mt,
  mb,
  minWidth,
  backgroundColor,
  onChange,
  value,
  name,
  editable,
  inputProps,
  onBlur,
  error,
  mainStyle,
  type,
  sx,
  labelFontWeight,
  className,
  menuItems,
  labelColor,
  labelStyle,
  defaultValue,
  isMandatory,
  renderValue
}: Props) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  return (
    <div style={mainStyle}>
      {label && (
        <Typography
          sx={{
            
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0em",
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            ...labelStyle,
            color: isDisable ? '#727F89' : labelColor ? labelColor : "#1D1F20"
          }}
        >
          {label}
       {isMandatory&& <span style={{ color: "#ff0000" }}> *</span>}   
        </Typography>
      )}
      <Select
        onChange={onChange}
        onBlur={onBlur}
        size={size}
        disabled={isDisable}
        className={clsx(styles.inputField, className)}
        IconComponent={KeyboardArrowDown}
        displayEmpty
        fullWidth
        value={value}
        defaultValue={defaultValue}
        renderValue={(selected: any) => {
          if (selected?.length === 0) {
            return <p style={{ color: "#A4A9AB", margin: 0 }}>{t("COMMON.PLACEHOLDERS.SELECT")}</p>;
          }
          if(renderValue){
            return renderValue(selected);
          }
          return selected;
        }}
      >
        {menuItems}
      </Select>
      {/* {!!error && <Typography sx={{ marginTop: 0, color: '#ff0000' }}>{error}</Typography>} */}
    </div>
  );
};

export const DropDownForObj = (props: Props) => {
  const labelFontWeight = props.labelFontWeight ? props.labelFontWeight : 400;
  const styles = useStyles();
  return (
    <div style={props.mainStyle}>
      {props.label && (
        <Typography
          sx={{
            mt: props.mt ?? '0px',
            mb: props.mb ?? '0px',
            fontWeight: labelFontWeight,
            color: props.isDisable ? '#727F89' : props.labelColor ? props.labelColor : "#3C3C43"
          }}
        >
          {props.label}
        </Typography>
      )}
      <Select
        onChange={props.onChange}
        onBlur={props.onBlur}
        size={props.size}
        disabled={props.isDisable}
        className={clsx(styles.inputField, props.className)}
        IconComponent={KeyboardArrowDown}
        classes={{
          icon: styles.icon,
        }}
        displayEmpty
        fullWidth
        defaultValue={props.defaultValue ? props.defaultValue : ''}
        renderValue={(selected: any) => {
          if (selected?.length === 0) {
            return <p style={{ color: "#A4A9AB", margin: 0 }}>{props.placeholder}</p>;
          }
          return selected === "List View"
            ? (<>
              <img src={listImage} style={{ width: "20px", height: "20px" }} />
              <span style={{ color: "#E24399", fontSize: "14px", fontWeight: 500, marginLeft: "5px" }}>{selected}</span>
            </>)
            : (<>
              <img src={tileImage} style={{ width: "20px", height: "20px" }} />
              <span style={{ color: "#E24399", fontSize: "14px", fontWeight: 500, marginLeft: "5px" }}>{selected}</span>
            </>);
        }}
      >
        {props.menuItems}
      </Select>
      {/* {!!props.error && <Typography sx={{ marginTop: 0, color: '#ff0000' }}>{props.error}</Typography>} */}
    </div>
  );
};

export const MultipleDropDown = (props: Props) => {
  const labelFontWeight = props.labelFontWeight ? props.labelFontWeight : 500;
  const styles = useStyles();
  return (
    <div style={props.mainStyle}>
      {props.label && (
        <Typography
          sx={{
            mt: props.mt ?? '0px',
            mb: props.mb ?? '0px',
            fontWeight: labelFontWeight,
            color: props.isDisable ? '#727F89' : "#1D1F20"
          }}
        >
          {props.label}
        </Typography>
      )}
      <Select
        multiple
        onChange={props.onChange}
        onBlur={props.onBlur}
        size={props.size}
        disabled={props.isDisable}
        className={clsx(styles.inputField, props.className)}
        IconComponent={KeyboardArrowDown}
        displayEmpty
        fullWidth
        value={props.multipleDefaultValue ? props.multipleDefaultValue : ''}
        renderValue={(selected: any) => {
          if (selected?.length === 0) {
            return <p style={{ color: "#A4A9AB", margin: 0 }}>{props.placeholder}</p>;
          }
          return selected.join(', ')
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {props.menuItems}
      </Select>
      {/* {!!props.error && <Typography sx={{ marginTop: 0, color: '#ff0000' }}>{props.error}</Typography>} */}
    </div>
  );
};
