import { Box, Typography } from '@mui/material'
import React from 'react'
import MenuOptions from './components/MenuOptions'
import { CustomizeQuestion } from 'app/containers/Tickets/types';
import { useTranslation } from 'react-i18next';

export default function QuestionCard({
    question,
    onDelete,
    onEdit
}:{
    question: CustomizeQuestion;
    onDelete: ()=>void
    onEdit: ()=>void
}) {
    const {t} = useTranslation();
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: "12px 10px 12px 10px",
                borderRadius: "12px",
                border: "1px solid #D7D7E0",
                gap: "10px",
                backgroundColor: "#FFFFFF",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    sx={{
                        
                        fontSize: "12px",
                        fontWeight: 600,
                        lineHeight: "12px",
                        letterSpacing: "0em",
                        color: "#5E6781",
                        background: "#F7F6F4",
                        padding: "8px",
                        borderRadius: "50px",
                    }}
                >
                    {t("TICKETS.CUSTOMIZED_TICKETS.QUESTION_BODY")}
                </Typography>
                <MenuOptions 
                    onDelete={onDelete}
                    onEdit={onEdit}
                />
            </Box>
            <Box>
                <Typography
                    sx={{
                        
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        letterSpacing: "0em",
                        color: "#8890A6"
                    }}
                >
                    {question.body}
                </Typography>
            </Box>
        </Box>
    )
}
