import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import { TableHeader } from '../TableHeader';
import TableLoader from '../TableLoader';
import { useStyles } from './styles';
import { OrderByTypes, TableHeaderColumnType } from './types';
import { LEFT_SPACING } from 'utils/constants';
import { CircularProgress } from '@mui/material';

export const StyledTableContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
});



export const StyledRow = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingLeft: theme.direction == 'ltr' ?  LEFT_SPACING : 0,
  paddingRight: theme.direction == 'rtl' ?  LEFT_SPACING : 0,
  '&:hover': {
    backgroundColor: '#F5F5F540',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#F4F8FF',
  },
}));

interface StyledCellProps {
  minWidth?: string | number;
  maxWidth?: string | number;
}

export const StyledCell = styled('div')(({  minWidth, maxWidth }: StyledCellProps) => ({
  flex: 1,
  display: 'inline',
  alignItems: 'center',
  padding: '12px 14px',
  '&:last-of-type': {
    borderRight: 'none',
  },
  backgroundColor: 'transparent',
  textAlign: 'unset',
  whiteSpace: 'nowrap',
  height: 50,
  minWidth: minWidth || 'auto',
  maxWidth: maxWidth || 'none',
  fontSize: '14px',
  lineHeight: '16px',
  color: "#3C3C43",
  textOverflow: 'ellipsis',


}));
export const TableBody = styled('div')({})
export const NoRecords = styled('div')({
  textAlign: 'center',
  padding: '20px',
  flex: 1,
});

interface TableProps {
  readonly id?: string;
  readonly onSort?: (value: { name: string; direction: OrderByTypes }) => void;
  readonly orderBy?: {
    name: string;
    direction: OrderByTypes;
  };
  readonly isColoredHeader?: boolean;
  readonly headers?: TableHeaderColumnType[];
  readonly selectAllHandler?: () => void;
  readonly isAllSelected?: boolean;
  readonly showSelect?: boolean;
  readonly loading?: boolean;
  readonly children: React.ReactNode;
  readonly hasMore?: boolean;
  readonly alignShowSelect?: 'left' | 'center' | 'right';
  readonly loadMore?: () => void;
  readonly dataLength?: number;
}

export default function CustomTable({
  id,
  onSort = () => {},
  orderBy,
  headers = [],
  selectAllHandler,
  isAllSelected,
  showSelect = false,
  loading,
  children,
  hasMore = false,
  alignShowSelect,
  loadMore = () => {},
  dataLength,
  isColoredHeader = false,
}: TableProps) {
  const styles = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();


  const { height } = useWindowDimensions();
  const applicableHeight = height - 240;

  return (
    <div
      id="scrollableDiv"
      className={styles.dataTableDiv}
      style={{
        direction: theme.direction,
        height: applicableHeight,
        maxHeight: applicableHeight,
      }}
    >
      <InfiniteScroll
        dataLength={dataLength ?? 0}
        loader={null}
        hasMore={hasMore}
        next={loadMore}
        scrollableTarget="scrollableDiv"
        
        style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
      
      >
            <TableHeader
              isColoredHeader={isColoredHeader}
              isAllSelected={isAllSelected}
              columnValues={headers}
              orderBy={orderBy}
              onRequestSort={onSort}
              showSelect={showSelect}
              selectAllHandler={selectAllHandler}
              alignShowSelect={alignShowSelect}
            />

              
              {children && (children as React.ReactNode[]).length ? (
                children
              ) : (
                <StyledRow>
                  <StyledCell
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      
                    }}
                  >
                    {!loading ? t('COMMON.TEXT.NO_RECORDS_FOUND') : t('COMMON.TEXT.LOADING') }
                  </StyledCell>
                </StyledRow>
              )}
              {loading && (
                <StyledRow>
                  <StyledCell
                    style={{ 
                      textAlign: 'center', 
                      
                    }}
                  >
                    <CircularProgress color="inherit" size={25} /> 
                  </StyledCell>
                </StyledRow>
              )}
       

      </InfiniteScroll>
    </div>
  );
}
