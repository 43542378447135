import { BorderColor } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  mainContainer: {
    background: "#FFFFFF",
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
    bottom: "auto",
  },
  root: {
    padding: "0px 30px 0px 260px",
  },

  imgClass: {
    height: 20,
    width: 20,
    cursor: "pointer",
  },
  textHeader: {
    color: "#2A3333",
    marginLeft: "14px",
    fontWeight: 600,
    
    fontSize: "18px",
    lineHeight: "29.98px",
    fontStyle: "Semibold",
  },
  textHeaderT: {
    color: "#2E40C8",
    fontWeight: 600,
    
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "29.98px",
  },
  mainHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: "100px",
  },
  label: {
    
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#2A3333",
  },
  commentSaveBtn: {
    background: "#C66078",
    color: "#ffff",

    "&:hover": {
      background: "#C66078",
    },

    
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    width: "120px",
    height: "56px",
  },
  tabLabel: {
    color: "#C66078",
    fontWeight: "bold",
  },
  textEnabled: {
    background: "#FFF",
    border: "none !important",
    "& fieldset": { border: "none" },
    boxShadow:
      "10px 31px 9px rgba(232, 232, 232, 0.01), 7px 20px 8px rgba(232, 232, 232, 0.04), 4px 11px 7px rgba(232, 232, 232, 0.15), 2px 5px 5px rgba(232, 232, 232, 0.26), 0px 1px 3px rgba(232, 232, 232, 0.29), 0px 0px 0px rgba(232, 232, 232, 0.3)",
    borderRadius: "4px",
  },
  uploadBox: {
    borderRadius: "4px",
    borderWidth: "1px",
    borderColor: "#6C7A7A",
    height: "56px",
    position: "relative",
    top: "47px",
    left: "19px",
    width: "378px",
    background: "#FFF",
    textAlign: "center",
    boxShadow:
      "10px 31px 9px rgba(232, 232, 232, 0.01), 7px 20px 8px rgba(232, 232, 232, 0.04), 4px 11px 7px rgba(232, 232, 232, 0.15), 2px 5px 5px rgba(232, 232, 232, 0.26), 0px 1px 3px rgba(232, 232, 232, 0.29), 0px 0px 0px rgba(232, 232, 232, 0.3)",
  },
  uploadLabel: {
    
    fontStyle: "normal",
    fontWeight: 600,
    positon: "relative",
    bottom: "30px",
    fontSize: "16px",
    lineHeight: "21.79px",
    marginLeft: "16px",
    color: "#727F89",
  },
  addBtnImage: {
    filter: "greyscale(100%)"
  },
  breadCrumbSectionParent: {
    flex: 1,
    color: "#207DB9",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: 'sticky',
    top: '-25px',
    background: "#fff",
    zIndex:1,
    transition: 'transform 0.3s ease, background 0.3s ease',
    marginBottom: "6px",
    marginTop: "-25px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  breadCrumbTitle: {
    marginLeft: "10px",
  },
  addColor: {
    marginLeft: "8px",
    color: "#012497",
  },
  floatingAddBtnStyle: {
    marginTop: "20px",
    marginBottom: "20px",
    left: 'auto',
    backgroundColor: "#0616BA"
  },
  search: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 3,
      margin: 0,
      background: "#FFFFFF",
      width: "450px",
      height: "50px",
    },
  },
  leftBox: {
    display: "flex",
    height: "100%",
    marginTop: "25px",
  },
}));
