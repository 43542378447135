import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ResultPageData, ResultsListItemType, ResultsState } from "../types";
import { OrderDirections, Pagination, Query } from "app/containers/types";
import { Result } from "../types/results";
import { OrderByTypes } from "app/components/Table/types";
import orderBy from 'lodash/orderBy'

const initialState = ResultsState;

export const formSlice = createSlice({
  name: "resultsState",
  initialState,
  reducers: {
    getResults: (state, action: PayloadAction<Query>) => {
      state.loading = true;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    delete: (state) => {
      state.error = "";
      state.loading = true;
    },
    deleteSuccess: (state) => {
      state.loading = false;
      state.error = "";
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getResultsSuccess: (
      state,
      action: PayloadAction<{ data: ResultsListItemType[]; pagination: Pagination }>
    ) => {
      state.list = action.payload.pagination.page === 1 ? action.payload.data : [...state.list, ...action.payload.data];
      state.pagination = action.payload.pagination;
      state.loading = false;
    },
    getTicketResultsSuccess: (
      state,
      action: PayloadAction<{ data?: ResultPageData }>
    ) => {
      if(action.payload.data) {
        state.list = action.payload.data.results;
        state.ticket = action.payload.data.ticket;
      } 
      state.loading = false;
    },
    getResultError  : (state) => {
      state.loading = false;
    },
    getTicketResult: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    setCurrentResult: (state, action: PayloadAction<string | undefined>) => {
      state.currentResult = action.payload;
    },
    onSort: (state, action: PayloadAction<{ name: string; direction: OrderDirections }>) => {
      const direction = action.payload.direction.toLowerCase();
      state.list = orderBy(state.list, [action.payload.name], [direction]);
 },
    getTicketResultInfo: (state, action: PayloadAction<string>) => {
      state.resultInfo.loading = true
    },

    getTicketResultInfoSuccess: (
      state,
      action: PayloadAction<{ data: {results: Result[] }}>
    ) => {
      state.resultInfo.loading = false
      state.resultInfo.results = action.payload.data.results
    },
    getTicketResultInfoError: (state) => {
      state.resultInfo.loading = false
    }
    
  },
});

export const { reducer: resultsReducer, actions, name: resultsSlice } = formSlice;
