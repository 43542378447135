import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL, ASSET_TYPE, translate } from "utils/constants";
import { Res, request } from "utils/request";
import { actions } from "./slice";
import { removeBlankProperties } from "utils/helpers";
import toastService from "utils/toast";
import {
  Answer,
  CorelationAnswer,
  FreeTextAnswer,
  MixedLetterAnswer,
  Question,
  QuestionListResponse,
  QuestionsForm,
  SingleMultiSelectAnswer,
} from "../types";
import { Query } from "app/containers/types";
import { selectAnswer, selectForm, selectPagination } from "./selector";
import { actions as authAction } from "app/redux/slice";
import { selectOrganization } from "app/redux/selectors";

export function* getQuestionRequest({
  payload,
}: {
  payload: { id: string; duplicate?: boolean };
}) {
  yield delay(500);
  try {
    const { id, duplicate } = payload;
    const { data, err, status }: Res<{ data: Question }> = yield call(
      request,
      `${API_URL}/v1/admin/question/get/${id}`
    );
    
    if (err || !data) {
      throw err;
    }
    yield put(
      actions.getQuestionSuccess({
        ...data?.data,
        body: duplicate ? `Copy of ${data?.data?.body}` : data?.data?.body,
      })
    );
  } catch (error: any) {
    yield put(actions.getQuestionError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* getQuestionsRequest({ payload }: { payload: Query }) {
  yield delay(500);
  try {
    let query = new URLSearchParams(
      removeBlankProperties(payload)
    )

    if(query.get("isCustomQuestion")){
      query.delete("user");
      query.delete("type");
    }

    const { data, status, err }: Res<QuestionListResponse> = yield call(
      request,
      `${API_URL}/v1/admin/question/list?${query.toString()}`
    );
    
    if (err || !data) {
      throw err;
    }
    yield put(
      actions.getQuestionsSuccess({
        data: data?.data,
        pagination: data?._metadata.pagination,
      })
    );
  } catch (error: any) {
    yield put(actions.getQuestionError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* createQuestionRequest({
  payload,
}: {
  payload: { callback?: () => void };
}) {
  yield delay(500);
  try {
    const form: QuestionsForm = yield select(selectForm);
    const answer: Array<Answer> = yield select(selectAnswer);
    let requestAnswer: Array<any> = [];
    if (form.type.value === "single_select") {
      requestAnswer = answer.map((x) => x.singleSelect);
    }

    if (form.type.value === "multi_select") {
      requestAnswer = answer.map((x) => x.multiSelect);
    }

    if (form.type.value === "mixed_letter") {
      requestAnswer = answer.map((x) => x.mixedLetter);
    }

    if (form.type.value === "correlation") {
      requestAnswer = answer.map((x) => x.corelation);
    }

    if (form.type.value === "free_text") {
      requestAnswer = answer.map((x) => x.freeText);
    }

    const data = {
      type: form.type.value,
      rightAnswerComment: form.rightAnswerComment.value,
      body: form.body.value,
      link: form.link.value,
      linkLabel: form.linkLabel.value,
      organizationId: form.organizationId.value,
      score: form.score.value || 0,
      showLink: JSON.parse(form.showLink.value),
      asset: form.asset?.type == ASSET_TYPE.NONE ? { id: null } : form.asset,
      answer: requestAnswer,
    };

    const { status, err } = yield call(
      request,
      `${API_URL}/v1/admin/question/create`,
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    
    if (err) {
      throw err;
    }
    yield put(actions.createQuestionSuccess());

    toastService.success(translate("QUESTIONS.SUCCESSFULLY_CREATED"));
    yield put(actions.resetForm({ callback: () => {} }));
    payload?.callback?.();
  } catch (error: any) {
    yield put(actions.createQuestionError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* updateQuestionRequest({
  payload,
}: {
  payload: { callback?: () => void };
}) {
  yield delay(500);
  try {
    const form: QuestionsForm = yield select(selectForm);

    const answer: Array<Answer> = yield select(selectAnswer);
    let requestAnswer: Array<any> = [];
    if (form.type.value === "single_select") {
      requestAnswer = answer.map((x) => x.singleSelect);
    }

    if (form.type.value === "multi_select") {
      requestAnswer = answer.map((x) => x.multiSelect);
    }

    if (form.type.value === "mixed_letter") {
      requestAnswer = answer.map((x) => x.mixedLetter);
    }

    if (form.type.value === "correlation") {
      requestAnswer = answer.map((x) => x.corelation);
    }

    if (form.type.value === "free_text") {
      requestAnswer = answer.map((x) => x.freeText);
    }

    const data = {
      type: form.type.value,
      rightAnswerComment: form.rightAnswerComment.value,
      body: form.body.value,
      link: form.link.value,
      linkLabel: form.linkLabel.value,
      organizationId: form.organizationId.value,
      score: form.score.value || 0,
      showLink: JSON.parse(form.showLink.value),
      asset: form.asset?.type == ASSET_TYPE.NONE ? { id: null } : form.asset,
      answer: requestAnswer,
      isCustomQuestion: form.isCustomQuestion
    };
    const { status, err } = yield call(
      request,
      `${API_URL}/v1/admin/question/update/${form._id}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
      }
    );
    
    if (err) {
      throw err;
    }
    yield put(actions.updateQuestionSuccess());
    toastService.success(translate("QUESTIONS.SUCCESSFULLY_UPDATED"));
    yield put(actions.resetForm({ callback: () => {} }));
    payload?.callback?.();
  } catch (error: any) {
    yield put(actions.updateQuestionError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* deleteQuestionRequest({ payload }: { payload: string }) {
  yield delay(500);
  try {
    const organization = yield select(selectOrganization);
    const { status, err } = yield call(
      request,
      `${API_URL}/v1/admin/question/delete/${payload}`,
      {
        method: "DELETE",
      }
    );
    
    if (err) {
      throw err;
    }
    yield put(actions.deleteQuestionSuccess());
    const pagination = yield select(selectPagination);
    toastService.success(translate("QUESTIONS.SUCCESSFULLY_DELETED"));
    yield put(
      actions.getQuestions({
        perPage: pagination.perPage,
        page: pagination.page,
        search: pagination.search,
        organization: organization ? organization.value : undefined,
        isActive: pagination.filters?.isActive,
        orderBy: pagination.orderBy,
        orderDirection: pagination.orderDirection,
      })
    );
  } catch (error: any) {
    yield put(actions.deleteQuestionError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}
export function* resetFrom({
  payload,
}: {
  payload: { callback?: () => void };
}) {
  yield delay(500);
  try {
    payload?.callback?.();
  } catch (error: any) {}
}
export function* questionsSaga() {
  yield takeLatest(actions.getQuestion, getQuestionRequest);
  yield takeLatest(actions.getQuestions, getQuestionsRequest);
  yield takeLatest(actions.createQuestion, createQuestionRequest);
  yield takeLatest(actions.updateQuestion, updateQuestionRequest);
  yield takeLatest(actions.deleteQuestion, deleteQuestionRequest);
  yield takeLatest(actions.resetForm, resetFrom);
}
