import React from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Option } from 'app/types';
import SelectInput from './components/SelectInput';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { customersSelectReducer, customersSelectSlice } from './redux/slice';
import { customersSelectSaga } from './redux/saga';
import { useSelector } from 'react-redux';
import { selectDirection } from 'styles/theme/slice';

interface CustomerSelectProps {
    label?: string;
    value?: Option;
    onChange?: (newValue?: Option | null) => void;
    isFieldReq?: boolean;
    mainStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    options?: Option[];
    isLoading?: boolean;
    disabled?: boolean;
    error?: string;
    defaultValue?: Option;
}

export default function CustomerSelect({
    value,
    onChange,
    isFieldReq=true,
    mainStyle,
    labelStyle,
    isLoading,
    disabled,
    error,
    defaultValue,
} : CustomerSelectProps) {
    const { t } = useTranslation();
    const direction = useSelector(selectDirection);
    useInjectReducer({ key: customersSelectSlice, reducer: customersSelectReducer });
    useInjectSaga({ key: customersSelectSlice, saga: customersSelectSaga });
    return (
        <Box
            sx={{ 
                marginBottom: "10px",
                width: "100%",
            }} 
            style={mainStyle}
        >
           <Typography
                sx={{
                    
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    ...labelStyle
                }}
                >
                {t("COMMON.LABELS.CUSTOMER_NAME")}
                {isFieldReq && <span style={{ color: '#ff0000' }}> *</span>}
            </Typography>
            <SelectInput 
                value={value}
                onChange={onChange}
                isLoading={isLoading}
                disabled={disabled}
                error={error}
                defaultValue={defaultValue}
            />
        </Box>
    )
}
