import { createSlice } from "utils/@reduxjs/toolkit";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { City, CityState, ContainerState } from "../types";
import { Pagination, Query, QueryWithCallback } from "app/containers/types";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = CityState;

const formSlice = createSlice({
  name: "cityState",
  initialState,
  reducers: {
    getCities(state, action: PayloadAction<QueryWithCallback>) {
      state.isLoading = true;
    },
    setCities(state, action: PayloadAction<{cities: City[], pagination: Pagination}>) {
      state.pagination = action.payload.pagination;
      if(action.payload.pagination.page == 1) {
        state.cities = action.payload.cities;
      }else{
        state.cities = [...state.cities,...action.payload.cities];
      }
      state.isLoading = false;
    },
    getCitiesError(state) {
        state.isLoading = false;
    }
  },
});

export const { actions, reducer: cityReducer, name: citySlice } = formSlice;
