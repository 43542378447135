import React, { useState } from "react";
const SVG =({ isSelected }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill={isSelected ? "#E24399" : "white" } />
      <path
        d="M4.68267 4.18125C4.50103 4.23751 4.28807 4.39379 4.17219 4.55007C3.9655 4.82825 3.98115 4.28752 3.98742 12.1391L3.99681 19.2811L4.07511 19.4374C4.17219 19.6374 4.3601 19.825 4.56053 19.9219L4.71712 20H11.9985H19.2798L19.4364 19.9219C19.6368 19.825 19.8247 19.6374 19.9218 19.4374L20.0001 19.2811V15.6241V11.9672L19.9249 11.8015C19.831 11.5921 19.5679 11.3483 19.3675 11.2827C19.2829 11.2576 17.9269 10.9795 16.3516 10.67C14.7763 10.3575 13.4547 10.0949 13.4171 10.0855L13.3483 10.0699L13.3389 8.04764L13.3295 6.02849L13.2261 5.81907C13.0225 5.40649 12.8252 5.30647 11.9358 5.17207C11.5819 5.11893 9.90018 4.86576 8.19964 4.60633C6.49909 4.35003 5.03969 4.13749 4.95826 4.14062C4.87684 4.14062 4.7547 4.15937 4.68267 4.18125ZM8.70698 5.35648C10.7645 5.66904 12.4714 5.93472 12.5027 5.95347C12.534 5.9691 12.581 6.01286 12.606 6.05037C12.6499 6.11288 12.6561 6.80989 12.6561 12.7392V19.3592H11.654H10.6518V17.7683C10.6518 15.8961 10.6612 15.9586 10.3543 15.6554C10.0536 15.3553 10.0693 15.3585 8.65687 15.3585C7.54823 15.3585 7.44488 15.3616 7.30082 15.4178C7.05654 15.5116 6.88116 15.671 6.76216 15.9148L6.65881 16.1242L6.64941 17.7402L6.64002 19.3592H5.79444C4.84552 19.3592 4.77975 19.3467 4.68893 19.1561C4.64509 19.0623 4.63882 18.3653 4.63882 12.0828C4.63882 6.9818 4.64822 5.08768 4.67327 5.01579C4.70772 4.92202 4.85805 4.79387 4.93634 4.79387C4.952 4.79387 6.64941 5.04705 8.70698 5.35648ZM16.2545 11.3295C17.8329 11.639 19.1577 11.914 19.1952 11.939C19.3644 12.0484 19.3581 11.9297 19.3581 15.6273C19.3581 19.4249 19.3706 19.2092 19.1545 19.3092C19.0637 19.353 18.7317 19.3592 16.1982 19.3592H13.3451V15.0615C13.3451 12.6986 13.3545 10.7638 13.3639 10.7638C13.3764 10.7638 14.6761 11.017 16.2545 11.3295ZM9.89079 16.118L9.97848 16.2024L9.98787 17.7808L9.99727 19.3592H8.66314H7.33214V17.8121C7.33214 16.7775 7.34467 16.243 7.36659 16.193C7.38538 16.1524 7.43862 16.0961 7.48246 16.068C7.55763 16.0211 7.6829 16.0148 8.68506 16.0242L9.80623 16.0305L9.89079 16.118Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M6.13687 7.40418C5.96149 7.49795 5.9427 7.77613 6.09615 7.92303L6.17758 7.99805H6.98557H7.79357L7.87499 7.92303C8.03158 7.77613 8.00653 7.49482 7.82802 7.40418C7.70275 7.33854 6.25587 7.33854 6.13687 7.40418Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M9.42904 7.44794C9.28185 7.59796 9.31317 7.85427 9.49481 7.96054C9.56997 8.00742 9.68898 8.01367 10.3247 8.01367C11.1578 8.01367 11.2173 8.00117 11.2987 7.80426C11.3582 7.66985 11.3144 7.51357 11.1954 7.4198C11.1202 7.36042 11.0701 7.35729 10.3185 7.35729H9.51986L9.42904 7.44794Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M6.14077 9.40418C5.96539 9.49795 5.9466 9.77613 6.10006 9.92303L6.18148 9.99805H6.98948H7.79747L7.8789 9.92303C8.03549 9.77613 8.01043 9.49482 7.83192 9.40418C7.70665 9.33854 6.25978 9.33854 6.14077 9.40418Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M9.42904 9.44794C9.28185 9.59796 9.31317 9.85427 9.49481 9.96054C9.56997 10.0074 9.68898 10.0137 10.3247 10.0137C11.1578 10.0137 11.2173 10.0012 11.2987 9.80426C11.3582 9.66985 11.3144 9.51357 11.1954 9.4198C11.1202 9.36042 11.0701 9.35729 10.3185 9.35729H9.51986L9.42904 9.44794Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M6.13687 11.4042C5.96149 11.4979 5.9427 11.7761 6.09615 11.923L6.17758 11.998H6.98557H7.79357L7.87499 11.923C8.03158 11.7761 8.00653 11.4948 7.82802 11.4042C7.70275 11.3385 6.25587 11.3385 6.13687 11.4042Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M9.42904 11.4479C9.28185 11.598 9.31317 11.8543 9.49481 11.9605C9.56997 12.0074 9.68898 12.0137 10.3247 12.0137C11.1578 12.0137 11.2173 12.0012 11.2987 11.8043C11.3582 11.6699 11.3144 11.5136 11.1954 11.4198C11.1202 11.3604 11.0701 11.3573 10.3185 11.3573H9.51986L9.42904 11.4479Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M6.14077 13.4042C5.96539 13.4979 5.9466 13.7761 6.10006 13.923L6.18148 13.998H6.98948H7.79747L7.8789 13.923C8.03549 13.7761 8.01043 13.4948 7.83192 13.4042C7.70665 13.3385 6.25978 13.3385 6.14077 13.4042Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M9.42904 13.4479C9.28185 13.598 9.31317 13.8543 9.49481 13.9605C9.56997 14.0074 9.68898 14.0137 10.3247 14.0137C11.1578 14.0137 11.2173 14.0012 11.2987 13.8043C11.3582 13.6699 11.3144 13.5136 11.1954 13.4198C11.1202 13.3604 11.0701 13.3573 10.3185 13.3573H9.51986L9.42904 13.4479Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M15.4408 13.4499C15.2936 13.5999 15.3249 13.8562 15.5065 13.9625C15.5817 14.0094 15.7007 14.0156 16.3364 14.0156C17.1695 14.0156 17.229 14.0031 17.3104 13.8062C17.3699 13.6718 17.3261 13.5155 17.2071 13.4218C17.1319 13.3624 17.0818 13.3592 16.3302 13.3592H15.5316L15.4408 13.4499Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M15.4408 15.4499C15.2936 15.5999 15.3249 15.8562 15.5065 15.9625C15.5817 16.0094 15.7007 16.0156 16.3364 16.0156C17.1695 16.0156 17.229 16.0031 17.3104 15.8062C17.3699 15.6718 17.3261 15.5155 17.2071 15.4218C17.1319 15.3624 17.0818 15.3592 16.3302 15.3592H15.5316L15.4408 15.4499Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M15.4408 17.4499C15.2936 17.5999 15.3249 17.8562 15.5065 17.9625C15.5817 18.0094 15.7007 18.0156 16.3364 18.0156C17.1695 18.0156 17.229 18.0031 17.3104 17.8062C17.3699 17.6718 17.3261 17.5155 17.2071 17.4218C17.1319 17.3624 17.0818 17.3592 16.3302 17.3592H15.5316L15.4408 17.4499Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
    </svg>
  );
};
export default SVG