import { Box, Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material'
import OutlineButton from 'app/components/Buttons/OutlineButton'
import PrimaryButton from 'app/components/Buttons/PrimaryButton'
import PopupDialog from 'app/components/PopupDialog'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Counter from './Counter'
import { selectGame, selectIsLoading } from 'app/containers/Games/redux/selector'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'app/containers/Games/redux/slice'
import { selectDirection } from 'styles/theme/slice'

interface SettingsProps {
    readonly open: boolean,
    readonly handleClose: () => void
    readonly disabled?: boolean
}

export default function Settings({
    open,
    handleClose,
    disabled
}:SettingsProps) {
    const { t } = useTranslation();
  const direction = useSelector(selectDirection);
    const isLoading = useSelector(selectIsLoading)
    const settings = useSelector(selectGame)
    const dispatch = useDispatch();

    const onConfirm = () => {
        dispatch(actions.saveGameSettings({
            gameId: settings?._id?.value,
            settings: {
                canSkip: settings?.canSkip?.value,
                numberOfMistakesTillReveal: settings?.numberOfMistakesTillReveal?.value,
                delayAfterMistake: settings?.delayAfterMistake?.value,
                numberOfMistakesTillDelay: settings?.numberOfMistakesTillDelay?.value,
                canSkipAfter: settings?.canSkipAfter?.value,
            },
            callback: handleClose
        }))
    }

    return (
        <PopupDialog
            open={open}
            handleClose={isLoading ? undefined : handleClose}
            containerStyle={{
                padding: "40px 30px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                }}
            >
                <Typography
                    sx={{
                        
                        fontSize: "24px",
                        fontWeight: 600,
                        textAlign: "center",
                        color: "#207DB9",
                    }}
                >
                    {t("GAMES.SETTINGS.HEADING")}
                </Typography>
                {/* Can skip questions checkbox */}
                
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            padding: "14px 16px",
                            gap: "10px",
                            borderRadius: "4px",
                            border: "1px solid #CCE1EF",
                            backgroundColor: "#F3F6F8",
                        }}
                    >
                        <FormControlLabel 
                            sx={{ 
                                margin: 0,
                                
                                fontWeight: 400,
                                lineHeight: "24px",
                                letterSpacing: "0em",
                                textAlign: "left",
                            }} 
                            label={<Typography sx={{
                                
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "14px",
                                letterSpacing: "0em",
                                marginLeft: "10px"
                            }}
                            >
                                {t("GAMES.SETTINGS.CAN_SKIP_QUESTIONS")}
                            </Typography>}
                            control={<Checkbox 
                                value={settings?.canSkip?.value}
                                checked={settings?.canSkip?.value}
                                name="canSkip"
                                onChange={(e)=>{
                                    dispatch(actions.setFormValues({ key: "canSkip", value: e.target.checked }))
                                }}
                                disabled={isLoading || disabled}
                                size="small" 
                                sx={{
                                    color: "#207DB9",
                                    '&.Mui-checked': {
                                        color: "#207DB9",
                                        margin: 0,
                                        padding: 0,
                                        borderWidth: "1px",
                                    },
                                    width: "20px",
                                    height: "20px",
                                }}
                            />}
                        />
                    </Box>
                </Box>
                {/* Reveal After */}
                <Tooltip title={!(settings?.numberOfMistakesTillReveal?.value > 0) ? t("GAMES.SETTINGS.REVEAL_NOT_APPLICABLE") : ''}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            padding: "14px 16px",
                            gap: "10px",
                            borderRadius: "4px",
                            border: "1px solid #CCE1EF",
                            backgroundColor: "#F3F6F8",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "14px",
                                letterSpacing: "0em",
                            }}
                        >
                            {t("GAMES.SETTINGS.REVEAL_ANSWER_AFTER")}
                        </Typography>
                        <Counter 
                            onChange={(value)=>{
                                dispatch(actions.setFormValues({ key: "numberOfMistakesTillReveal", value }))
                            }}
                            value={settings?.numberOfMistakesTillReveal?.value} 
                            min={0}
                            hideOptions={[0]}
                            disabled={isLoading || disabled} />
                        <Typography
                            sx={{
                                
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "14px",
                                letterSpacing: "0em",
                            }}
                        >
                            {t("GAMES.SETTINGS.MISTAKES")}
                        </Typography>
                    </Box>
                </Box>
                </Tooltip>

                {/* Delay After */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            padding: "14px 16px",
                            gap: "10px",
                            borderRadius: "4px",
                            border: "1px solid #CCE1EF",
                            backgroundColor: "#F3F6F8",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "14px",
                                letterSpacing: "0em",
                            }}
                        >
                            {t("GAMES.SETTINGS.DELAY")}
                        </Typography>
                        <Counter 
                            maxCount={600}
                            disabled={isLoading || disabled} 
                            value={settings?.delayAfterMistake?.value}
                            onChange={(value)=>{
                                dispatch(actions.setFormValues({ key: "delayAfterMistake", value }))
                            }}
                        />
                        <Typography
                            sx={{
                                
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "14px",
                                letterSpacing: "0em",
                            }}
                        >
                            {t("GAMES.SETTINGS.SECONDS_AFTER")}
                        </Typography>
                        <Counter 
                            disabled={isLoading || disabled} 
                            value={settings?.numberOfMistakesTillDelay?.value}
                            onChange={(value)=>{
                                dispatch(actions.setFormValues({ key: "numberOfMistakesTillDelay", value }))
                            }}
                        />
                        <Typography
                            sx={{
                                
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "14px",
                                letterSpacing: "0em",
                            }}
                        >
                            {t("GAMES.SETTINGS.MISTAKES")}
                        </Typography>
                    </Box>
                </Box>

                {/* Skip After after*/}
                <Tooltip title={!(settings?.canSkipAfter?.value > 0) ? t("GAMES.SETTINGS.SKIP_NOT_APPLICABLE") : ''}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            padding: "14px 16px",
                            gap: "10px",
                            borderRadius: "4px",
                            border: "1px solid #CCE1EF",
                            backgroundColor: "#F3F6F8",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "14px",
                                letterSpacing: "0em",
                            }}
                        >
                            {t("GAMES.SETTINGS.CAN_SKIP_QUESTION_AFTER")}
                        </Typography>
                        <Counter 
                            disabled={isLoading || disabled} 
                            value={settings?.canSkipAfter?.value}
                            onChange={(value)=>{
                                dispatch(actions.setFormValues({ key: "canSkipAfter", value }))
                            }}
                            min={0}
                            hideOptions={[0]}
                        />
                        <Typography
                            sx={{
                                
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "14px",
                                letterSpacing: "0em",
                            }}
                        >
                            {t("GAMES.SETTINGS.MISTAKES")}
                        </Typography>
                    </Box>
                </Box>
                </Tooltip>
                {/* actions */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        mt: "10px",
                        width: "100%",
                    }}
                >
                    <OutlineButton 
                        label={t("COMMON.BUTTONS.CANCEL")}
                        sx={{
                            textTransform: "none",
                        }}
                        onClick={handleClose}
                        disabled={isLoading || disabled}
                    />
                    <PrimaryButton 
                        label={t("COMMON.BUTTONS.SAVE")}
                        sx={{
                            textTransform: "none",
                        }}
                        disabled={disabled}
                        onClick={onConfirm}
                        isLoading={isLoading}
                    />
                </Box>
            </Box>
        </PopupDialog>
    )
}
