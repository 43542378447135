import { Box, Typography } from "@mui/material";
import MediaUploader from "app/components/MediaUploader";
import PopupDialog from "app/components/PopupDialog";
import React, { useRef, useState } from "react";
import ActionButtons from "./components/ActionButtons";
import QuestionBody from "./components/QuestionBody";
import Answers from "./components/Answers";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomizeTicketQuestion } from "app/containers/Tickets/redux/selector";
import { ASSET_TYPE } from "utils/constants";
import { actions } from "app/containers/Tickets/redux/slice";
import { MediaTypes } from "app/containers/types";
import { useTranslation } from "react-i18next";

export default function QuestionForm({
  open,
  handleClose,
  onConfirm,
}: {
  readonly open: boolean;
  readonly handleClose: () => void;
  readonly onConfirm?: () => void;
}) {
  const question = useSelector(selectCustomizeTicketQuestion);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef<any>(null);
  const [focus, setFocus] = useState(false);

  return (
    <PopupDialog open={open} handleClose={handleClose}>
      <Box
        sx={{
          width: "100%",
          minWidth: "360px",
          maxWidth: "460px",
          padding: "32px 18px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "28px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#207DB9",
            textTransform: "uppercase",
            marginBottom: "16px",
          }}
        >
          {t("TICKETS.CUSTOMIZED_TICKETS.ADD_QUESTION")}
        </Typography>
        <MediaUploader
          label={t("TICKETS.CUSTOMIZED_TICKETS.QUESTION_IMAGE")}
          innerContainerStyle={{
            border: "1px dashed #D7D7E0",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
          containerStyle={{
            width: "100%",
          }}
          dashedBorderStyle={{
            border: "none",
            height: "100%",
            width: "100%",
          }}
          labelStyle={{
            lineHeight: "100%",
            marginBottom: "8px",
            fontWeight: 600,
          }}
          // caption={t("TICKETS.CUSTOMIZED_TICKETS.SUPPORTED_FORMATS")}
          tooltip={t("TICKETS.CUSTOMIZED_TICKETS.TOOLTIP_QUESTION_IMAGE")}
          height="200px"
          width="320px"
          fallbackWidth="320px"
          fallbackHeight="200px"
          src={question.asset?.url}
          onSuccess={(img) => {
            setFocus(true);
            dispatch(
              actions.setCustomizeQuestionValues({
                key: "asset",
                value: {
                  _id: img?._id,
                  type: ASSET_TYPE.VIDEO,
                  mimeType: img?.type,
                  fileName: img?.fileName,
                  filePath: img?.filePath,
                  url: img?.url,
                },
              })
            );
          }}
          type={MediaTypes.IMAGE}
        />
        <QuestionBody focus={focus} />
        <Answers />
        <ActionButtons handleClose={handleClose} onConfirm={onConfirm} />
      </Box>
    </PopupDialog>
  );
}
