import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsEnableOrganizations,
  selectIsLoadingOrganizations,
  selectOrganization,
  selectOrganizations,
  selectUser,
  selectOrganizationPagination,
} from "app/redux/selectors";
import { actions } from "app/redux/slice";
import { Option, RoleTypes } from "app/types";
import { selectDirection } from "styles/theme/slice";
import { useDebounce } from "utils/hooks/useDebounce";


export default function OrganizationSelect() {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(""); // Controlled input for search
  const organizations = useSelector(selectOrganizations);
  const isLoading = useSelector(selectIsLoadingOrganizations);
  const organization = useSelector(selectOrganization);
  const debouncedInputValue = useDebounce(inputValue, 500)
  const enable = useSelector(selectIsEnableOrganizations);
  const user = useSelector(selectUser);
  const direction = useSelector(selectDirection);
  const pagination = useSelector(selectOrganizationPagination); // Pagination support
  const dispatch = useDispatch();
  const { t } = useTranslation();



  // Search when user types
  useEffect(() => {
    dispatch(actions.getOrganizations({ page: 1, search: debouncedInputValue }));
  }, [debouncedInputValue, dispatch]);


   // show first page when open
   useEffect(() => {
    if(open){
      dispatch(actions.getOrganizations({ page: 1 }));
    }
  }, [open]);

  useEffect(() => {
    if (user &&  organizations.length > 0) {
      if(user.type === RoleTypes.EDITOR) {
        const org = user.organization
        if (org) {
          dispatch(
            actions.setOrganization({
              label: org.name,
              value: org._id as string,
            })
          );
        }
      }
      if(user.type === RoleTypes.ADMIN) {
        const defaultOrganization = localStorage.getItem("organization");
        if( !defaultOrganization) {
          const org = organizations[0]
          if (org) {
            dispatch(
              actions.setOrganization({
                label: org.name,
                value: org._id as string,
              })
            );
          }
        }
      }
      
    }
  }, [user, organizations, dispatch]);

  const handleScroll = (event: React.SyntheticEvent) => {
    const hasMore = pagination.page < pagination.totalPage; // Assuming pagination gives total pages
    if (!hasMore || isLoading) return;
    const listboxNode = event.currentTarget;
    const bottom = Math.ceil(listboxNode.scrollTop + listboxNode.clientHeight) >= listboxNode.scrollHeight 
    if (bottom) {
      // Load next page when scroll reaches the bottom
      dispatch(
        actions.getOrganizations({
          page: pagination.page + 1,
        })
      );
    }
  };

  const disabled = () => {
    if (user?.type === RoleTypes.ADMIN) {
      return enable;
    }
    return true;
  };

  const handleInputChange = (event: any, newInputValue: string) => {
    setInputValue(newInputValue); // Update input value for search purposes
  };

  const handleChange = (event: any, newValue: any  | null) => {
    if (newValue) {
      dispatch(actions.setOrganization(newValue as Option)); // Set organization only when selected
    }
  };

  return (
    <Autocomplete
      sx={{
        width: "223px",
        
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={organization} // Controlled value
      inputValue={inputValue} // Controlled input value for search
      onInputChange={handleInputChange} // Handle input change for search
      onChange={handleChange} // Handle selection of an organization
      getOptionLabel={(option) => option?.label || ""}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      options={
        organizations?.map((organization) => ({
          label: organization.name,
          value: organization._id,
        })) || []
      }
      loading={isLoading}
      ListboxProps={{
        onScroll: handleScroll, // Add scroll event for loading more pages
      }}
      disabled={disabled()}
      loadingText={
        <Typography
          sx={{
            
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "14px",
            textAlign: "left",
          }}
        >
          {t("COMMON.TEXT.LOADING")}
        </Typography>
      }
      noOptionsText={
        <Typography
          sx={{
            
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "14px",
            textAlign: "left",
          }}
        >
          {t("COMMON.TEXT.NO_OPTIONS")}
        </Typography>
      }
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "16px",
              color: "#5E6781",
              py: "4px",
              
            }}
          >
            {option.label}
          </Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            
            fontSize: "14px !important",
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
              margin: 0,
              background: "#FFFFFF",
              height: "36px",
              "& fieldset": {
                border: "none",
                fontSize: "14px !important",
                
              },
              padding: "0px 10px",
            },
            "& .MuiSelect-select": {
              fontSize: "14px !important",
              
            },
            input: {
              "&::placeholder": {
                
                fontSize: "14px !important",
              },
              fontSize: "14px !important",
              padding: "0px",
              
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={16} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
