import { Box, IconButton, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";
import SearchInput from "app/components/SearchInput";
import CitySelect from "app/components/CitySelect";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import { cityAllOption } from "app/components/CitySelect/helper";

export default function Header({ disabled, resetFilters }: { disabled?: boolean; resetFilters(): void; }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const searchParams  = useSearchParams();
  const cityParam = searchParams?.[0]?.get("city");
  
  const setCitySearchQuery = (city: string) => {
  
    const params = new URLSearchParams(window.location.search);
    if(city.toLowerCase() == "all"){
      params.set("city", "");
    }else{
      params.set("city", city);
    }

    navigate(`?${params.toString()}`);
  };

  const handleResetFilters = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("q");
    params.delete("city");
    navigate(`?${params.toString()}`);
    resetFilters()
    
  }

  return (
    <>
      <Grid2 xs={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <SearchInput
            disabled={disabled}
            provisionLabel
          />
        </Box>
      </Grid2>
      <Grid2 xs={4}>
        <CitySelect
          label={t("COMMON.LABELS.CITY")}
          onChange={(value) => 
            setCitySearchQuery(value?.value || "")
          }
          backgroundColor="#F3F6F8"
          borderWidth={0}
          isFieldReq={false}
          showAll={true}
          value={cityParam ? {value: cityParam, label: ''} : undefined}
        />
      </Grid2>
      <Grid2 xs={1} display={'flex'}  justifyContent={'center'} alignItems={'center'}>
      <IconButton
          onClick={handleResetFilters}
        >
          <RefreshIcon />
        </IconButton>
      </Grid2>
    </>
  );
}
