import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { QUESTION_TYPES } from "utils/constants";
import { Question } from "./types";
import MarkdownPreview from "@uiw/react-markdown-preview";
type Props = {
  question: Question;
};

// The Chip component for displaying individual answers
const Chip: React.FC<{ isFullChip: boolean; text: string; img?: string; isCorrect?: boolean }> = ({
  img,
  text,
  isCorrect,
  isFullChip = false
}) => {
  const chipStyle: React.CSSProperties = isFullChip ?  {
    backgroundColor: isCorrect ? "green" : "gray",
    color: "white",
    padding: "1px 2px",
    margin: "4px 4px 4px 0px",
    borderRadius: "4px",
    display: "inline-block",
  } : {
    backgroundColor: isCorrect ? "green" : "gray",
    color: "white",
    padding: "1px 2px",
    margin: "4px 4px 4px 0px",
    borderRadius: "4px",
    display: "inline-block",
    maxWidth: 200,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  };
  if (!text && !img) {
    return null;
  }

  return (
    <span style={chipStyle}>
      {text}{" "}
      {img && <img src={img} style={{ height: "15px", width: "15px" }} />}
    </span>
  );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F4F8FF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 520,
    fontSize: theme.typography.pxToRem(12),
    padding: 20,
    border: "1px solid #ccc",
    borderRadius: "12px",
    maxHeight: 300,
    overflowY: "auto",
  },
}));

// Main component to display the question and answers
const QuestionBodyWithAnswer = ({ question }: Props) => {
  const { type, answers, body } = question;

  const renderChips = ({isFullChip}: {isFullChip: boolean}) => {
    switch (type) {
      case QUESTION_TYPES.SINGLE_SELECT:
      case QUESTION_TYPES.MULTI_SELECT:
        return answers.map((answer) => {
          if (!answer.isCorrect) {
            return null;
          }
          return (
            <Chip
              key={answer._id}
              text={answer.title}
              img={answer?.asset?.thumbUrl}
              isCorrect={answer.isCorrect}
              isFullChip={isFullChip}
            />
          );
        });
      case QUESTION_TYPES.FREE_TEXT:
        return answers.map((answer, index) => {
          if (!answer.letters) {
            return null;
          }
          const word = answer.letters
            .map((m) => {
              return m.option1;
            })
            .join("");
          if (!word) return null;
          return (
            <Chip
              key={answer._id}
              text={`Word ${index + 1}: ${word}`}
              isCorrect={true}
              isFullChip={isFullChip}
            />
          );
        });
      case QUESTION_TYPES.CORRELATION:
        return answers.map((answer, index) => {
          if (!answer.left && !answer.right) {
            return null;
          }
          return (
            <Chip
              key={answer._id}
              text={`${answer.left}->${answer.right}`}
              isCorrect={true}
              isFullChip={isFullChip}
            />
          );
        });
      case QUESTION_TYPES.MIXED_LETTER:
        return answers.map((answer, index) => {
          if (!answer.letters) {
            return null;
          }
          const word = answer.word;
          if (!word) return null;
          return (
            <Chip
              key={answer._id}
              text={`Word ${index + 1}: ${word}`}
              isCorrect={true}
              isFullChip={isFullChip}
            />
          );
        });
      default:
        return null;
    }
  };

  return (
    <HtmlTooltip
    arrow
      title={
        <Box
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography component={"span"}>
            <MarkdownPreview
              source={body}
              style={{background: "#F4F8FF",}}
              wrapperElement={{
                "data-color-mode": "light",
              }}
            />
          </Typography>
          <Box
            style={{ display: "flex", marginTop: "5px", flexDirection: "row" ,  flexWrap: "wrap" }}
          >
            {renderChips({isFullChip: true})}
          </Box>
        </Box>
      }
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography component={"span"} noWrap>
          {getQuestionBodyText(body)}
        </Typography>
        <span style={{ marginTop: "-5px", overflow: "hidden" }}>
          {renderChips({isFullChip: false})}
        </span>
      </div>
    </HtmlTooltip>
  );
};

const getQuestionBodyText = (value: string) => {
  const val = value
    .replace(/<u>|<\/u>/g, "") // Remove <u> and </u> tags
    .replace(/[#*`~>\[\]]+/g, "") // Remove Markdown characters
    .replace(/!\[.*\]\(.*\)/g, "") // Remove images
    .replace(/\[(.*?)\]\(.*?\)/g, "$1") // Keep link text, remove URLs
    .replace(/\n+/g, "\n"); // Normalize new lines

  return val.length > 55 ? val.substring(0, 55) + "..." : val;
};

export default QuestionBodyWithAnswer;
