import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    loginButtonWrapper: {
        width: "100%",
    },
      heading: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "19px",
        textAlign: "center",
        color: "#000",
      },
      subHeading: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        color: "#FF923D",
        textAlign: "center",
        maxWidth: 188,
      },
      resetMsgText: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "16px",
        textAlign: "center",
        color: "#999999",
        marginTop: 4,
      },
      form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px"
      },
      button: {
        width: "100%",
        height: 40,
        fontSize: 22,
        marginTop: 11,
      },
    
      successHeading: {
        fontSize: 16,
        fontWeight: 500,
        textAlign: "center",
        color: "#000",
      },
      iconMail: {
        fontSize: 53,
        color: "#FF923D",
        margin: "30px 0px 30px 0px",
      },
      input: {
        marginTop: 24,
      },
      mailSent: {
        color: "#3C4858",
        fontSize: 14,
        fontWeight: 600,
        marginBottom: 26,
        textAlign: "center",
        maxWidth: 210,
        lineHeight: "16px",
      },
      descText: {
        fontSize: 14,
        fontWeight: 400,
        textAlign: "center",
        color: "#999",
        maxWidth: 289,
      },
      loginText: {
        
        fontSize: "48px",
        fontWeight: 400,
        lineHeight: "58px",
        letterSpacing: "0em",
        textAlign: "center",
        color: "#207DB9",
        marginBottom: "16px"
      },
      error: {
        width: "100%",
        textAlign: "center",
        justifyContent: "center",
        marginBottom: 10,
      },
    }));