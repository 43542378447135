import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import OutlineButton from "app/components/Buttons/OutlineButton";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import PopupDialog from "app/components/PopupDialog";
import React from "react";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  selectDefaultTicket,
  selectGame,
  selectIsLoading,
} from "app/containers/Games/redux/selector";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/containers/Games/redux/slice";
import { selectDirection } from "styles/theme/slice";
import InputField from "app/components/InputField";
import { ContentCopy } from "@mui/icons-material";

export default function TestGameModal() {
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  const testTicket = useSelector(selectDefaultTicket);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(actions.closeTestGameTicket());
  };
  const { isOpen } = testTicket;

  const handleCopy = (id) => {
    const textField: any = document.getElementById(id); // Assuming the TextField has this ID
    textField.select(); // Select the text field content
    try {
      navigator.clipboard.writeText(textField.value); // Copy to clipboard
      console.log("Text copied successfully!"); // Optional success message (can be replaced with visual feedback)
    } catch (err) {
      console.error("Failed to copy text:", err); // Handle errors
    } finally {
      textField.selectionStart = 0; // Deselect the text field
      textField.selectionEnd = 0;
    }
  };
  return (
    <PopupDialog
      open={isOpen}
      handleClose={isLoading ? undefined : handleClose}
      containerStyle={{
        padding: "40px 30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          minWidth: 300
        }}
      >
        <Typography
          sx={{
            
            fontSize: "24px",
            fontWeight: 600,
            textAlign: "center",
            color: "#207DB9",
          }}
        >
          {t("GAMES.FORM.LABELS.TEST_GAME")}
        </Typography>

        <Typography
          sx={{
            
            fontSize: "14px",
            color: "#207DB9",
          }}
        >
        <CheckCircleIcon sx={{ width: '20px', height: '20px', color: "#207DB9"}} />  {t("GAMES.FORM.LABELS.TEST_GAME_INFO")}
        </Typography>

        <Box>
          <InputField
            label={t("TICKETS.FORM.TICKET_PASSWORD")}
            name="password"
            fullWidth={true}
            isDisable={true}
            value={testTicket.testPassword}
            lightPlaceholder
       
            InputProps={{
              id: "copy-password",
              endAdornment: (
                <InputAdornment
                  sx={{
                    background: "transparent",
                    padding: 0, // Remove default padding (optional)
                    margin: 0, // Remove default margin (optional)
                    border: "none", // Remove default border (optional)
                    cursor: 'text'
                  }}
                  position="start"
                >
                  <IconButton
                    size="small"
                    onClick={() => handleCopy("copy-password")}
                  >
                    <ContentCopy />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography
          sx={{
            
            fontSize: "12px",
            color: "#207DB9",
          }}
        >
          {t("GAMES.FORM.LABELS.TEST_GAME_INFO_SUB")}
        </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            mt: "10px",
            width: "100%",
          }}
        >
          <OutlineButton
            label={t("COMMON.BUTTONS.CLOSE")}
            sx={{
              textTransform: "none",
            }}
            onClick={handleClose}
            disabled={isLoading}
          />
        </Box>
      </Box>
    </PopupDialog>
  );
}
