import { Box, Typography } from "@mui/material";
import InputField from "app/components/InputField";
import { selectStation } from "app/containers/Stations/redux/selector";
import { actions } from "app/containers/Stations/redux/slice";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TabPanel from "../TabPanel";
import { debounce } from "lodash";

interface QRCodeProps {
  disabled: boolean;
}

export default function QrCode({ disabled }: QRCodeProps) {
  const { t } = useTranslation();
  const station = useSelector(selectStation);
  const dispatch = useDispatch();
  const debouncedDispatch = debounce(
    (value) => dispatch(actions.generateBarcode(value)),
    300
  );

  useEffect(() => {
    if (station.qrText.value) {
      debouncedDispatch(station.qrText.value);
    } else {
      dispatch(actions.setFormValues({ key: "qrCode", value: "" }));
    }
    return () => debouncedDispatch.cancel();
  }, [station.qrText.value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(actions.setFormValues({ key: 'code', value }));
    dispatch(actions.setFormValues({ key: 'qrText', value }));
  };

  return (
    <TabPanel value={station?.type?.value} index={0}>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "24px",
          color: "#5E6781",
        }}
      >
        {t("STATIONS.FORM.LABELS.QR_CODE")}
      </Typography>
      <Box
        sx={{
          width: "240px",
          height: "240px",
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
          border: "1px solid #fefefe",
        }}
      >
        {station?.qrCode?.value && (
          <img
            alt="qrCode"
            style={{
              objectFit: "cover",
              borderRadius: "4px",
            }}
            src={station?.qrCode?.value}
            width="100%"
            height="100%"
          />
        )}
      </Box>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "20px",
          lineHeight: "24px",
          color: "#5E6781",
          mt: "20px",
        }}
      >
        {t("STATIONS.FORM.LABELS.CREATE_QR")}
      </Typography>
      <InputField
        label={""}
        placeholder={t("STATIONS.FORM.PLACEHOLDERS.PASTE_URL")}
        name="qrText"
        fullWidth={true}
        value={station.qrText.value}
        error={station.qrText.error ? t(station.qrText.error) : ""}
        isDisable={disabled}
        lightPlaceholder
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
          },
          marginBottom: "20px",
        }}
        onChange={onChange}
      />
    </TabPanel>
  );
}
