import { Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'
import Thumbnail from './components/Thumbnail'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectGroupPhotos, selectList } from 'app/containers/Results/redux/selector'

export default function CreativeAssignmentsPhotos() {
    const { t } = useTranslation();
    const list = useSelector(selectGroupPhotos);
    if(!list.length) {return null}

    return (
        <Grid2
            container
            spacing={2}
            sx={{
                padding: "16px",
                borderRadius: "12px",
                background: "#FFFFFF",
                marginTop: "16px",
                marginBottom: "40px",
                marginX: "12px",
            }}
        >
            <Grid2
                md={12}
            >
                <Typography
                    sx={{
                        
                        fontSize: "32px",
                        fontWeight: 600,
                        lineHeight: "32px",
                        letterSpacing: "0em",
                        color: "#207DB9"
                    }}
                >
                    {t("RESULTS.PHOTOS_BY_GROUPS")}
                </Typography>
            </Grid2>
            {
                list.map((item, index) => (
                    <Grid2
                        md={3}
                        key={index}
                    >
                        <Thumbnail url={item?.groupPhoto?.url} name={item.groupName} key={index} />
                    </Grid2>
                ))
            }
        </Grid2>
    )
}
