import ConfirmDialog from "app/components/ConfirmDialog";
import InputField from "app/components/InputField";
import React from "react";

export default function ConfirmDelete({
  open,
  handleClose,
  title,
  handleConfirm,
}: {
  open: boolean;
  title: string;
  handleClose: () => void;
  handleConfirm: () => void;
}) {
  return (
    <ConfirmDialog
      containerStyle={{
        minWidth: "360px",
        maxWidth: "460px",
        width: "100%",
      }}
      titleStyle={{
        
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "28.44px",
        letterSpacing: "0em",
        color: "#207DB9",
        textTransform: "uppercase",
      }}
      messageStyle={{
        
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "17px",
        letterSpacing: "0em",
      }}
      open={open}
      title="delete question"
      message="Are you sure that you want to delete question?"
      handleClose={handleClose}
      onConfirm={handleConfirm}
      cancelBtnLabel="cancel"
      confirmBtnLabel="delete"
      innerContainerStyle={{
        paddingX: "20px",
      }}
      cancelButtonStyle={{
        
        fontSize: "20px",
        fontWeight: 600,
        width: "100%",
        lineHeight: "32px",
        letterSpacing: "0em",
        color: "#26A8E0",
        textTransform: "uppercase",
        height: "60px",
        border: "1px solid #26A8E0",
        ":hover": {
          border: "1px solid #26A8E0",
        },
      }}
      confirmButtonStyle={{
        
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "32px",
        width: "100%",
        letterSpacing: "0em",
        color: "#FFFFFF",
        textTransform: "uppercase",
        height: "60px",
        backgroundColor: "#26A8E0",
        ":hover": {
          backgroundColor: "#26A8E0",
        },
      }}
    >
      <InputField
        label="Question body"
        value={title}
        sx={{
          width: "100%",
        }}
        borderRadius="8px"
        borderColor="#D7D7E0"
        labelStyle={{
          
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "24px",
          letterSpacing: "0em",
          color: "#8890A6",
        }}
      />
    </ConfirmDialog>
  );
}
