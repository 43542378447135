import React, { useState } from "react";
const SVG =({ isSelected }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill={isSelected ? "#E24399" : "white" } />
      <path
        d="M11.2811 4.01381C9.77181 4.14845 8.30635 4.73401 7.05382 5.69846C6.44322 6.16816 5.65099 7.05119 5.19382 7.7714C4.99654 8.0814 4.61139 8.87049 4.47361 9.24625C4.32018 9.66585 4.15422 10.3109 4.0728 10.8088C3.97573 11.4194 3.97573 12.578 4.0728 13.1886C4.21371 14.0528 4.43604 14.748 4.81493 15.5214C5.22826 16.3637 5.65725 16.9618 6.34614 17.6539C7.03817 18.3427 7.63625 18.7717 8.47858 19.1851C9.25201 19.564 9.94717 19.7863 10.8114 19.9272C11.422 20.0243 12.5806 20.0243 13.1912 19.9272C13.6891 19.8458 14.3342 19.6798 14.7537 19.5264C15.1295 19.3886 15.9186 19.0035 16.2286 18.8062C17.2338 18.1674 18.17 17.2311 18.8088 16.226C19.0061 15.916 19.3912 15.1269 19.529 14.7511C19.7889 14.0372 19.9674 13.1604 20.005 12.3744C20.03 11.8922 20.005 11.7764 19.8515 11.601C19.5322 11.2378 18.9403 11.3975 18.8307 11.8734C18.8119 11.9517 18.7963 12.1208 18.7963 12.2555C18.7963 12.6124 18.7086 13.2136 18.5928 13.6771C17.9947 16.0663 16.0689 17.992 13.6797 18.5901C12.5869 18.8657 11.397 18.8625 10.3104 18.587C7.62999 17.9012 5.61028 15.6216 5.25644 12.8817C5.19382 12.387 5.19382 11.6104 5.25644 11.1157C5.60715 8.37887 7.63312 6.09614 10.3104 5.41038C10.7801 5.29139 11.3813 5.20371 11.7445 5.20371C12.1923 5.20058 12.4209 5.09725 12.5368 4.84674C12.6839 4.52421 12.5587 4.19229 12.2362 4.04199C12.0984 3.97936 11.7853 3.96997 11.2811 4.01381Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M18.9976 6.0141C18.9475 6.02663 18.8692 6.05481 18.8254 6.07986C18.7816 6.10178 17.1063 7.75825 15.0991 9.7623L11.4511 13.4009L10.5431 12.4145C8.86153 10.5827 8.77385 10.4919 8.62355 10.4261C8.3386 10.2978 8.00042 10.4167 7.8689 10.6892C7.78436 10.8645 7.78436 11.0868 7.86264 11.2403C7.91587 11.3436 8.81769 12.3362 10.5211 14.1712C11.091 14.7849 11.21 14.8789 11.4355 14.8789C11.6985 14.8789 11.6484 14.9259 15.7317 10.8489C19.9527 6.63411 19.7617 6.84077 19.7272 6.5339C19.6865 6.18946 19.3233 5.93269 18.9976 6.0141Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
    </svg>
  );
};
export default SVG