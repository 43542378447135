import {
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import { MODES } from "utils/constants";
import { useTranslation } from "react-i18next";
import OptionsSelect from "../OptionsSelect";
import QrCode from "../QR";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Code from "../Code";
import Location from "../Location";


type Props = { mode: MODES };

const StationTypeForm = ({ mode }: Props) => {
  const { t } = useTranslation();
  return (
    <Grid2 xs={6}>
      <Box
        sx={{
          background: "#F3F6F8",
          padding: "20px 20px",
          borderRadius: "8px",
          height: "510px",
        }}
      >
        <Typography
          sx={{
            mt: "10px",
            mb: "10px",
            ml: "0px",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "32px",
            lineHeight: "100%",
            color: "#207DB9",
          }}
        >
          {t("STATIONS.FORM.STATION_TYPE")}
        </Typography>
   
          <div>
            <OptionsSelect disabled={mode === MODES.VIEW} />
            <QrCode disabled={mode === MODES.VIEW} />
            <Code disabled={mode === MODES.VIEW} />
            <Location disabled={mode === MODES.VIEW} />
          </div>
      </Box>
    </Grid2>
  );
};

export default StationTypeForm;
