import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const Styles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  container: {
    minHeight: "150px",
    borderRadius: "7px 7px 0px 0px",
    border: "none",
    overflow: "auto",
    
    "&::-webkit-scrollbar": {
      width: 13,
      height: 8,
      padding: "5px 0px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#3C4858",
      boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.15)",
      borderRadius: "10px",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#222",
      borderRadius: 20,
      border: "3px solid #3C4858",
      transform: "matrix(-1, 0, 0, 1, 0, 0)",
      boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.15)",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#222",
    },
  },
  drawerListUnselectedItems: {
    // margin: "0px 0px 0px 10px"
  },
  drawerListItems: {
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    width: "220px",
    height: "44px",
    // borderRight: "4px solid #0016BB",
    // lineHeight: "140%",
  },
  drawerListItemUnselectedText: {
    fontStyle: "normal",
    color: "#FFFFFF",
    fontWeight: "400 !important",
    
  },
  drawerListItemText: {
    color: "#E24399",
    fontWeight: "400",
    
    fontSize: "16px",
  },
  logoImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "34px",
  },

  imgLogout: {
    height: "23px",
    width: "20px",
  },

  logoutModal: {
    position: "absolute",
    background: "#F4F8FF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "460px",
    height: "200px",
    zIndex: "9999",
    borderRadius: 0,
  },
  CardActions: {
    justifyContent: "center",
    marginTop: "10px",
    marginRight: "28px",
  },
  cardContent: {
    marginLeft: "17px",
    marginTop: "12px",
  },

  bodyTypography: {
    fontWeight: "400",
    fontSize: "18px",
    
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "#1D1F20",
    marginTop: "14px",
    textAlign: "center",
  },
  headTypography: {
    color: "#17171B",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    textAlign: "center",
    
    fontStyle: "normal"
  },
  btnNo: {
    color: "#88929C",
    paddingRight: "38px",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.0125rem",
  },
  btnLogout: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.0125rem",
  },
  saveBtn: {
    background: "#17171B",
    color: "#fff",
    "&:hover": {
      background: "#C66078",
    },

    
    fontWeight: 600,
    fontSize: "16px",
    width: "160px",
    height: "50px",
  },
  cancelBtn: {
    color: "#17171B",
    borderColor: "#17171B",
    
    fontWeight: 600,
    fontSize: "16px",
    width: "160px",
    height: "50px",
    marginRight: "30px",
  },
  search: {
    "& .MuiOutlinedInput-root": {
      margin: 0,
      width: "670px",
      height: "40px",
      background: "#F2F2F2",
      borderRadius: "54px"
    },
  },
}));
