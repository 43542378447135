import React from "react";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import SearchInput from "app/components/SearchInput";
import { useTranslation } from "react-i18next";
import CitySelect from "app/components/CitySelect";
import EditorsSelect from "app/components/EditorSelect";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LEFT_SPACING } from "utils/constants";
import { cityAllOption } from "app/components/CitySelect/helper";
import { allEditorOption } from "app/components/EditorSelect/helper";

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const searchParams  = useSearchParams();
  const cityParam = searchParams?.[0]?.get("city");
  const editorParam = searchParams?.[0]?.get("user");
  
  const setCitySearchQuery = (search: string) => {
    const params = new URLSearchParams(window.location.search);
    if(search.toLowerCase() == "all"){
      params.set("city", "");
    }else{
      params.set("city", search);
    }
    navigate(`?${params.toString()}`);
  };
  const setEditorSearchQuery = (search: string) => {
    const params = new URLSearchParams(window.location.search);
    if (search.toLowerCase() == "all") {
      params.set("user", "");
    } else {
      params.set("user", search);
    }
    navigate(`?${params.toString()}`);
  };
  return (
    <Grid2 container spacing={2} md={12}  sx={{marginTop: '-32px'}} paddingLeft={LEFT_SPACING}>
      <Grid2 xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
            paddingBottom: "10px",
          }}
        >
          <SearchInput />
        </Box>
      </Grid2>
      <Grid2 xs={6} md={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <CitySelect 
            label={t("COMMON.LABELS.CITY")}
            isFieldReq={false}
            backgroundColor="#F3F6F8"
            borderWidth={0}
            onChange={(e) => setCitySearchQuery(e?.value||"")}
            showAll={true}
            value={cityParam ? {value: cityParam, label: ''} : undefined}
          />
        </Box>
      </Grid2>
      <Grid2 xs={6} md={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <EditorsSelect 
            label={t("COMMON.LABELS.EDITOR_NAME")}
            isFieldReq={false}
            backgroundColor="#F3F6F8"
            borderWidth={0}
            onChange={(e) => setEditorSearchQuery(e?.value||"")}
            value={editorParam ? {value: editorParam, label: ''} : undefined}
          />
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default Header;
