
import { refreshToken } from "./refresh-token";

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

export interface Res<T> {
  data: T | null;
  status: number;
  err?: ResponseError;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return {data: response, status: response.status};
  }
  const error = new ResponseError(response);
  const res = await response.text();
  error.message = res;
  error.response = response;
  return {err: error, status: response.status, data: null};
}

const defaultOptions = () => {
  const sessionToken = localStorage.getItem('accessToken');
  const language = localStorage.getItem('i18nextLng');
  return {
    headers: {
      'Content-Type': 'application/json',
      'X-Custom-Lang': language || 'en',
      ...(sessionToken ? { Authorization: `Bearer ${sessionToken}` } : {}),
    },
  };
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
  url: string,
  options?: RequestInit,
): Promise<Res<{}>> {
  const payload = { ...defaultOptions(), ...options };
  
  try {
    let res = await fetch(url, payload);
    let response = await checkStatus(res);
    if (response.status === 401) {
      // Attempt to refresh the token
      await refreshToken();
      // Retry the original request with the same payload
      const payload = { ...defaultOptions(), ...options };
      res = await fetch(url, payload);
      response = await checkStatus(res);
    }
    
    if (response.err) {
      return response;
    }

    const data = await parseJSON(response.data);
    return { data, status: response.status };
    
  } catch (error) {
    return { data: null, err: error as ResponseError, status: 500 }; // Handle unexpected errors
  }
}
