import { createTheme, Direction, Theme } from '@mui/material';
import { colors } from '@mui/material';

const getMuiTheme = (direction: Direction = 'ltr'): Theme => {
  return createTheme({
    direction,
    typography: {
      fontFamily: 'Rubik', 
    },
    palette: {
      background: {
        default: colors.common.white,
        paper: colors.common.white,
      },
      primary: {
        main: '#17171B',
      },
      secondary: {
        main: '#17171B',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
      info: {
        main: '#17171B',
      },
    },
  });
};

export default getMuiTheme;
