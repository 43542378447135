import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "./components/Header";
import CustomTable, { StyledCell } from "app/components/Table";

import { MenuOptions, TableHeaderDefinition, TableHeaders } from "./headers";
import FloatingButton from "app/components/Buttons/FloatingButton";
import MenuWrapper from "app/components/MenuWrapper";
import { Typography } from "@mui/material";
import ConfirmDialog from "app/components/ConfirmDialog";
import InputField from "app/components/InputField";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  selectGames,
  selectIsLoading,
  selectPagination,
} from "../../redux/selector";
import { API_URL, modifyMenuOptions } from "utils/constants";
import { actions } from "../../redux/slice";
import { selectOrganization } from "app/redux/selectors";
import { formatTableDateTime, formatUUID } from "styles/theme/utils";
import CustomTableRow from "app/components/TableRow";
import { OrderDirections } from "app/containers/types";
import { selectDirection } from "styles/theme/slice";

const ListView = () => {
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = React.useState({
    status: false,
    title:"",
    id: "",
  });
  const games = useSelector(selectGames);
  const isLoading = useSelector(selectIsLoading);
  const pagination = useSelector(selectPagination);
  const organization = useSelector(selectOrganization);

  const searchParams = useSearchParams();
  const q = searchParams?.[0]?.get("q");
  const orderBy = searchParams?.[0]?.get("orderBy");
  const orderDirection = searchParams?.[0]?.get("direction") as OrderDirections;
  const { t } = useTranslation();
  const direction = useSelector(selectDirection);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.getGames({
        page: 1,
        search: q ? q : undefined,
        organization: organization?.value,
        orderBy: orderBy as string,
        orderDirection: orderDirection?.toLowerCase() as OrderDirections,
      })
    );
  }, [q, dispatch, organization?.value, orderBy, orderDirection]);

  const addNewUser = () => {
    navigate("/app/games/new");
  };

  const onClickAction = (id: string,  gameId?: string, title?: string) => {
    switch (id) {
      case "1":
        window.open(
          `${API_URL}/v1/admin/game-info/downloads/${gameId}?type=PDF&source=STATION`,
          "_blank"
        );
        break;
      case "2":
        window.open(
          `${API_URL}/v1/admin/game-info/downloads/${gameId}?type=PDF&source=ANSWER`,
          "_blank"
        );
        break;
      case "3":
        navigate(`/app/games/${gameId}/duplicate`);
        break;
      case "4":
        navigate(`/app/tickets/new`);
        break;
      case "5":
        navigate(`/app/games/${gameId}/edit`);
        break;
      case "6":
        setConfirmDelete({
          status: true,
          id: gameId||"",
          title:title||"",
        });
        break;
      default:
        break;
    }
  };

  const deleteStation = () => {
    dispatch(actions.deleteGame(confirmDelete.id));
    setConfirmDelete({
      status: false,
      title:"",
      id: "",
    });
  };
  const hasMoreItems = pagination.page < pagination.total;
  const getNextPage = () => {
    if (!isLoading) {
      dispatch(
        actions.getGames({
          page: pagination.page + 1,
          search: q ? q : undefined,
          organization: organization?.value,
          orderBy: orderBy as string,
          orderDirection: orderDirection?.toLowerCase() as OrderDirections,
        })
      );
    }
  };

  return (
    <>
      <Header />

      <CustomTable
        loading={isLoading}
        headers={TableHeaders}
        hasMore={hasMoreItems}
        loadMore={getNextPage}
        dataLength={games.length}
      >
        {games?.map((g) => (
          <CustomTableRow
            onDoubleClick={() => navigate(`/app/games/${g?._id}/view`)}
            key={g?._id}
            hoverOptionComponent={
              <MenuWrapper
                options={modifyMenuOptions(
                  MenuOptions,
                  g?._id ?? "",
                  onClickAction,
                  g?.name,
                )}
                key={g?._id}
              />
            }
          >
            <StyledCell
              minWidth={TableHeaderDefinition.GAME_ID.minWidth}
              maxWidth={TableHeaderDefinition.GAME_ID.maxWidth}
            >
              <Typography
                noWrap
                sx={{
                  color: "#2F80ED",
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/app/games/${g?._id}/view`);
                }}
              >
                {formatUUID(g?._id)}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.CREATED_DATE.minWidth}
              maxWidth={TableHeaderDefinition.CREATED_DATE.maxWidth}
            >
              {formatTableDateTime(g?.createdAt)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.EDITED_DATE.minWidth}
              maxWidth={TableHeaderDefinition.EDITED_DATE.maxWidth}
            >
              {formatTableDateTime(g?.updatedAt)}
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.GAME_NAME.minWidth}
              maxWidth={TableHeaderDefinition.GAME_NAME.maxWidth}
            >

              <Typography
                noWrap
                sx={
                  g?.creator?._id
                  ? {
                      color: "#2F80ED",
                      fontSize: "14px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }
                  : {
                      fontSize: "14px",
                    }
                }
                onClick={() => navigate(`/app/games/${g?._id}/view`)}
              >
                {g?.name}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.EDITOR_NAME.minWidth}
              maxWidth={TableHeaderDefinition.EDITOR_NAME.maxWidth}
            >
              <Typography
                noWrap
                sx={
                  g?.creator?._id
                    ? {
                        color: "#2F80ED",
                        fontSize: "14px",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }
                    : {
                        fontSize: "14px",
                      }
                }
                onClick={() => {
                  if (!g?.creator?._id) {
                    return;
                  }
                  navigate(`/app/editors/${g?.creator?._id}/view`);
                }}
              >
                {g?.creator?.fullName || "N/A"}
              </Typography>
            </StyledCell>
            <StyledCell
              minWidth={TableHeaderDefinition.NUMBER_OF_STATIONS.minWidth}
              maxWidth={TableHeaderDefinition.NUMBER_OF_STATIONS.maxWidth}
            >
              {g?.noOfStations}
            </StyledCell>
          </CustomTableRow>
        ))}
      </CustomTable>
      <FloatingButton onClick={addNewUser} label={t("GAMES.GAME")} />

      <ConfirmDialog
        open={confirmDelete.status}
        handleClose={() =>
          setConfirmDelete({
            status: false,
            title:"",
            id: "",
          })
        }
        onConfirm={deleteStation}
        title={t("COMMON.DELETE.TITLE", { name: t("GAMES.GAME") })}
        message={t("COMMON.DELETE.MESSAGE", { name: t("GAMES.GAME") })}
        cancelBtnLabel={t("COMMON.BUTTONS.CANCEL")}
        confirmBtnLabel={t("COMMON.BUTTONS.DELETE")}
      >
        <InputField
          label={t("COMMON.LABELS.GAME_NAME")}
          value={confirmDelete.title}
          fullWidth
          labelStyle={{
            
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
          }}
          borderColor="#FFFFFF"
          backgroundColor="#F3F6F8"
          mainStyle={{
            width: "100%",
          }}
        />
      </ConfirmDialog>
    </>
  );
};

export default ListView;
