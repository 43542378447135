import React, { useState } from "react";
const SVG =({ isSelected }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill={isSelected ? "#E24399" : "white" } />
      <path
        d="M3.91471 3.73333C3.83984 3.77892 3.76823 3.85707 3.72917 3.93848C3.66732 4.06548 3.66406 4.14038 3.66406 5.14334C3.66406 6.15608 3.66732 6.22121 3.73242 6.35146C3.80404 6.50451 3.99935 6.625 4.17188 6.625C4.30859 6.625 4.51042 6.49474 4.57878 6.36449C4.63411 6.26354 4.64062 6.16911 4.64062 5.44619V4.6386H5.43164C6.18685 4.6386 6.22591 4.63535 6.34635 4.56371C6.67188 4.37809 6.67188 3.9222 6.34635 3.73659C6.22266 3.66494 6.19661 3.66169 5.12891 3.66169C4.06771 3.66169 4.03516 3.66494 3.91471 3.73333Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M11.7507 3.73463C11.5651 3.83232 5.06445 7.5967 4.9375 7.68137C4.87891 7.71719 4.80404 7.80837 4.76823 7.88326C4.70312 8.01352 4.70312 8.09167 4.70312 11.9993C4.70312 15.907 4.70312 15.9852 4.76823 16.1154C4.80404 16.1903 4.87891 16.2815 4.9375 16.3173C5.08724 16.415 11.5911 20.1794 11.7572 20.2673C11.9297 20.352 12.0599 20.3552 12.2259 20.2673C12.5547 20.1012 19.0488 16.3206 19.1204 16.2554C19.293 16.0959 19.2865 16.2815 19.2865 11.9993C19.2865 7.71719 19.293 7.9028 19.1204 7.74324C19.0488 7.67811 12.5547 3.89745 12.2259 3.73137C12.0599 3.6467 11.9167 3.6467 11.7507 3.73463ZM14.9115 6.40486C16.4935 7.32317 17.7891 8.0819 17.7891 8.09167C17.7891 8.11772 12.0404 11.4132 11.9948 11.4132C11.9492 11.4132 6.20052 8.11772 6.20052 8.09167C6.20052 8.06562 11.9395 4.74085 11.9915 4.73759C12.0143 4.73759 13.3294 5.48982 14.9115 6.40486ZM8.7819 10.7131L11.5033 12.2761L11.5065 15.64C11.5065 18.4568 11.5 19.0006 11.4609 18.9778C11.4382 18.9647 10.2435 18.2711 8.80794 17.4375C7.37565 16.6071 6.20052 15.9168 6.20052 15.907C6.20052 15.8972 7.13151 15.3567 8.26758 14.7054C9.5013 13.9988 10.3672 13.4843 10.416 13.4256C10.6797 13.113 10.4518 12.6506 10.0352 12.6506C9.9082 12.6506 9.68359 12.7711 7.97461 13.7513C6.91667 14.357 5.9694 14.8975 5.86849 14.9594L5.67969 15.0636V11.9993V8.93508L5.86849 9.04254C5.97266 9.10115 7.28451 9.85338 8.7819 10.7131ZM18.3034 13.5266L18.2936 15.0571L16.194 13.8522C14.3223 12.7776 14.0814 12.6506 13.9544 12.6506C13.5378 12.6506 13.3099 13.113 13.5736 13.4256C13.6224 13.4843 14.4883 13.9988 15.722 14.7054C16.8581 15.3567 17.7891 15.8972 17.7891 15.907C17.7891 15.9168 16.6139 16.6071 15.1784 17.4375C13.7461 18.2711 12.5514 18.9647 12.5254 18.9778C12.4896 19.0006 12.4831 18.447 12.4831 15.64L12.4863 12.2761L15.39 10.6089L18.2936 8.94159L18.3034 10.4721C18.3066 11.3122 18.3066 12.6864 18.3034 13.5266Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M17.6495 3.73659C17.5811 3.77566 17.4965 3.86033 17.4639 3.9222C17.3402 4.1534 17.4216 4.43345 17.6495 4.56371C17.7699 4.63535 17.809 4.6386 18.5674 4.6386H19.3552V5.44619C19.3552 6.16911 19.3617 6.26354 19.4171 6.36449C19.4854 6.49474 19.6872 6.625 19.824 6.625C19.9965 6.625 20.1918 6.50451 20.2634 6.35146C20.3285 6.22121 20.3318 6.15608 20.3318 5.14334C20.3318 4.14038 20.3285 4.06548 20.2667 3.93848C20.2243 3.85382 20.156 3.77892 20.0746 3.73333C19.9509 3.66494 19.9184 3.66169 18.8637 3.66169C17.7992 3.66169 17.7732 3.66494 17.6495 3.73659Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M3.92454 17.4297C3.86269 17.4623 3.77806 17.5502 3.73574 17.6186C3.66413 17.7456 3.66413 17.7554 3.67064 18.8756C3.6804 19.9567 3.68366 20.0088 3.74876 20.0934C3.78457 20.1423 3.85618 20.2139 3.90501 20.2498C3.98965 20.3149 4.04173 20.3181 5.08665 20.3279C6.12832 20.3377 6.18366 20.3344 6.31386 20.2693C6.67519 20.0967 6.67845 19.5789 6.32038 19.4096C6.22923 19.3673 6.08275 19.3575 5.42519 19.3575H4.64069V18.5564C4.64069 17.6707 4.62767 17.5991 4.43561 17.4623C4.29889 17.3646 4.06777 17.3516 3.92454 17.4297Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M19.6194 17.4265C19.5608 17.4558 19.4794 17.5242 19.4404 17.5795C19.372 17.6772 19.3687 17.713 19.359 18.5174L19.3492 19.3575H18.568C17.9104 19.3575 17.7639 19.3673 17.6728 19.4096C17.3147 19.5789 17.318 20.0967 17.6793 20.2693C17.8095 20.3344 17.8648 20.3377 18.9065 20.3279C19.9514 20.3181 20.0035 20.3149 20.0881 20.2498C20.137 20.2139 20.2086 20.1423 20.2444 20.0934C20.3095 20.0088 20.3128 19.9567 20.3225 18.8756C20.329 17.7554 20.329 17.7456 20.2574 17.6186C20.1272 17.3939 19.8538 17.3092 19.6194 17.4265Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
    </svg>
  );
};
export default SVG