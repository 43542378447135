import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../ConfirmDialog";
import { BlockerFunction, useBlocker } from "react-router-dom";

export default function NavigationConfirmDialog({
  title = "Unsaved changes",
  isUnsaved,
  onConfirm,
}: {
  title?: string;
  isUnsaved: boolean;
  onConfirm: () => void;
}) {
  const { t } = useTranslation();

  const isMountedRef = useRef<boolean>();
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      return (
        !!isMountedRef.current && isUnsaved &&
        currentLocation.pathname !== nextLocation.pathname
        // your other blocking conditions here
      );
    },
    [isUnsaved],
  );

  
  const blocker = useBlocker(shouldBlock);
  useEffect(() => {
    const beforeUnloadCallback = (event) => {
      if (isUnsaved) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", beforeUnloadCallback);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadCallback);
    };
  }, [isUnsaved]);
  return (
    <ConfirmDialog
      open={blocker.state === "blocked"}
      handleClose={(val) => {
      
        if (val) {
          //@ts-ignore
          blocker.reset();
        } else {
          //@ts-ignore
          blocker?.proceed();
        }
      }}
      onConfirm={() => {
        //@ts-ignore
        blocker.reset();
        onConfirm();
      }}
      title={t("COMMON.UNSAVED_CHANGES.TITLE", { name: "" })}
      message={t("COMMON.UNSAVED_CHANGES.MESSAGE", {
        name: title,
      })}
      messageSecond={t("COMMON.UNSAVED_CHANGES.MESSAGE_1")}
      cancelBtnLabel={t("COMMON.UNSAVED_CHANGES.CANCEL")}
      confirmBtnLabel={t("COMMON.UNSAVED_CHANGES.PROCEED")}
    />
  );
}
