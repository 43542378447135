import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL, translate } from "utils/constants";
import { Res, request } from "utils/request";
import { selectPagination } from "./selector";
import { actions } from "./slice";
import { Editor, EditorsListResponse } from "../types";
import { removeBlankProperties } from "utils/helpers";
import toastService from "utils/toast";
import { Query } from "app/containers/types";
import { actions as authAction } from "app/redux/slice";
import { selectOrganization } from "app/redux/selectors";

export function* getEditorRequest({ payload }: { payload: string }) {
  try {
    const id = payload;
    const { data, status, err }: Res<{ data: Editor }> = yield call(
      request,
      `${API_URL}/v1/admin/editor/get/${id}`
    );
    
    if (err || !data) {
      throw err;
    }
    yield put(actions.getEditorSuccess(data?.data));
  } catch (error: any) {
    yield put(actions.getEditorError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* getEditorsRequest({ payload }: { payload: Query }) {
  try {
    const query = new URLSearchParams(
      removeBlankProperties(payload)
    ).toString();
    const { data, err, status }: Res<EditorsListResponse> = yield call(
      request,
      `${API_URL}/v1/admin/editor/list?${query}`
    );
    
    if (err || !data) {
      throw err;
    }
    yield put(
      actions.getEditorsSuccess({
        editors: data?.data,
        pagination: data?._metadata.pagination,
      })
    );
  } catch (error: any) {
    yield put(actions.getEditorsError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* createEditorRequest({
  payload,
}: {
  payload: { editor: Editor; callback?: () => void };
}) {
  yield delay(500);
  try {
    const { status, err }: Res<{}> = yield call(
      request,
      `${API_URL}/v1/admin/editor/create`,
      {
        method: "POST",
        body: JSON.stringify(payload.editor),
      }
    );
    
    if (err) {
      throw err;
    }
    yield put(actions.createEditorSuccess());
    toastService.success(translate("EDITORS.SUCCESSFULLY_CREATED"));
    payload?.callback?.();
  } catch (error: any) {
    yield put(actions.createEditorError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* updateEditorRequest({
  payload,
}: {
  payload: { editor: Editor; callback?: () => void };
}) {
  yield delay(500);
  try {
    const { data, status, err }: Res<{ data: Editor }> = yield call(
      request,
      `${API_URL}/v1/admin/editor/update/${payload.editor?._id}`,
      {
        method: "PUT",
        body: JSON.stringify(payload.editor),
      }
    );
    
    if (err || !data) {
      throw err;
    }
    yield put(actions.updateEditorSuccess(data?.data));
    toastService.success(translate("EDITORS.SUCCESSFULLY_UPDATED"));
    payload?.callback?.();
  } catch (error: any) {
    yield put(actions.updateEditorError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}

export function* deleteEditorRequest({ payload }: { payload: string }) {
  yield delay(500);
  try {
    const organization = yield select(selectOrganization);
    const { status, err }: Res<{}> = yield call(
      request,
      `${API_URL}/v1/admin/editor/delete/${payload}`,
      {
        method: "DELETE",
      }
    );
    
    if (err) {
      throw err;
    }
    yield put(actions.deleteEditorSuccess());
    const pagination = yield select(selectPagination);
    toastService.success(translate("EDITORS.SUCCESSFULLY_DELETED"));
    yield put(
      actions.getEditors({
        perPage: pagination.perPage,
        page: pagination.page,
        search: pagination.search,
        isActive: pagination.filters?.isActive,
        orderBy: pagination.orderBy,
        orderDirection: pagination.orderDirection,
        organization: organization ? organization.value : undefined,
      })
    );
  } catch (error: any) {
    yield put(actions.deleteEditorError());
    let errorMessage = error?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message ||
        errorMessage ||
        translate("COMMON.ERRORS.MESSAGE")
    );
  }
}
export function* resetFrom({
  payload,
}: {
  payload: { callback?: () => void };
}) {
  yield delay(500);
  try {
    payload?.callback?.();
  } catch (error: any) {}
}
export function* editorsSaga() {
  yield takeLatest(actions.getEditor, getEditorRequest);
  yield takeLatest(actions.getEditors, getEditorsRequest);
  yield takeLatest(actions.createEditor, createEditorRequest);
  yield takeLatest(actions.updateEditor, updateEditorRequest);
  yield takeLatest(actions.deleteEditor, deleteEditorRequest);
  yield takeLatest(actions.resetForm, resetFrom);
}
