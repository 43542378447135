import { SingleMultiSelectAnswer } from "app/containers/Questions/types";
import { ASSET_TYPE } from "utils/constants";

export const SingleMultiData: SingleMultiSelectAnswer[] = [
    {
      _id: "",
      isCorrect: false,
      isDelete: false,
      title: "",
      order: 0,
      asset: {
        _id: "",
        fileName: "",
        filePath: "",
        mimeType: "",
        type: ASSET_TYPE.IMAGE,
      },
    },
    {
      _id: "",
      isCorrect: false,
      isDelete: false,
      title: "",
      order: 0,
      asset: {
        _id: "",
        fileName: "",
        filePath: "",
        mimeType: "",
        type: ASSET_TYPE.IMAGE,
      },
    },
    {
      _id: "",
      isCorrect: false,
      isDelete: false,
      title: "",
      order: 0,
      asset: {
        _id: "",
        fileName: "",
        filePath: "",
        mimeType: "",
        type: ASSET_TYPE.IMAGE,
      },
    },
    {
      _id: "",
      isCorrect: false,
      isDelete: false,
      title: "",
      order: 0,
      asset: {
        _id: "",
        fileName: "",
        filePath: "",
        mimeType: "",
        type: ASSET_TYPE.IMAGE,
      },
    },
    {
      _id: "",
      isCorrect: false,
      isDelete: false,
      title: "",
      order: 0,
      asset: {
        _id: "",
        fileName: "",
        filePath: "",
        mimeType: "",
        type: ASSET_TYPE.IMAGE,
      },
    },
    {
      _id: "",
      isCorrect: false,
      isDelete: false,
      title: "",
      order: 0,
      asset: {
        _id: "",
        fileName: "",
        filePath: "",
        mimeType: "",
        type: ASSET_TYPE.IMAGE,
      },
    },
    {
      _id: "",
      isCorrect: false,
      isDelete: false,
      title: "",
      order: 0,
      asset: {
        _id: "",
        fileName: "",
        filePath: "",
        mimeType: "",
        type: ASSET_TYPE.IMAGE,
      },
    },
  ];