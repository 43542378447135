import React from "react";
import { DropDown } from "../DropDown";
import { MenuItem, Typography } from "@mui/material";
import { QUESTION_TYPES } from "utils/constants";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function QuestionTypesDropdown({
  onChange,
  value,
  disabled,
  isMandatory,
  renderValue,
  hideAll
}: {
  readonly onChange?: (v: QUESTION_TYPES) => void;
  readonly value?: QUESTION_TYPES;
  readonly disabled?: boolean;
  readonly isMandatory?: boolean;
  readonly hideAll?: boolean;
  readonly renderValue?: (value: string) => JSX.Element;
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const searchParams  = useSearchParams();
  const type = searchParams?.[0]?.get("type");

  // Generate the list of question types with labels
  const questionTypes = Object.keys(QUESTION_TYPES)
    .filter((x) => hideAll ? x !== "ALL" : true)
    .map((key) => ({
      id: key,
      value: QUESTION_TYPES[key],
      name: t(`QUESTIONS.TYPES.${key}`),
    }));

  // Custom renderValue function to display the label for the selected value
  const renderSelectedValue = (selectedValue: string) => {
    const selectedType = questionTypes.find(item => item.value === selectedValue);
    return selectedType ? selectedType.name : hideAll ? "" : t('COMMON.TEXT.ALL');
  };

  return (
    <DropDown
      isDisable={disabled}
      menuItems={questionTypes.map((item) => (
        <MenuItem value={item.value} key={item.id}>
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "14px",
              letterSpacing: "0em",
              color: "#5E6781",
              paddingY: "8px",
            }}
          >
            {item.name}
          </Typography>
        </MenuItem>
      ))}
      renderValue={renderValue || renderSelectedValue}
      onChange={(e) => onChange?.(e.target.value as QUESTION_TYPES)}
      label={t("COMMON.LABELS.QUESTION_TYPE")}
      placeholder="Select"
      name="status"
      value={value}
      fullWidth={true}
      className={styles.dropDownWithoutBorder}
      isMandatory={isMandatory}
      defaultValue={t('COMMON.TEXT.ALL')}
    />
  );
}
