import { Box, Typography } from '@mui/material'
import { selectEditor } from 'app/containers/Editors/redux/selector'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectDirection } from 'styles/theme/slice'
import { formatTableDateTime } from 'styles/theme/utils'
import { getFormattedDateTime } from 'utils/helpers'

export default function Summary() {
  const { t } = useTranslation()
  const editor = useSelector(selectEditor)
  const direction = useSelector(selectDirection);

  return (
    <Box
      sx={{
        border: "1px solid #F2F2F2 !important",
        borderRadius: "8px",
        marginBottom: "16px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 16px",
        }}
      >
        <Typography
          sx={{
            
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            fontWeight: 600,
          }}
        >
          {t("EDITORS.FORM.LABELS.NUMBER_OF_GAMES")}
        </Typography>
        <Typography
          sx={{
            
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            fontWeight: 400,
          }}
        >
          {editor?.noOfGames.value}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 16px",
          backgroundColor: "#F3F6F8",
        }}
      >
        <Typography
          sx={{
            
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            fontWeight: 600,
          }}
        >
          {t("EDITORS.FORM.LABELS.NUMBER_OF_QUESTIONS")}
        </Typography>
        <Typography
          sx={{
            
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            fontWeight: 400,
          }}
        >
          {editor?.noOfQuestions.value}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 16px",
        }}
      >
        <Typography
          sx={{
            
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            fontWeight: 600,
          }}
        >
          {t("EDITORS.FORM.LABELS.LAST_LOGIN_DATE")}
        </Typography>
        <Typography
          sx={{
            
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#5E6781",
            fontWeight: 400,
          }}
        >
          {formatTableDateTime(editor?.lastLogin?.value)}
        </Typography>
      </Box>
    </Box>
  )
}
