import React from "react";
import { Box, Typography } from "@mui/material";
import { Option } from "app/types";
import SelectInput from "./components/SelectInput";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { citySlice, cityReducer } from "./redux/slice";
import { citiesSaga } from "./redux/saga";
import { useSelector } from "react-redux";
import { selectCities } from "./redux/selector";
import { useTranslation } from "react-i18next";
import { selectDirection } from "styles/theme/slice";
import { useSearchParams } from "react-router-dom";

interface CitySelectProps {
  readonly label?: string;
  readonly value?: Option;
  readonly onChange?: (newValue?: Option | null) => void;
  readonly isFieldReq?: boolean;
  readonly mainStyle?: React.CSSProperties;
  readonly labelStyle?: React.CSSProperties;
  readonly isLoading?: boolean;
  readonly error?: string;
  readonly disabled?: boolean;
  readonly borderWidth?: number;
  readonly backgroundColor?: string;
  readonly showAll?: boolean;
}

export default function CitySelect({
  value,
  onChange,
  isFieldReq = true,
  mainStyle,
  labelStyle,
  isLoading,
  error,
  disabled,
  label,
  borderWidth,
  backgroundColor,
  showAll = false
}: CitySelectProps) {
  useInjectReducer({ key: citySlice, reducer: cityReducer });
  useInjectSaga({ key: citySlice, saga: citiesSaga });

  return (
    <Box
      sx={{
        marginBottom: "10px",
        width: "100%",
      }}
      style={mainStyle}
    >
      {label && (
        <Typography
          sx={{
            
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0em",
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            ...labelStyle,
          }}
        >
          {label}
          {isFieldReq && <span style={{ color: "#ff0000" }}> *</span>}
        </Typography>
      )}
      <SelectInput
        value={value}
        onChange={onChange}
        isLoading={isLoading}
        disabled={disabled}
        error={error}
        backgroundColor={backgroundColor}
        borderWidth={borderWidth}
        showAll={showAll}
      />
    </Box>
  );
}
