import { API_URL } from "./constants";

export async function refreshToken(): Promise<void> {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('Failed to refresh token');
    }
  
    const response = await fetch(`${API_URL}/v1/auth/user/refresh`, {
      method: 'POST',
      body: JSON.stringify({ refreshToken }),
      headers: { 'Content-Type': 'application/json' ,  Authorization: `Bearer ${refreshToken}`},
    });
  
    if (!response.ok) {
      throw new Error('Failed to refresh token');
    }
  
    const data = await response.json();
    localStorage.setItem("accessToken", data?.data?.accessToken);
    localStorage.setItem("refreshToken", data?.data?.refreshToken);
  }
