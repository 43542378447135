import { Box, Typography } from "@mui/material";
import { selectCustomizeTicket, selectTicket } from "app/containers/Tickets/redux/selector";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";



export default function Summary() {
  const ticket = useSelector(selectCustomizeTicket);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        padding: "0 16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #D7D7E0",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            padding: "8px 16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            ":nth-of-type(even)": {
              backgroundColor: "#FFFFFF80",
            },
          }}
        >
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {t("TICKETS.CUSTOMIZED_TICKETS.CUSTOMER_NAME")}
          </Typography>
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {ticket?.ticket?.customer?.name}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "8px 16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            ":nth-of-type(even)": {
              backgroundColor: "#FFFFFF80",
            },
          }}
        >
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {t("TICKETS.CUSTOMIZED_TICKETS.CREATED_DATE")}
          </Typography>
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {ticket?.ticket?.customer?.createdAt}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "8px 16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            ":nth-of-type(even)": {
              backgroundColor: "#FFFFFF80",
            },
          }}
        >
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {t("TICKETS.CUSTOMIZED_TICKETS.GAME_NAME")}
          </Typography>
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {ticket?.ticket?.game?.name}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "8px 16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            ":nth-of-type(even)": {
              backgroundColor: "#FFFFFF80",
            },
          }}
        >
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {t("TICKETS.CUSTOMIZED_TICKETS.PASSWORD")}
          </Typography>
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {ticket?.ticket?.password}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "8px 16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            ":nth-of-type(even)": {
              backgroundColor: "#FFFFFF80",
            },
          }}
        >
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {t("TICKETS.CUSTOMIZED_TICKETS.FROM_DATE")}
          </Typography>
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {ticket?.ticket?.startDate}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "8px 16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            ":nth-of-type(even)": {
              backgroundColor: "#FFFFFF80",
            },
          }}
        >
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {t("TICKETS.CUSTOMIZED_TICKETS.TO_DATE")}
          </Typography>
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {ticket?.ticket?.endDate}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "8px 16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            ":nth-of-type(even)": {
              backgroundColor: "#FFFFFF80",
            },
          }}
        >
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {t("TICKETS.CUSTOMIZED_TICKETS.NUMBER_OF_DEVICES")}
          </Typography>
          <Typography
            sx={{
              
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#8890A6",
            }}
          >
            {ticket?.ticket?.noOfDevices}
          </Typography>
        </Box>
        
      </Box>
    </Box>
  );
}
