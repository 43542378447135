import { call, put, takeLatest } from 'redux-saga/effects';
import { API_URL, translate } from 'utils/constants';
import { Res, request } from 'utils/request';
import { actions } from './slice';
import { removeBlankProperties } from 'utils/helpers';
import toastService from 'utils/toast';
import { Profile } from '../types';
import { actions as authAction } from 'app/redux/slice';
import { showServerError } from 'styles/theme/utils';

export function* updateProfileRequest({payload}: { payload: {profile: Profile, callback?: () => void}}) { 
    try {
        const {data, err, status}: Res<{ data: Profile }> = yield call(request, `${API_URL}/v1/auth/user/profile/update`, {
            method: 'PATCH',
            body: JSON.stringify(removeBlankProperties(payload.profile))
        });
        
        if(err || !data){
          throw err;
        }
        yield put(actions.updateProfileSuccess(data.data));
        payload?.callback?.();
        toastService.success( translate("PROFILE.SAVE_SUCCESS"));
    } catch (error:any) {
        yield put(actions.updateProfileError());
        showServerError(error)
    }
}

export function* profileSaga() {
    yield takeLatest(actions.updateProfile, updateProfileRequest);
}