import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress, SxProps, Theme, Tooltip } from "@mui/material";
import React from "react";

const CustomButton = ({
  tooltip,
  label,
  onClick,
  disabled,
  isLoading,
  color,
  variant,
  sx,
  startIcon,
  endIcon,
}: {
  tooltip?: string;
  label: string;
  onClick?: (e) => void;
  disabled?: boolean;
  isLoading?: boolean;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  variant?: "text" | "outlined" | "contained";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Tooltip title={tooltip}>
      <div>
        <LoadingButton
          startIcon={startIcon}
          endIcon={endIcon}
          loading={isLoading}
          color={color}
          disabled={disabled}
          onClick={onClick}
          variant={variant}
          loadingIndicator={<CircularProgress color="inherit" size={16} />}
          sx={{
            gap: "10px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...sx,
          }}
        >
          {label}
        </LoadingButton>
      </div>
    </Tooltip>
  );
};

export default CustomButton;
