import { Box, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import MediaUploader from 'app/components/MediaUploader'
import { selectStation } from 'app/containers/Stations/redux/selector'
import { actions } from 'app/containers/Stations/redux/slice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MODES } from 'utils/constants'

export default function StationImage({
    mode
}:{
    readonly mode?: MODES
}) {
    const { t } = useTranslation();
    const station = useSelector(selectStation);
    const dispatch = useDispatch();
    return (
        <Grid2
          xs={6}
        >
            <Box
              sx={{
                background: "#F3F6F8",
                padding: "20px 20px",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                height: "100%"
              }}
            >
              <Typography
                sx={{
                  mt: '10px',
                  mb: '10px',
                  ml: '0px',
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "32px",
                  lineHeight: "100%",
                  color: "#207DB9"
                }}
              >
                {t("STATIONS.FORM.STATION_IMAGE")}
              </Typography>
              <MediaUploader 
                title={t("STATIONS.FORM.STATION_IMAGE")}
                src={station?.stationImageUrl?.value}
                disabled={mode === MODES.VIEW}
                caption={t("STATIONS.FORM.LABELS.STATION_IMAGE_CAPTION")}
                height='200px'
                onSuccess={(img) => {
                  dispatch(actions.setFormValues({ key: "stationImageId", value: img?._id }));
                  dispatch(actions.setFormValues({ key: "stationImageUrl", value: img?.url }));
                }}
                error={station?.stationImageUrl?.error ? t(station?.stationImageUrl?.error) : ""}
              />
            </Box>
        </Grid2>
    )
}
