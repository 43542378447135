import React, { useEffect } from 'react';
import { useInjectReducer } from 'redux-injectors';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@mui/styles';
import getMuiTheme from './mui'; // Import the modified MuiTheme
import { reducer, selectDirection, themeSliceKey } from './slice';
import { useSelector } from 'react-redux';

// JSS setup with RTL support
const jss = create({ plugins: [...jssPreset().plugins, rtlPlugin] });

// Cache for RTL
const cacheRtl = createCache({
  key: 'rtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

// Cache for LTR (standard)
const cacheLtr = createCache({
  key: 'ltr',
});

export const ThemeProvider = (props) => {
  useInjectReducer({ key: themeSliceKey, reducer: reducer });

  // Get the theme direction from Redux
  const themeDirection = useSelector(selectDirection) || 'ltr';

  // Update document direction
  useEffect(() => {
    document.body.dir = themeDirection;
  }, [themeDirection]);

  // Cache provider based on direction
  const cache = themeDirection === 'rtl' ? cacheRtl : cacheLtr;

  // Get the theme based on direction
  const theme = getMuiTheme(themeDirection);

  return (
    <StylesProvider jss={jss}>
      <CacheProvider value={cache}>
        <MuiThemeProvider theme={theme}>
          {props.children}
        </MuiThemeProvider>
      </CacheProvider>
    </StylesProvider>
  );
};
