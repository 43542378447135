import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { validate } from "../../validator";
import PrimaryButton from "app/components/Buttons/PrimaryButton";
import InputField from "app/components/InputField";
import { useStyles } from "./styles";
import { selectEmail, selectLoading, selectPassword } from "../../redux/selectors";
import { actions, loginReducer, loginSlice } from "../../redux/slice";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { loginSaga } from "../../redux/saga";

export function LoginForm({ className }) {
  useInjectReducer({ key: loginSlice, reducer: loginReducer });
  useInjectSaga({ key: loginSlice, saga: loginSaga });
  const classes = useStyles();
  const email = useSelector(selectEmail);
  const password = useSelector(selectPassword);
  const isLoading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmitForm = (evt?: React.FormEvent<HTMLFormElement>) => {
    evt?.preventDefault();
    const errors = validate({
      email,
      password,
    });
    if (errors.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(actions.login({ callback: () => navigate("/app/tickets") }));
  };

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.setFormValues({ key: name, value }));
  };

  return (
    <form className={classes.form} noValidate onSubmit={onSubmitForm}>
      <Box width="100%" mt={1}>
        <Typography className={classes.loginText}>
          {t("AUTH.LOGIN.TITLE")}
        </Typography>
        <Typography
          sx={{
            
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "27px",
            letterSpacing: "0em",
            textAlign: "center",
            marginBottom: "32px",
            color: "#8890A6",
          }}
        >
          {t("AUTH.LOGIN.CAPTION")}
        </Typography>
      </Box>
      <InputField
        label={t("AUTH.FORM.EMAIL")}
        placeholder={t("AUTH.FORM.ENTER_EMAIL_ADDRESS")}
        onChange={onChange}
        name="email"
        fullWidth
        lightPlaceholder
        mainStyle={{ paddingBottom: "24px", width: "100%" }}
        labelStyle={{ fontWeight: 600 }}
        error={email.error}
        value={email.value}
        isDisable={isLoading}
      />
      <InputField
        label={t("AUTH.FORM.PASSWORD")}
        placeholder={t("AUTH.FORM.ENTER_PASSWORD")}
        onChange={onChange}
        fullWidth
        lightPlaceholder
        name="password"
        mainStyle={{ width: "100%", paddingBottom: "24px" }}
        labelStyle={{ fontWeight: 600 }}
        type="password"
        error={password.error}
        value={password.value}
        isDisable={isLoading}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmitForm();
          }
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          flexDirection: "row",
          marginBottom: "16px",
        }}
      >
        <Typography
          onClick={() =>{
            if(isLoading) return;
            navigate("/auth/recovery/forgot-password")
          }}
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#207DB9",
            cursor: "pointer",
          }}
        >
          {t("AUTH.FORM.FORGOT_PASSWORD")}
        </Typography>
      </Box>

      <Box
        className={classes.loginButtonWrapper}
        sx={{
          my: 3,
        }}
      >
        <PrimaryButton
          label={t("AUTH.BUTTONS.LOGIN")}
          sx={{
            width: "100%",
            height: "48px",
            textTransform: "none",
          }}
          disabled={isLoading}
          isLoading={isLoading}
          onClick={onSubmitForm}
        />
      </Box>
    </form>
  );
}

export default LoginForm;
