import { call, delay, put, takeLatest, select } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { Res, request } from 'utils/request';
import { actions } from './slice';
import { showServerError } from 'styles/theme/utils';
import { Question } from 'app/containers/Stations/types';
import { actions as authAction } from 'app/redux/slice';
import { selectSelectedQuestion } from './selector';

export function* getQuestionsRequest() { 
  yield delay(500);
  try {
      const id:string = yield select(selectSelectedQuestion);
      if(!id) {return }
      const {data, err, status}: Res<{ data: {stationQuestions : Question[]}}> = yield call(request, `${API_URL}/v1/admin/station/get/${id}/questions`);
      
        if(err || !data){
            throw err;
        }
      yield put(actions.getQuestionsSuccess(data?.data?.stationQuestions));
  } catch (error:any) {
      yield put(actions.getQuestionsError());
      showServerError(error)
  }
}

export function* gamesQuestionsSaga() {
  yield takeLatest(actions.getQuestions, getQuestionsRequest);
}
