
import React, { useEffect } from 'react';
import { LoginPage } from './containers/LoginPage/Loadable';
import { useSelector } from 'react-redux';
import { selectToken } from 'app/redux/selectors';
import { useNavigate } from 'react-router-dom';

export function AuthPage() {
  const token = useSelector(selectToken)
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate('/app/tickets', { replace: true });
    }
  }, [token]);

  if(token) return null;
  return (
    <LoginPage />
  );
}
