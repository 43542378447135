import React, { useState } from "react";
const SVG =({ isSelected }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill={isSelected ? "#E24399" : "white" }/>
      <path
        d="M9.20552 3.02524C7.74146 3.25752 6.5519 4.30278 6.14365 5.71405C6.01343 6.16102 5.96416 6.68188 6.01695 7.11477C6.19996 8.70553 7.2593 9.94436 8.79727 10.3737C9.09994 10.4582 9.18793 10.4688 9.74751 10.4688C10.4443 10.4688 10.7083 10.416 11.2608 10.1731C12.9642 9.42701 13.8687 7.49486 13.3514 5.71405C12.9818 4.43651 11.9577 3.44053 10.6801 3.1097C10.286 3.01116 9.57154 2.96893 9.20552 3.02524ZM10.4831 3.83822C11.5776 4.11273 12.4645 5.04185 12.6862 6.15398C12.7671 6.54463 12.7425 7.18868 12.6369 7.55821C12.3483 8.55068 11.5776 9.32495 10.5746 9.62762C10.293 9.71208 10.2015 9.72264 9.74751 9.72264C9.29351 9.72264 9.202 9.71208 8.92045 9.62762C8.10395 9.38126 7.44583 8.83223 7.07277 8.08964C6.86865 7.68491 6.7877 7.37169 6.76658 6.87545C6.72787 6.00264 6.99183 5.29524 7.59716 4.66879C8.34679 3.88749 9.43428 3.57426 10.4831 3.83822Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M5.78109 12.0537C5.0737 12.1804 4.49652 12.4901 3.97565 13.018C3.52164 13.4755 3.23305 13.9858 3.07116 14.6263C3.01485 14.8445 3.00781 15.0663 3.00781 16.7063V18.5469L3.10636 18.642L3.20138 18.7405L6.69614 18.7511C9.87767 18.7616 10.1979 18.7581 10.2965 18.7053C10.5006 18.5962 10.5534 18.3111 10.4021 18.1352L10.3176 18.0366L7.03048 18.019L3.74688 18.0014V16.5057C3.74688 15.2141 3.75744 14.9783 3.81023 14.7742C4.01084 13.9753 4.55634 13.3171 5.29894 12.9828C5.79165 12.7575 5.72126 12.7646 8.50158 12.7435C10.9194 12.7224 11.0461 12.7188 11.1165 12.6555C11.2326 12.5499 11.2749 12.381 11.2115 12.2367C11.1095 11.9903 11.2221 12.0009 8.49102 12.0044C6.63982 12.0079 5.97114 12.0185 5.78109 12.0537Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
      <path
        d="M14.2737 12.7886C12.3063 13.503 12.1233 13.5769 12.0635 13.6719C12.0037 13.7705 12.0001 13.9394 12.0001 16.498C12.0001 19.5352 11.9861 19.3628 12.243 19.4754C12.521 19.5986 16.3536 20.9852 16.4557 20.9993C16.5331 21.0134 17.1068 20.8163 18.6377 20.2602C19.778 19.8449 20.7529 19.486 20.8021 19.4613C21.0203 19.3628 21.0098 19.4895 21.0098 16.498C21.0098 13.9183 21.0063 13.7705 20.9429 13.6719C20.8831 13.5734 20.7177 13.5065 18.7257 12.7815C17.5432 12.3522 16.5402 12.0002 16.4944 12.0037C16.4522 12.0037 15.4527 12.3557 14.2737 12.7886ZM17.9725 13.3059C18.782 13.6015 19.4577 13.8514 19.4718 13.8655C19.5 13.8901 16.6035 14.9565 16.505 14.9565C16.4205 14.9565 13.51 13.8937 13.5311 13.869C13.5452 13.8549 16.3994 12.8026 16.4944 12.778C16.5014 12.7745 17.1666 13.0138 17.9725 13.3059ZM14.4532 15.0339L16.1178 15.6357V17.867V20.0948L15.882 20.0068C15.7483 19.9576 14.9951 19.683 14.1998 19.3945L12.7568 18.8701L12.7462 16.6493C12.7427 15.4281 12.7498 14.4286 12.7638 14.4286C12.7814 14.4286 13.5416 14.6996 14.4532 15.0339ZM20.2637 16.6493L20.2531 18.8736L18.8805 19.3733C18.1274 19.6479 17.3707 19.9224 17.2018 19.9822L16.8921 20.0948V17.867V15.6357L18.5392 15.0375C19.4437 14.7066 20.2038 14.4321 20.2285 14.4321C20.2637 14.4286 20.2707 14.8826 20.2637 16.6493Z"
        fill={isSelected ? "white" : "#26A8E0"  }
      />
    </svg>
  );
};
export default SVG